<template>
  <div v-bind="$attrs">
    <slot name="loader" v-if="forceLoad || isPageLoading">
      <div :class="bindClass" :style="bindStyle">
        <div style="width: 100%; visibility: hidden">
          <slot></slot>
        </div>
      </div>
    </slot>
    <slot v-else-if="!hideComponent"></slot>
  </div>
</template>

<script>
import LoadingMixin from "@/mixins/LoadingMixin";

export default {
  name: "LoadingComponent",
  inheritAttrs: false,
  props: {
    hideComponent: {
      type: Boolean,
      default: false,
    },
    isFullWidth: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "rect",
    },
    flat: {
      type: Boolean,
      default: false,
    },
    loadingWidth: {
      type: [Number, String],
    },
    loadingHeight: {
      type: [Number, String],
    },
    forceLoad: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [LoadingMixin],
  computed: {
    bindClass() {
      return `loader animation--wave-new shape--${this.type} shape--${
        this.flat ? "flat" : "round"
      }`;
    },
    bindStyle() {
      const width = this.isFullWidth
        ? "100%"
        : typeof this.loadingWidth === "number"
        ? `${this.loadingWidth}px`
        : this.loadingWidth || "auto";
      const height =
        typeof this.loadingHeight === "number"
          ? `${this.loadingHeight}px`
          : this.loadingHeight || "auto";
      return `display: inline-block; width: ${width}; height: ${height}; border-radius: ${
        this.type === "circle" ? "9999px" : this.flat ? "0" : "8px"
      }`;
    },
  },
};
</script>
