<template>
  <section class="section">hello</section>
</template>

<script>
export default {
  name: "ProjectAggregation",
};
</script>

<style scoped lang="scss"></style>
