<template>
  <form @submit.prevent="submit">
    <div class="modal-card" style="width: 800px">
      <header class="modal-card-head">
        <p class="modal-card-title">{{ title }}</p>
        <button type="button" class="delete" @click="$emit('close')" />
      </header>
      <section class="modal-card-body">
        <h2 class="subtitle">Alapadatok</h2>
        <vuelidated-input
          icon="database"
          v-model="tenantEdit.url"
          label="Adatázis URL"
          label-position="on-border"
          placeholder="Adatbázis URL"
          :validation-rule="v$.tenantEdit.url"
          class="mb-4"
        ></vuelidated-input>
        <vuelidated-input
          icon="signature"
          v-model="tenantEdit.identifier"
          label="Azonosító"
          label-position="on-border"
          placeholder="Azonosító"
          :validation-rule="v$.tenantEdit.identifier"
          class="mb-4"
        ></vuelidated-input>

        <b-field grouped>
          <vuelidated-input
            icon="address-book"
            expanded
            v-model="tenantEdit.name"
            label="Név"
            label-position="on-border"
            placeholder="Név"
            :validation-rule="v$.tenantEdit.name"
            class="mb-2"
          ></vuelidated-input>
          <vuelidated-input
            expanded
            icon="building"
            v-model="tenantEdit.companyName"
            label="Cég név"
            label-position="on-border"
            placeholder="Cég név"
            :validation-rule="v$.tenantEdit.companyName"
            class="mb-2"
          ></vuelidated-input>
        </b-field>

        <h2 class="subtitle">Kontakt</h2>

        <b-field grouped>
          <vuelidated-input
            icon="at"
            expanded
            v-model="tenantEdit.contactEmail"
            type="email"
            label="Kontakt email"
            label-position="on-border"
            placeholder="Kontakt email"
            :validation-rule="v$.tenantEdit.contactEmail"
            class="mb-2"
          ></vuelidated-input>
          <phone-input
            ref="phoneInput"
            label="Kontakt telefonszám"
            placeholder="Kontakt telefonszám"
            required
            v-model="tenantEdit.contactPhoneNumber"
            class="mb-2"
            @validate="validContactInput = $event"
          />
        </b-field>

        <h2 class="subtitle">Felhasználók</h2>

        <b-field grouped>
          <vuelidated-input
            type="number"
            icon="user"
            expanded
            v-model="tenantEdit.maxUserCount"
            label="Maximum felhasználók száma"
            label-position="on-border"
            placeholder="Maximum felhasználók száma"
            :validation-rule="v$.tenantEdit.maxUserCount"
            class="mb-2"
          ></vuelidated-input>
          <vuelidated-input
            type="number"
            icon="user-gear"
            expanded
            v-model="tenantEdit.maxPowerUserCount"
            label="Maximum szuperuserek száma"
            label-position="on-border"
            placeholder="Maximum szuperuserek száma"
            :validation-rule="v$.tenantEdit.maxPowerUserCount"
            class="mb-2"
          ></vuelidated-input>
        </b-field>

        <vuelidated-input
          icon="tag"
          expanded
          v-model="tenantEdit.powerUserRoleIdentifier"
          label="Szuperuser szerep azonosítója"
          label-position="on-border"
          placeholder="Szuperuser szerep azonosítója"
          :validation-rule="v$.tenantEdit.powerUserRoleIdentifier"
        ></vuelidated-input>
      </section>
      <footer class="modal-card-foot">
        <b-button label="Mégsem" @click="$emit('close')" />
        <b-button
          :disabled="!validContactInput"
          label="Mentés"
          native-type="submit"
          :loading="submitting"
          type="is-primary"
        />
      </footer>
    </div>
  </form>
</template>

<script>
import {
  email,
  maxLength,
  required,
  numeric,
  minValue,
} from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import PhoneInput from "@/components/module/input/PhoneInput";
import VuelidatedInput from "@/components/module/input/VuelidatedInput";
import { deepCopy } from "@/utils/util";

export default {
  name: "TenantEdit",
  components: {
    VuelidatedInput,
    PhoneInput,
  },
  setup: () => ({ v$: useVuelidate() }),
  props: {
    tenant: {
      type: Object,
      required: false,
    },
  },
  validations: {
    tenantEdit: {
      identifier: {
        required,
      },
      url: {
        required,
      },
      name: {
        required,
      },
      companyName: {
        required,
      },
      contactEmail: {
        required,
        maxLength: maxLength(255),
        email,
      },
      maxUserCount: {
        required,
        minValue: minValue(1),
        numeric,
      },
      maxPowerUserCount: {
        required,
        minValue: minValue(1),
        numeric,
      },
      powerUserRoleIdentifier: {
        required,
      },
    },
  },
  data() {
    return {
      submitting: false,
      tenantEdit: {
        identifier: null,
        name: null,
        companyName: null,
        avatar: null,
        contactEmail: null,
        contactPhoneNumber: null,
        maxUserCount: null,
        maxPowerUserCount: null,
        powerUserRoleIdentifier: null,
        deleted: null,
        numberOfUsers: null,
        numberOfSuperUsers: null,
      },
      validContactInput: true,
    };
  },
  async mounted() {
    if (!!this.tenant) {
      this.tenantEdit = deepCopy(this.tenant);
    }
  },
  computed: {
    title() {
      return "Tenant ".concat(!!this.tenant ? "szerkesztése" : "létrehozása");
    },
  },
  methods: {
    async submit() {
      this.submitting = true;
      const phoneInput = this.$refs.phoneInput;
      this.v$.$reset();
      phoneInput.v$.$reset();

      this.v$.tenantEdit.$touch();
      phoneInput.v$.$touch();
      if (!this.v$.tenantEdit.$invalid && !phoneInput.v$.$invalid) {
        if (!!this.tenant) {
          await this.$store.dispatch("tenant/editTenant", this.tenantEdit);
        } else {
          await this.$store.dispatch("tenant/createTenant", this.tenantEdit);
        }
        this.$emit("close");
      }
      this.submitting = false;
    },
  },
};
</script>
