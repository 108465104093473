<template>
  <div :class="$style.base">
    <div>
      <loading-component
        loading-width="100%"
        loading-height="32px"
        force-load
      />
    </div>
    <div>
      <loading-component
        loading-width="100%"
        loading-height="32px"
        force-load
      />
      <loading-component
        loading-width="100%"
        loading-height="32px"
        force-load
      />
    </div>
    <div>
      <loading-component
        type="circle"
        loading-width="56px"
        loading-height="56px"
        force-load
      />
      <loading-component
        type="circle"
        loading-width="56px"
        loading-height="56px"
        force-load
      />
    </div>
    <div>
      <loading-component
        loading-width="100%"
        loading-height="32px"
        force-load
      />
    </div>
    <div>
      <loading-component
        loading-width="100%"
        loading-height="32px"
        force-load
      />
      <loading-component
        loading-width="100%"
        loading-height="32px"
        force-load
      />
    </div>
    <div>
      <loading-component
        loading-width="100%"
        loading-height="32px"
        force-load
      />
      <loading-component
        loading-width="100%"
        loading-height="32px"
        force-load
      />
    </div>
    <div>
      <loading-component
        loading-width="100%"
        loading-height="32px"
        force-load
      />
      <loading-component
        loading-width="100%"
        loading-height="32px"
        force-load
      />
    </div>
    <div>
      <loading-component
        loading-width="100%"
        loading-height="32px"
        force-load
      />
    </div>
    <div>
      <loading-component
        loading-width="100%"
        loading-height="32px"
        force-load
      />
    </div>
    <div>
      <loading-component
        loading-width="100%"
        loading-height="32px"
        force-load
      />
    </div>
  </div>
</template>

<script>
import LoadingComponent from "@/components/loading/LoadingComponent.vue";

export default {
  name: "LoadingNormalProjectListing",
  components: { LoadingComponent },
};
</script>

<style module scoped lang="scss">
.base {
  display: flex;
  justify-content: space-around;
  margin-left: 2rem;
  margin-bottom: 12px;
  div {
    padding: 0 1rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 12px;
  }
}
</style>
