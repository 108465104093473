<template>
  <b-tab-item
    :label="$tf('employees.tabs.employees|Kollégák')"
    icon="user-alt"
    type="is-primary"
  >
    <div class="is-flex has-gap-2 is-align-items-center is-flex-wrap-wrap m-4">
      <b-switch v-model="isShowingExEmployees">{{
        $tf("employees.showExes|Ex-kollégák megjelenítése")
      }}</b-switch>
      <span>{{ $tf("employees.filter.external|Kollégák szűrése:") }}</span>
      <b-select style="width: 250px" expanded v-model="externalFilter">
        <option value="ALL">
          {{ $tf("employees.filter.external.all|Minden kolléga") }}
        </option>
        <option value="INTERNAL">
          {{
            $tf("employees.filter.external.only-internal|Csak belsős kollégák")
          }}
        </option>
        <option value="EXTERNAL">
          {{
            $tf("employees.filter.external.only-external|Csak külsős kollégák")
          }}
        </option>
      </b-select>
    </div>
    <b-table
      :data="filteredByExternalStatus"
      ref="table"
      paginated
      per-page="30"
      detailed
      detail-key="email"
      :show-detail-icon="true"
      aria-next-label="Következő lap"
      aria-previous-label="Előző lap"
      aria-page-label="Lap"
      aria-current-label="Aktuális lap"
      :row-class="employeeClass"
    >
      <b-table-column field="avatar" label="Avatar" v-slot="props">
        <div class="employee-avatar">
          <f-avatar :data="props.row" size="96x96" :font-size="2" />
          <b-tag
            v-if="props.row.external"
            type="is-danger"
            class="external-tag"
            rounded
            >{{ $tf("employees.table.external|Külsős") }}</b-tag
          >
        </div>
      </b-table-column>

      <b-table-column
        field="name"
        :label="$tf('employees.table.name|Név')"
        v-slot="props"
        sortable
        :custom-sort="
          (a, b, isAsc) =>
            isAsc
              ? Intl.Collator('hu').compare(a.name, b.name)
              : Intl.Collator('hu').compare(b.name, a.name)
        "
        searchable
      >
        <div>
          <strong>{{ props.row.name }}</strong>
        </div>
        <div>{{ props.row.title }}</div>
      </b-table-column>

      <!--              <b-table-column field="handle" label="Kulcs" sortable v-slot="props">-->
      <!--                {{ props.row.handle }}-->
      <!--              </b-table-column>-->

      <b-table-column
        field="contact"
        :label="$tf('employees.table.contact|Elérhetőség')"
        v-slot="props"
        sortable
        searchable
      >
        <div>{{ thisOrDefault(props.row.email) }}</div>
        <div>
          {{
            $filterPhone(
              !!props.row.contact ? props.row.contact : props.row.phone
            )
          }}
        </div>
      </b-table-column>

      <b-table-column
        field="title"
        :label="
          $tf(
            'employees.table.departmentSeniority|Terület/Szenioritás/Munkakör'
          )
        "
        searchable
        v-slot="props"
      >
        <div>
          <indicator-tag
            v-if="props.row.departmentId"
            :indicator-id="props.row.departmentId"
          />
        </div>
        <div>
          <indicator-tag
            v-if="props.row.seniorityId"
            :indicator-id="props.row.seniorityId"
          />
          <indicator-tag
            class="ml-1"
            v-if="props.row.roleId"
            :indicator-id="props.row.roleId"
          />
        </div>
      </b-table-column>

      <template #detail="props">
        <div class="ml-1 mr-1 container content">
          <p
            class="has-text-centered is-italic subtitle is-5"
            v-if="props.row.flair"
          >
            <sub><b-icon icon="quote-left" size="is-small" /> </sub>
            {{ props.row.flair }}
            <sup><b-icon icon="quote-right" size="is-small" /></sup>
          </p>
          <template v-if="props.row.loggingRequired && props.row.competencies">
            <p>
              {{ $tf("employees.details.competencies|Kompetenciák") }}:
              <b-tag
                rounded
                v-for="(competency, index) in props.row.competencies"
                :key="index"
              >
                {{ competency.name }}
              </b-tag>
            </p>
          </template>
          <p>
            {{ $tf("employees.table.start|Munkaviszony kezdete") }}:
            {{ thisOrDefault(props.row.startDate) }}
          </p>
          <p v-if="props.row.birthDate">
            {{ $tf("employees.table.birthday|Születésnap") }}:
            {{ thisOrDefault(props.row.birthDate) }}
          </p>
          <div
            class="is-flex is-align-items-center mb-5"
            style="gap: 8px"
            v-if="props.row.external"
          >
            <b-tag type="is-warning" size="is-medium">{{
              $tf("employees.details.external|Külsős")
            }}</b-tag>
            {{ $tf("employees.details.parentCompany|Anyacég") }}:
            {{ thisOrDefault(props.row.parentCompany) }}
          </div>
        </div>
      </template>
    </b-table>
  </b-tab-item>
</template>

<script>
import { and, maxLength, minLength } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import FilteredExEmployeesMixin from "../../../mixins/FilteredExEmployeesMixin";
import IndicatorTag from "@/components/module/info/IndicatorTag.vue";
import FAvatar from "@/components/module/icon/FAvatar.vue";

export default {
  name: "EmployeeListEmployeeTab",
  components: { FAvatar, IndicatorTag },
  mixins: [FilteredExEmployeesMixin],
  props: {
    employees: {
      type: Array,
      required: true,
    },
  },
  setup: () => ({ v$: useVuelidate() }),
  data: function () {
    return {
      name: "",
      userData: {
        phoneNumber: null,
        privatePhoneNumber: null,
      },
      masks: {
        phoneNumber: {
          delimiter: "-",
          blocks: [3, 2, 3, 4],
        },
        privatePhoneNumber: {
          delimiter: "-",
          blocks: [3, 2, 3, 4],
        },
      },
      masked: {},
      isShowingExEmployees: false,
      externalFilter: "ALL",
    };
  },
  validations: {
    userData: {
      phoneNumber: {
        length: and(minLength(12), maxLength(12)),
      },
      privatePhoneNumber: {
        length: and(minLength(12), maxLength(12)),
      },
    },
  },
  computed: {
    filteredByExternalStatus() {
      return this.filterByExEmployees.filter((employee) => {
        if (this.externalFilter === "ALL") {
          return true;
        }
        return this.externalFilter === "EXTERNAL"
          ? !!employee.external
          : !employee.external;
      });
    },
  },
  methods: {
    save(userId) {
      this.v$.$touch();
      if (!this.v$.$invalid) {
        let employee = this.employees.filter((emp) => {
          return emp.id === userId;
        });
        employee[0]["active"] = true;
        this.$store.dispatch("employee/update", employee[0]).then(() => {
          this.showModify = false;
        });
      }
    },
    thisOrDefault(self) {
      return self !== undefined && self !== null && self !== ""
        ? self
        : "Ismeretlen";
    },
  },
};
</script>
<style scoped lang="scss">
@import "~@/assets/scss/colors.scss";
.employee-ex {
  td {
    font-style: italic !important;
    color: $grey-lighter;
  }
}
</style>

<style lang="scss">
@import "~@/assets/scss/colors.scss";
.colleagues-table {
  border-radius: 8px 8px 0 0 !important;
}
.colleagues-table-header {
  &:first-of-type {
    border-top-left-radius: 8px !important;
  }
  &:last-of-type {
    border-top-right-radius: 8px !important;
  }
  background: $blue !important;
  .th-wrap {
    span {
      color: $white !important;
    }
  }
}

.employee-avatar {
  position: relative;
  width: fit-content;
  height: fit-content;

  .external-tag {
    position: absolute;
    top: 68%;
    left: 65%;
    padding: 6px;
  }
}

.colleagues-table-cell {
  &:first-of-type {
    border-bottom-left-radius: 8px !important;
  }
  &:last-of-type {
    border-bottom-right-radius: 8px !important;
  }
  height: 52px !important;
  color: $black !important;
  background: $grey-lightest;
}
</style>
