<template>
  <b-field grouped>
    <b-field-with-loading
      v-if="apiKeyWithType || !generate"
      :label="
        $tf('profile.apiKey.label|{type} kulcs', {
          type: label,
        })
      "
      expanded
      width="100%"
    >
      <template v-if="!!apiKeyWithType">
        <b-input disabled expanded value="*************" autocomplete="off" />
      </template>
      <template v-else-if="!generate">
        <b-input
          v-model="computedKey"
          autocomplete="off"
          :placeholder="$tf('profile.apiKey.input.placeholder|API kulcs')"
          password-reveal
          type="password"
        />
      </template>
    </b-field-with-loading>
    <b-field-with-loading
      :label="$tf('profile.apiKey.expiration.label|Érvényesség vége')"
      expanded
      width="100%"
    >
      <template v-if="!!apiKeyWithType">
        <f-datepicker
          :modelValue="
            apiKeyWithType.expireAt
              ? new Date(apiKeyWithType.expireAt)
              : undefined
          "
          :placeholder="
            $tf('profile.apiKey.expiration.placeholder|Érvényesség vége')
          "
          disabled
          icon="calendar-week"
          position="is-bottom-right"
        />
      </template>
      <template v-else>
        <f-datepicker
          v-model="computedExpiration"
          :placeholder="
            $tf('profile.apiKey.expiration.placeholder|Érvényesség vége')
          "
          editable
          icon="calendar-week"
          position="is-bottom-right"
        />
      </template>
    </b-field-with-loading>
    <template v-if="!!apiKeyWithType">
      <div-with-loading
        class="is-flex has-gap-3 pr-5 is-justify-content-end is-align-items-end pb-1"
        loading-height="40px"
        loading-width="40px"
      >
        <clickable-icon
          :tooltip="$tf('profile.apiKey.deleteTooltip|Törlés')"
          icon="trash"
          type="is-danger"
          @click="$emit('delete', apiKeyWithType.id)"
        />
      </div-with-loading>
    </template>
    <template v-else-if="generate">
      <div-with-loading
        class="is-flex has-gap-3 pr-5 is-justify-content-end is-align-items-end"
        loading-height="40px"
        loading-width="40px"
      >
        <b-button icon="certificate" @click="generateKey">
          {{ $tf("profile.apiKey.generateTooltip|Generálás") }}
        </b-button>
      </div-with-loading>
    </template>
  </b-field>
</template>

<script>
import BFieldWithLoading from "@/components/loading/BFieldWithLoading.vue";
import ClickableIcon from "@/components/module/icon/ClickableIcon.vue";
import { mapGetters } from "vuex";
import { API_KEY_TYPES } from "@/utils/const";
import DivWithLoading from "@/components/loading/DivWithLoading.vue";
import FDatepicker from "@/components/module/input/FDatepicker.vue";

export default {
  name: "ApikeyInput",
  components: { FDatepicker, DivWithLoading, ClickableIcon, BFieldWithLoading },
  props: {
    modelValue: {
      type: String,
      default: null,
    },
    expiration: {
      type: [String, Date],
      default: null,
    },
    type: {
      type: String,
      required: true,
    },
    generate: {
      type: Boolean,
      default: false,
    },
  },
  async mounted() {
    await this.$store.dispatch("api_key/list");
  },
  data() {
    return {
      API_KEY_TYPES,
    };
  },
  computed: {
    ...mapGetters({
      apiKeys: "api_key/list",
    }),
    apiKeyWithType() {
      return this.apiKeys.find((it) => it.type === this.type);
    },
    label() {
      return (
        API_KEY_TYPES.ISSUE_TRACKING[this.type] ??
        API_KEY_TYPES.GENERATE[this.type] ??
        API_KEY_TYPES.OTHER[this.type]
      );
    },
    computedKey: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
    computedExpiration: {
      get() {
        return this.expiration;
      },
      set(expiration) {
        this.$emit("expirationChange", expiration);
      },
    },
  },
  methods: {
    generateKey() {
      const length = 128;
      const characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789.";
      let apiKey = "";

      for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        apiKey += characters.charAt(randomIndex);
      }

      this.computedKey = apiKey;
      this.$emit("saveNow", this.type);
    },
  },
};
</script>

<style scoped></style>
