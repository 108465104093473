<template>
  <section class="section">
    <div class="container">
      <h1 class="title">{{ $tf("overtimeRequest.title|Túlóra kérelmek") }}</h1>
      <h2 class="subtitle">
        {{ $tf("overtimeRequest.subtitle|Beküldött túlóra kérelmek kezelése") }}
      </h2>
    </div>

    <div class="container" v-if="toApproveRequests">
      <OvertimeRequestTable
        :items="toApproveRequests.items"
        :pagination="pagination"
        :sort="sort"
        :on-sort="onSort"
        :on-page-change="onPageChange"
        :loading="loading"
        :empty-message="
          $tf(
            'overtimeRequest.table.empty|Jelenleg nincs elbírálásra váró túlóra kérelem.'
          )
        "
      />
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import { createUrlSearchParams, formatDate } from "@/utils/util";
import OvertimeRequestTable from "../../../../components/enterprise/overtime/OvertimeRequestTable";

export default {
  components: { OvertimeRequestTable },
  name: "HandleOvertimeRequestList",
  async mounted() {
    await this.getToApproveRequests();
  },
  data() {
    return {
      formatDate,
      pagination: {
        totalCount: 0,
        page: 0,
        size: 10,
      },
      sort: {
        field: "created",
        order: "desc",
      },
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      toApproveRequests: "overtime_requests/toApproveRequests",
    }),
  },
  methods: {
    async getToApproveRequests() {
      this.loading = true;
      let requestParams = {
        params: createUrlSearchParams(this.pagination, this.sort),
      };

      await this.$store.dispatch(
        "overtime_requests/getOvertimeRequestToApprove",
        {
          params: requestParams,
          force: true,
        }
      );
      this.pagination = this.toApproveRequests.pagination;
      this.loading = false;
    },
    onPageChange: function (page) {
      this.pagination.page = page - 1;
      this.getToApproveRequests();
    },
    onSort: function (field, order) {
      this.sort.field = field;
      this.sort.order = order;
      this.getToApproveRequests();
    },
  },
};
</script>

<style scoped></style>
