<template>
  <div
    v-if="username"
    class="avatar-placeholder"
    :style="`background: ${userColor}; font-size: ${fontSize}rem;`"
  >
    <div
      class="avatar-placeholder-text"
      :style="`color: ${getContrastedColor(userColor)};`"
    >
      {{ userMonogram }}
    </div>
  </div>
</template>

<script>
import {
  stringToColor,
  createMonogram,
  getContrastedColor,
  hslToHex,
} from "@/utils/util";

export default {
  name: "AvatarPlaceholder",
  data() {
    return {
      stringToColor,
      createMonogram,
      getContrastedColor,
    };
  },
  props: {
    username: {
      type: String,
      required: true,
    },
    fontSize: {
      type: Number,
      default: 1,
    },
    predefinedColor: {
      type: String,
      required: false,
      default: null,
    },
  },
  computed: {
    userColor() {
      let predefinedColor = this.predefinedColor;
      if (predefinedColor?.startsWith("hsl")) {
        const parts = this.predefinedColor
          .replace(/hsl\(|\)|\s|%/g, "")
          .split(",");
        predefinedColor = hslToHex(parts[0], parts[1], parts[2]);
      }
      return predefinedColor || this.stringToColor(this.username);
    },
    userMonogram() {
      return this.createMonogram(this.username);
    },
  },
};
</script>

<style scoped lang="scss">
.avatar-placeholder {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  border-radius: 100%;
  align-items: center;
  justify-content: center;

  &-text {
    font-weight: bold;
    color: black;
  }
}
</style>
