<template>
  <section class="section">
    <div class="container is-flex is-justify-content-space-between">
      <div>
        <h1 class="title">
          {{ $tf("forecast.scenario.title|Scenariok") }}
        </h1>
        <h2 class="subtitle">
          {{ $tf("forecast.scenario.subtitle|Scenariok beállítása") }}
        </h2>
      </div>
      <div
        class="is-flex is-align-items-center has-text-centered has-gap-1 is-justify-content-end"
        v-if="canCreate"
      >
        <p
          class="has-text-red has-font-weight-500 mb-0"
          v-if="noChanceCategories"
        >
          {{
            $tf(
              "forecast.scenario.noChanceCategoroies|Addig nem vehető fel scenario, amíg nincsenek esélykategóriák felvéve!"
            )
          }}
        </p>
        <b-button
          class="ml-auto"
          type="is-info"
          @click="openEdit(null)"
          icon-left="plus-circle"
          :disabled="noChanceCategories"
        >
          {{ $tf("forecast.scenario.newButton|Új scenario felvétele") }}
        </b-button>
      </div>
    </div>

    <div class="container">
      <b-table
        :data="scenarios"
        sticky-header
        striped
        hoverable
        :loading="!loaded"
        mobile-cards
        ref="table"
        height="auto"
      >
        <b-table-column
          field="name"
          :label="$tf('forecast.scenario.nameTableHeader|Név')"
          sortable
          searchable
          v-slot="props"
        >
          <div class="is-flex is-align-items-center-has-gap-1">
            <label class="has-font-weight-500">{{ props.row.name }}</label>
            <b-tooltip
              :label="$tf('forecast.scenario.defaultTooltip|Alapértelmezett')"
              append-to-body
              v-if="props.row.isDefault"
            >
              <b-icon icon="check-circle" />
            </b-tooltip>
          </div>
        </b-table-column>

        <b-table-column
          field="filters"
          :label="
            $tf('forecast.scenario.chanceCategoriesTableHeader|Esélykategóriák')
          "
          sortable
          searchable
          v-slot="props"
        >
          {{ chanceCategoryString(props.row) }}
        </b-table-column>

        <b-table-column
          v-slot="props"
          cell-class="do-not-wrap"
          width="32"
          :visible="canEdit"
        >
          <div class="is-flex has-gap-3 pr-5">
            <clickable-icon
              icon="pencil"
              type="is-info"
              class="mr-2"
              @click="openEdit(props.row)"
              :tooltip="$tf('forecast.scenario.editTooltip|Szerkesztés')"
              v-if="canEdit"
            ></clickable-icon>
            <clickable-icon
              icon="trash"
              type="is-danger"
              @click="deleteRow(props.row.id)"
              v-if="canDelete && props.row.id > 5"
              :tooltip="$tf('forecast.scenario.deleteTooltip|Törlés')"
            ></clickable-icon>
          </div>
        </b-table-column>

        <template #empty>
          <section class="section">
            <div class="content has-text-grey has-text-centered">
              <p>
                <b-icon icon="frown" size="is-large"></b-icon>
              </p>
              <p>
                {{
                  $tf(
                    "forecast.scenario.emptyTable|Még nincsenek scenariok felvéve."
                  )
                }}
              </p>
            </div>
          </section>
        </template>
      </b-table>
    </div>
  </section>
</template>

<script>
import { PRIVILEGES } from "@/utils/const";
import { mapGetters } from "vuex";

import ClickableIcon from "@/components/module/icon/ClickableIcon.vue";
import ForecastScenarioEditModal from "@/components/forecast/edit/ForecastScenarioEditModal.vue";

import { openDeleteConfirm } from "@/utils/util";

export default {
  name: "ForecastScenario",
  data() {
    return {
      loaded: false,
    };
  },
  components: {
    ClickableIcon,
  },
  computed: {
    ...mapGetters({
      scenarios: "forecast_scenario/scenarios",
      chanceCategories: "forecast_chance_category/chanceCategories",
    }),
    canCreate() {
      return this.$store.getters["session/hasAnyAuthority"](
        PRIVILEGES.ENTERPRISE.FORECAST.SCENARIO.CREATE,
        PRIVILEGES.ENTERPRISE.FORECAST.MISC.ADMIN
      );
    },
    canEdit() {
      return this.$store.getters["session/hasAnyAuthority"](
        PRIVILEGES.ENTERPRISE.FORECAST.SCENARIO.EDIT,
        PRIVILEGES.ENTERPRISE.FORECAST.MISC.ADMIN
      );
    },
    canDelete() {
      return this.$store.getters["session/hasAnyAuthority"](
        PRIVILEGES.ENTERPRISE.FORECAST.SCENARIO.DELETE,
        PRIVILEGES.ENTERPRISE.FORECAST.MISC.ADMIN
      );
    },
    noChanceCategories() {
      return !this.chanceCategories?.length;
    },
  },
  methods: {
    chanceCategoryString(row) {
      return row?.chanceCategories
        ?.map((c) => c.chance)
        ?.sort((a, b) => Number(b) - Number(a))
        ?.join(", ");
    },
    openEdit(row) {
      this.$buefy.modal.open({
        parent: this,
        component: ForecastScenarioEditModal,
        props: { scenario: row },
        hasModalCard: true,
        trapFocus: true,
        events: {
          save: () => this.load(true),
        },
      });
    },
    async deleteRow(id) {
      openDeleteConfirm(this.$buefy, async () => {
        await this.$store.dispatch("forecast_scenario/deleteScenario", id);
        await this.load(true);
      });
    },
    async load(force) {
      this.loaded = false;

      await this.$store.dispatch(
        "forecast_chance_category/fetchChanceCategories",
        force
      );
      await this.$store.dispatch("forecast_scenario/fetchScenarios", force);
      this.loaded = true;
    },

    async save(row) {
      await this.$store.dispatch("forecast_scenario/createScenario", row);
      await this.load(true);
    },
  },
  async mounted() {
    await this.load();
    this.loaded = true;
  },
};
</script>

<style scoped lang="scss">
@import "../../../assets/scss/colors";

.link.is-danger {
  color: $red;
}

.red {
  color: $red;
}

.cost-label {
  color: $custom-grayer-blue;
}

.cost-value {
  font-weight: 500;
  color: $custom-dark-blue;
}

.cost-since {
  color: $custom-grayer-blue;
  font-size: 14px;
}

.tier-toggle {
  :deep(.has-text-link) {
    color: $custom-dark-blue !important;
  }
}

.users-without-tiers-container {
  h1.title {
    color: $red;
    font-size: 1.1rem;
  }
}

.fixed-price-margin {
  margin-bottom: 2rem;
}
</style>
