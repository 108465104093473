<template>
  <div>
    <b-skeleton width="30%" :animated="true"></b-skeleton>
    <b-skeleton width="70%" :animated="true"></b-skeleton>
    <b-skeleton width="20%" :animated="true"></b-skeleton>
    <b-skeleton width="60%" :animated="true"></b-skeleton>
    <b-skeleton width="40%" :animated="true"></b-skeleton>
    <b-skeleton width="50%" :animated="true"></b-skeleton>
  </div>
</template>

<script>
export default {
  name: "LoadingSkeleton",
};
</script>
