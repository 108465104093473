<template>
  <div>
    <MoneyTable
      v-if="!hideInvoices"
      :data="projectData.invoices ?? []"
      :tigs="projectData.tigDatas"
      :on-approve="canEvaluateInvoice ? payInvoice : null"
      :on-cancel="canEvaluateInvoice ? cancelInvoice : null"
      :on-create="createInvoice"
      :on-modify="modifyInvoice"
      type="invoices"
      style="margin-bottom: 4rem"
    />
    <hr />
    <MoneyTable
      v-if="type === PROJECT_TYPES.BASIC"
      :data="projectData.costs ?? []"
      :on-create="createExpense"
      :on-modify="modifyExpense"
      :on-delete="deleteExpense"
      type="expenses"
      style="margin-bottom: 4rem"
    />
    <hr />
    <MoneyTable
      v-if="type === PROJECT_TYPES.BASIC"
      :data="projectData.overrides ?? []"
      :on-create="createOverride"
      :on-modify="modifyOverride"
      :on-delete="deleteOverride"
      type="overrides"
    />
  </div>
</template>

<script>
import { deepCopy, formatDate, moneyify, numberify } from "@/utils/util";
import { INVOICE_STATUS, PRIVILEGES, PROJECT_TYPES } from "@/utils/const";
import MoneyTable from "@/components/enterprise/ProjectDetails/TabItems/MoneyTable.vue";

export default {
  name: "InvoiceTabItem",
  components: {
    MoneyTable,
  },
  props: {
    projectData: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    hideInvoices: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      PROJECT_TYPES,
      newData: {},
      modifiedData: {},
      name: "",
      editModal: false,
      createModal: false,
      sSummary: false,
      vSummary: "",
      editData: {},
      moneyify,
      formatDate,
    };
  },
  computed: {
    canEvaluateInvoice() {
      return this.$store.getters["session/hasAnyAuthority"](
        PRIVILEGES.ENTERPRISE.PROJECT_EXTRAS.EVALUATE_INVOICE,
        PRIVILEGES.ENTERPRISE.PROJECT_EXTRAS.ADMIN
      );
    },
  },
  methods: {
    async fetchData(force) {
      this.$emit("fetch", force);
    },
    toggleDetails(props) {
      this.modifiedData = deepCopy(props.row);
      if (props.row.date) {
        this.modifiedData.date = new Date(props.row.date);
      }
      if (props.row.day) {
        this.modifiedData.day = new Date(props.row.day);
      }
      props.toggleDetails(props.row);
    },
    async createExpense(expense) {
      await this.$store.dispatch("enterprise_projects/createOrEditExpense", {
        projectId: this.projectData.id,
        tigIdentifier: expense.tigIdentifier,
        identifier: expense.identifier,
        summary: expense.summary,
        cost: numberify(expense.cost),
        date: expense.date,
      });
      await this.fetchData(true);
    },
    async modifyExpense(expense) {
      expense.projectId = this.projectData.id;
      await this.$store.dispatch("enterprise_projects/createOrEditExpense", {
        projectId: this.projectData.id,
        tigIdentifier: expense.tigIdentifier,
        identifier: expense.identifier,
        summary: expense.summary,
        cost: numberify(expense.cost),
        date: expense.date,
      });
      await this.fetchData(true);
    },
    async deleteExpense(identifier) {
      await this.$store.dispatch("enterprise_projects/deleteExpense", {
        projectId: this.projectData.id,
        identifier: identifier,
      });
      await this.fetchData(true);
    },
    async createOverride(override) {
      await this.$store.dispatch("enterprise_projects/createOrEditOverride", {
        projectId: this.projectData.id,
        userId: override.userId,
        cost: numberify(override.cost),
      });
      await this.fetchData(true);
    },
    async modifyOverride(override) {
      override.projectId = this.projectData.id;
      await this.$store.dispatch("enterprise_projects/createOrEditOverride", {
        projectId: this.projectData.id,
        userId: override.userId,
        cost: numberify(override.cost),
      });
      await this.fetchData(true);
    },
    async deleteOverride(id) {
      await this.$store.dispatch("enterprise_projects/deleteOverride", {
        projectId: this.projectData.id,
        userId: id,
      });
      await this.fetchData(true);
    },
    async createInvoice(invoice) {
      await this.$store.dispatch("enterprise_projects/createOrEditInvoice", {
        projectId: this.projectData.id,
        number: invoice.number,
        status: INVOICE_STATUS.DUE.enum,
        tigIdentifiers: invoice.tigIdentifiers,
        summary: invoice.summary,
        cost: numberify(invoice.cost),
        date: formatDate(invoice.date),
      });
      await this.fetchData(true);
    },
    async modifyInvoice(invoice) {
      invoice.projectId = this.projectData.id;
      await this.$store.dispatch("enterprise_projects/createOrEditInvoice", {
        id: invoice.id,
        projectId: this.projectData.id,
        number: invoice.number,
        status: invoice.status,
        tigIdentifiers: invoice.tigIdentifiers,
        summary: invoice.summary,
        cost: numberify(invoice.cost),
        date: formatDate(invoice.date),
      });
      await this.fetchData(true);
    },
    async payInvoice(id) {
      this.$buefy.dialog.prompt({
        title: this.$tf(
          "projectpage.invoice.markPaidModal.title|Számla fizetettnek jelölése"
        ),
        message: this.$tf(
          "projectpage.invoice.markPaidModal.message|Kifizetés dátuma"
        ),
        confirmText: this.$tf(
          "projectpage.invoice.markPaidModal.confirm|Jelölés"
        ),
        cancelText: this.$tf("projectpage.invoice.markPaidModal.cancel|Mégsem"),
        inputAttrs: {
          type: "date",
          value: formatDate(new Date()),
        },
        trapFocus: true,
        onConfirm: async (value) => {
          await this.$store.dispatch("enterprise_projects/evaluateInvoice", {
            id: id,
            request: {
              status: INVOICE_STATUS.PAID.enum,
              date: formatDate(value),
            },
          });
          await this.fetchData(true);
        },
      });
    },
    async cancelInvoice(id) {
      this.$buefy.dialog.confirm({
        title: this.$tf(
          "projectpage.invoice.cancelModal.title|Számla visszavonása"
        ),
        message: this.$tf(
          "projectpage.invoice.cancelModal.message|Biztosan vissza szeretnéd vonni a számlát?"
        ),
        confirmText: this.$tf(
          "projectpage.invoice.cancelModal.confirm|Visszavonás"
        ),
        cancelText: this.$tf("projectpage.invoice.cancelModal.cancel|Mégse"),
        trapFocus: true,
        type: "is-danger",
        onConfirm: async () => {
          await this.$store.dispatch("enterprise_projects/evaluateInvoice", {
            id: id,
            request: {
              status: INVOICE_STATUS.CANCELLED.enum,
              date: formatDate(new Date()),
            },
          });
          await this.fetchData(true);
        },
      });
    },
  },
};
</script>

<style scoped></style>
