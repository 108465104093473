import { newLogger } from "@/utils/util";
import StoreCore from "../../../StoreCore";
import network from "@/utils/network";

let logger = newLogger("Enterprise-RiskManagement");
const urls = {
  unknownTimesheets: (onlyMyTeam) =>
    `/enterprise/risk-management/unknown-timesheets?onlyMyTeam=${onlyMyTeam}`,
  unknownExport: (since) =>
    `/enterprise/risk-management/unknown-timesheets/export?since=${since}`,
  missingTimesheets: (onlyMyTeam) =>
    `/enterprise/risk-management/missing-timesheets?onlyMyTeam=${onlyMyTeam}`,
  unknownIssues: (prefix) =>
    `/enterprise/risk-management/unknown-issues${
      prefix ? "?prefix=" + prefix : ""
    }`,
  sharedIssues: (id) =>
    `/enterprise/risk-management/shared-issues${id ? "?id=" + id : ""}`,
  loggingWillingness: (onlyMyTeam) =>
    `/enterprise/risk-management/logging-willingness?onlyMyTeam=${onlyMyTeam}`,
  ignoredPrefix: (id) => `/enterprise/risk-management/ignored-prefixes/${id}`,
  ignoredPrefixes: "/enterprise/risk-management/ignored-prefixes",
};

const initState = () => {
  return {
    unknownTimesheets: {},
    missingTimesheets: {},
    sharedIssues: {},
    unknownIssues: {},
    loggingWillingness: {},
    ignoredPrefixes: [],
  };
};

const storeCore = new StoreCore();
const actions = {
  fetchMissingTimesheets: async function ({ commit, dispatch }) {
    try {
      let data = await this.remoteRequest(
        "get",
        urls.missingTimesheets(false),
        null,
        true,
        dispatch,
        "Hiányzó logolások letöltése..."
      );
      commit("saveMissingTimesheets", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  fetchUnknownIssues: async function ({ commit, dispatch }, prefix) {
    try {
      let data = await this.remoteRequest(
        "get",
        urls.unknownIssues(prefix),
        null,
        true,
        dispatch,
        "Ismeretlen jegyek letöltése..."
      );
      commit("saveUnknownIssues", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  fetchUnknownTimesheets: async function ({ commit, dispatch }) {
    try {
      let data = await this.remoteRequest(
        "get",
        urls.unknownTimesheets(false),
        null,
        true,
        dispatch,
        "Ismeretlen logolások letöltése..."
      );
      commit("saveUnknownTimesheets", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  fetchSharedIssues: async function ({ commit, dispatch }, id) {
    try {
      let data = await this.remoteRequest(
        "get",
        urls.sharedIssues(id),
        null,
        true,
        dispatch,
        "Többszörösen célzott jegyek letöltése..."
      );
      commit("saveSharedIssues", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  fetchIgnoredPrefixes: async function ({ commit, dispatch }, force) {
    try {
      let data = await this.remoteRequest(
        "get",
        urls.ignoredPrefixes,
        null,
        true,
        dispatch,
        "Ignorált prefixek letöltése...",
        null,
        force
      );
      commit("saveIgnoredPrefixes", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  fetchLoggingWillingness: async function ({ commit, dispatch }) {
    try {
      let data = await this.remoteRequest(
        "get",
        urls.loggingWillingness(false),
        null,
        true,
        dispatch,
        "Logolási hajlandóságok letöltése..."
      );
      commit("saveLoggingWillingness", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  createIgnoredPrefix: async function ({ commit, dispatch }, ignoredPrefix) {
    try {
      await this.remoteRequest(
        "post",
        urls.ignoredPrefixes,
        ignoredPrefix,
        false,
        dispatch,
        "Ignorált prefix létrehozása..."
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  editIgnoredPrefix: async function ({ commit, dispatch }, ignoredPrefix) {
    try {
      await this.remoteRequest(
        "put",
        urls.ignoredPrefixes,
        ignoredPrefix,
        false,
        dispatch,
        "Ignorált prefix szerkesztése...",
        undefined,
        undefined,
        false,
        true,
        {
          progress: "Prefix mentése folyamatban...",
          fail: "Prefix mentése sikertelen!",
          success: "Prefix mentése sikeres!",
        }
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  deleteIgnoredPrefix: async function ({ commit, dispatch }, prefix) {
    try {
      await this.remoteRequest(
        "delete",
        urls.ignoredPrefix(prefix),
        null,
        true,
        dispatch,
        "Ignorált prefix törlése..."
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  exportUnknown: async function ({ dispatch }, { since }) {
    dispatch(
      "loading/startLoading",
      {
        id: 1337,
        type: "GET",
        url: urls.unknownExport(since),
        flair: "Ismeretlen logolások exportálása...",
      },
      { root: true }
    );

    try {
      const axios = await network.excelConnection();
      const { data } = await axios.get(urls.unknownExport(since));

      dispatch(
        "loading/finishLoading",
        { id: 1337, result: true },
        { root: true }
      );

      return data;
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  },
  clearCache: async function ({}) {
    this.clearCoreCache();
  }.bind(storeCore),
};

const mutations = {
  saveMissingTimesheets: (state, stuff) => {
    state.missingTimesheets = stuff;
  },
  saveUnknownIssues: (state, issues) => {
    state.unknownIssues = issues;
  },
  saveUnknownTimesheets: (state, stuff) => {
    state.unknownTimesheets = stuff;
  },
  saveSharedIssues: (state, issues) => {
    state.sharedIssues = issues;
  },
  saveLoggingWillingness: (state, stuff) => {
    state.loggingWillingness = stuff;
  },
  saveIgnoredPrefixes: (state, ignoredPrefixes) => {
    state.ignoredPrefixes = ignoredPrefixes;
  },
};

const getters = {
  getMissingTimesheets: (state) => {
    return state.missingTimesheets;
  },
  getUnknownIssues: (state) => {
    return state.unknownIssues;
  },
  getUnknownIssuesFiltered: (state) => (filter) => {
    for (let p in state.unknownIssues.projects) {
      let record = state.unknownIssues.projects[p];
      if (record.prefix === filter) return record.issues;
    }
    return [];
  },
  getUnknownTimesheets: (state) => {
    return state.unknownTimesheets;
  },
  getSharedIssues: (state) => {
    return state.sharedIssues;
  },
  getSharedIssuesFiltered: (state) => (filter) => {
    for (let p in state.sharedIssues.projects) {
      let record = state.sharedIssues.projects[p];
      if (record.prefix === filter) return record.issues;
    }
    return [];
  },
  getLoggingWillingness: (state) => {
    return state.loggingWillingness;
  },
  getIgnoredPrefixes: (state) => {
    return state.ignoredPrefixes;
  },
};

export default {
  namespaced: true,
  state: initState(),
  mutations: mutations,
  actions: actions,
  getters: getters,
};
