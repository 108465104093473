<template>
  <div>
    <template v-if="loading">
      <LoadingSkeleton></LoadingSkeleton>
    </template>
    <template v-else-if="issues.length > 0">
      <div
        v-for="project in issues"
        class="container mt-4 mb-4"
        :key="project.projectId"
      >
        <h1 class="subtitle">
          {{ project.projectName }} ({{ project.projectIdentifier }})
        </h1>
        <div class="columns">
          <div class="column">
            <h3 class="subtitle">{{ $tf("taskPage.taskTab.toDo|Teendő") }}</h3>
            <template v-if="project.issues.find((i) => i.status === 'TODO')">
              <logging-task-card
                v-for="issue in project.issues.filter(
                  (i) => i.status === 'TODO'
                )"
                :key="issue.id"
                :employees="employees"
                :issue="issue"
              ></logging-task-card>
            </template>
            <template v-else>
              <div class="card p-4">
                <div class="content">
                  <p
                    class="has-font-weight-700 has-text-grey has-text-centered"
                  >
                    {{
                      $tf("taskPage.taskTab.toDO.empty|Nincsenek teendő jegyek")
                    }}
                  </p>
                </div>
              </div>
            </template>
          </div>
          <div class="column">
            <h3 class="subtitle">
              {{ $tf("taskPage.taskTab.doing|Folyamatban") }}
            </h3>
            <template
              v-if="project.issues.find((i) => i.status === 'IN_PROGRESS')"
            >
              <logging-task-card
                v-for="issue in project.issues.filter(
                  (i) => i.status === 'IN_PROGRESS'
                )"
                :key="issue.id"
                :employees="employees"
                :issue="issue"
              ></logging-task-card>
            </template>
            <template v-else>
              <div class="card p-4">
                <div class="content">
                  <p
                    class="has-font-weight-700 has-text-grey has-text-centered"
                  >
                    {{
                      $tf(
                        "taskPage.taskTab.doing.empty|Nincsenek folyamatban lévő jegyek"
                      )
                    }}
                  </p>
                </div>
              </div>
            </template>
          </div>
          <div class="column">
            <h3 class="subtitle">
              {{ $tf("taskPage.taskTab.done|Elvégezve") }}
            </h3>
            <template v-if="project.issues.find((i) => i.status === 'DONE')">
              <logging-task-card
                v-for="issue in project.issues.filter(
                  (i) => i.status === 'DONE'
                )"
                :key="issue.id"
                :employees="employees"
                :issue="issue"
              ></logging-task-card>
            </template>
            <template v-else>
              <div class="card p-4">
                <div class="content">
                  <p
                    class="has-font-weight-700 has-text-grey has-text-centered"
                  >
                    {{
                      $tf(
                        "taskPage.taskTab.done.empty|Nincsenek elvégzett jegyek"
                      )
                    }}
                  </p>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="container mt-4 mb-4">
        <div class="content has-text-grey has-text-centered">
          <p>
            <b-icon icon="exclamation-triangle" size="is-large"></b-icon>
          </p>
          <p>
            {{
              $tf("taskPage.noOngoingProjects|Nincs folyamatban lévő projekt!")
            }}
          </p>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import LoadingSkeleton from "@/components/module/loading/LoadingSkeleton.vue";
import { mapGetters } from "vuex";
import { TASK_TYPE } from "@/utils/const";
import LoggingTaskCard from "@/components/enterprise/logging/LoggingTaskCard.vue";

export default {
  name: "SelfTrackingTaskList",
  components: { LoggingTaskCard, LoadingSkeleton },
  data() {
    return {
      TASK_TYPE,
      loading: true,
    };
  },
  computed: {
    ...mapGetters({
      issues: "enterprise_core/activeIssues",
      employees: "employee/loggingEmployees",
    }),
  },
  mounted() {
    this.$store.dispatch("enterprise_core/fetchActiveIssues");
    // this.$store.dispatch("employee/fetchWorkers");
    this.loading = false;
  },
};
</script>

<style scoped lang="scss"></style>
