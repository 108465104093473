<template>
  <div>
    <b-table
      :data="journalEntries(projectId) || []"
      ref="journalTable"
      :per-page="15"
      paginated
      aria-next-label="Következő lap"
      aria-previous-label="Előző lap"
      aria-page-label="Lap"
      aria-current-label="Aktuális lap"
      detailed
      detail-key="id"
      detail-transition="fade"
      :opened-detailed="openRows"
      :row-class="(row, index) => 'cursor-pointer'"
      @click="
        (row) =>
          openRows.includes(row.id)
            ? openRows.splice(openRows.indexOf(row.id), 1)
            : openRows.push(row.id)
      "
    >
      <b-table-column
        sortable
        field="created"
        :label="$tf('projectPage.journal.table.created|Időpont')"
        v-slot="props"
      >
        {{ formatDateTime(new Date(props.row.created)) }}
      </b-table-column>

      <b-table-column
        sortable
        field="tigIdentifier"
        :label="$tf('projectPage.journal.table.tig|Mérföldkő')"
        v-slot="props"
      >
        {{ props.row.tigIdentifier }}
      </b-table-column>

      <b-table-column
        sortable
        field="createdBy"
        :label="$tf('projectPage.journal.table.editor|Szerkesztő')"
        v-slot="props"
      >
        {{ props.row.createdBy }}
      </b-table-column>

      <b-table-column
        sortable
        field="summary"
        :label="$tf('projectPage.journal.table.summary|Megjegyzés')"
        v-slot="props"
      >
        <span v-if="props.row.summary">{{ props.row.summary }}</span>
        <span v-else>{{
          $tf("projectPage.journal.table.summary.empty|Nincs megjegyzés")
        }}</span>
      </b-table-column>

      <b-table-column
        :label="$tf('projectPage.journal.table.changes|Módosítások')"
        centered
        v-slot="props"
      >
        <div class="is-flex has-gap-1">
          <b-tag v-for="(change, id) in props.row.changes" :key="id">
            {{ targetMap.get(change.target)?.toUpperCase() }}
          </b-tag>
        </div>
      </b-table-column>

      <template #empty>
        <p>
          {{ $tf("projectPage.journal.table.empty|Még nincs naplóbejegyzés") }}
        </p>
      </template>

      <template #detail="props">
        <div class="container">
          <div class="subtitle my-2">
            {{
              $tf(
                "projectPage.journal.table.details.createdBy|Szerkesztő neve:"
              )
            }}
            {{ props.row.createdBy }}
          </div>
          <div class="subtitle my-2">
            {{
              $tf(
                "projectPage.journal.table.details.created|Szerkesztés ideje:"
              )
            }}
            {{ formatDateTime(props.row.created) }}
          </div>
          <div class="subtitle my-2">
            {{
              $tf(
                "projectPage.journal.table.details.summary|Szerkesztés összegzése:"
              )
            }}
            {{
              props.row.summary
                ? props.row.summary
                : $tf(
                    "projectPage.journal.table.details.summary.empty|Nincs összegzés"
                  )
            }}
          </div>
          <div class="subtitle mb-1">
            {{
              $tf(
                "projectPage.journal.table.details.description|Szerkesztés leírása:"
              )
            }}:
          </div>
          <div class="mb-2">
            {{
              props.row.description
                ? props.row.description
                : $tf(
                    "projectPage.journal.table.details.description.empty|Nincs leírás"
                  )
            }}
          </div>
          <div class="subtitle mb-1">
            {{
              $tf("projectPage.journal.table.details.changes|Változtatások:")
            }}
          </div>
          <div>
            <b-table :data="props.row.changes">
              <b-table-column
                field="target"
                :label="
                  $tf('projectPage.journal.table.details.changes.target|Mező')
                "
                v-slot="props"
              >
                {{ targetMap.get(props.row.target) }}
              </b-table-column>
              <b-table-column
                :label="
                  $tf(
                    'projectPage.journal.table.details.changes.value|Módosítás'
                  )
                "
                v-slot="props"
              >
                <span v-if="props.row.value !== null">{{
                  props.row.value
                }}</span>
                <span v-else-if="props.row.amount !== null">{{
                  formatAmount(props.row)
                }}</span>
                <span v-else>{{ formatDate(props.row.date) }}</span>
              </b-table-column>
            </b-table>
          </div>
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { formatDate, formatDateTime, moneyify } from "@/utils/util";
import JournalDetailsModal from "@/components/enterprise/project/journal/JournalDetailsModal";

export default {
  name: "ProjectJournal",
  props: {
    projectId: {
      type: Number,
      required: true,
    },
    projectIdentifier: {
      type: String,
      required: true,
    },
    tigIdentifiers: {
      type: Array,
      required: true,
    },
    addingNew: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      formatDate,
      formatDateTime,
      moneyify,
      openRows: [],
      targetMap: new Map([
        ["NAME", this.$tf("projectPage.journal.targetType.name|Név")],
        ["KEY", this.$tf("projectPage.journal.targetType.key|Kulcs")],
        ["SUMMARY", this.$tf("projectPage.journal.targetType.summary|Leírás")],
        ["CLIENT", this.$tf("projectPage.journal.targetType.client|Ügyfél")],
        ["LEAD", this.$tf("projectPage.journal.targetType.manager|Felelős")],
        ["TYPE", this.$tf("projectPage.journal.targetType.type|Típus")],
        [
          "PRICING_MODEL",
          this.$tf("projectPage.journal.targetType.pricingModel|Árazás"),
        ],
        [
          "MANAGEMENT_TYPE",
          this.$tf("projectPage.journal.targetType.managementType|Szervezés"),
        ],
        [
          "LABOR_TYPES",
          this.$tf("projectPage.journal.targetType.laborTypes|Feladattípusok"),
        ],
        [
          "TECHNOLOGIES",
          this.$tf("projectPage.journal.targetType.technologies|Technológiák"),
        ],
        [
          "SUPPORT_POTENTIAL",
          this.$tf(
            "projectPage.journal.targetType.supportPotential|Támogatási lehetőségek"
          ),
        ],
        [
          "CATEGORIES",
          this.$tf("projectPage.journal.targetType.categories|Kategóriák"),
        ],
        [
          "DEADLINE",
          this.$tf("projectPage.journal.targetType.deadline|Befejezés"),
        ],
        ["BUDGET", this.$tf("projectPage.journal.targetType.budget|Büdzsé")],
        ["INCOME", this.$tf("projectPage.journal.targetType.income|Bevétel")],
        [
          "COMMITMENT",
          this.$tf("projectPage.journal.targetType.commitment|Vállalás"),
        ],
        ["STATUS", this.$tf("projectPage.journal.targetType.status|Státusz")],
        [
          "BILLABLE_AMOUNT",
          this.$tf(
            "projectPage.journal.targetType.billableAmount|Számlázható összeg"
          ),
        ],
        [
          "PROGRESS_TYPE",
          this.$tf(
            "projectPage.journal.targetType.progressType|Előrehaladás típus"
          ),
        ],
      ]),
    };
  },
  computed: {
    ...mapGetters({
      journalEntries: "enterprise_journal/entries",
    }),
  },
  methods: {
    formatAmount(change) {
      if (change.target === "COMMITMENT") {
        return change.amount > 0
          ? "+" + change.amount + " nap"
          : change.amount + " nap";
      } else if (
        change.target === "INCOME" ||
        change.target === "BUDGET" ||
        change.target === "BILLABLE_AMOUNT"
      ) {
        return change.amount > 0
          ? "+" + this.moneyify(change.amount)
          : this.moneyify(change.amount);
      }
      return change.amount;
    },

    async approveJournalEntry(id) {
      this.$buefy.dialog.confirm({
        title: this.$tf(
          "projectPage.journal.approveModal.title|Jóváhagyás megerősítése"
        ),
        message: this.$tf(
          "projectPage.journal.approveModal.message|Biztos vagy benne, hogy jóváhagyod?"
        ),
        confirmText: this.$tf(
          "projectPage.journal.approveModal.confirm|Jóváhagyás"
        ),
        cancelText: this.$tf("projectPage.journal.approveModal.cancel|Mégse"),
        type: "is-warning",
        hasIcon: true,
        onConfirm: async () => {
          await this.$store.dispatch("enterprise_journal/approve", id);
          await this.$store.dispatch("enterprise_journal/fetchEntries", {
            key: this.projectId,
            force: true,
          });
        },
      });
    },
    async denyJournalEntry(id) {
      this.$buefy.dialog.confirm({
        title: this.$tf(
          "projectPage.journal.denyModal.title|Elutasítás megerősítése"
        ),
        message: this.$tf(
          "projectPage.journal.denyModal.message|Biztos vagy benne, hogy elutasítod?"
        ),
        confirmText: this.$tf(
          "projectPage.journal.denyModal.confirm|Elutasítás"
        ),
        cancelText: this.$tf("projectPage.journal.denyModal.cancel|Mégse"),
        type: "is-warning",
        hasIcon: true,
        onConfirm: async () => {
          await this.$store.dispatch("enterprise_journal/deny", id);
          await this.$store.dispatch("enterprise_journal/fetchEntries", {
            key: this.projectId,
            force: true,
          });
        },
      });
    },

    onSave() {
      this.newEntry = {
        summary: "",
        description: "",
        tigIdentifier: this.projectIdentifier,
        priority: 4,
        escalation: false,
        changes: [],
        tags: [],
      };
      this.$store.dispatch("enterprise_journal/fetchEntries", {
        key: this.projectId,
        force: true,
      });
      this.$emit("save");
    },
    async deleteJournalEntry(id) {
      openDeleteConfirm(this.$buefy, async () => {
        await this.$store.dispatch("enterprise_journal/deleteEntry", id);
        this.$emit("delete");
        await this.$store.dispatch("enterprise_journal/fetchEntries", {
          key: this.projectId,
          force: true,
        });
      });
    },
    convertPriority(prio) {
      switch (prio) {
        case 1:
          return this.$tf("projectPage.journal.priority.level1|Kritikus");
        case 2:
          return this.$tf("projectPage.journal.priority.level2|Magas");
        case 3:
          return this.$tf("projectPage.journal.priority.level3|Közepes");
        case 4:
          return this.$tf("projectPage.journal.priority.level4|Alacsony");
        case 5:
          return this.$tf("projectPage.journal.priority.level5|Tervezett");
      }
    },
    getPriorityClass(prio) {
      switch (prio) {
        case 1:
          return "is-danger";
        case 2:
          return "is-danger is-light";
        case 3:
          return "is-warning";
        case 4:
          return "is-success is-light";
        case 5:
          return "is-unknown";
      }
    },
    emit(event, args) {
      this.$emit(event, args);
    },
    showDetails(entry) {
      this.$buefy.modal.open({
        parent: this,
        component: JournalDetailsModal,
        props: {
          journalEntry: entry,
        },
        hasModalCard: true,
        trapFocus: true,
        width: "100%",
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/assets/scss/colors.scss";

.is-success {
  color: $green;
}
.is-success.is-light {
  color: $turquoise;
}
.is-warning {
  color: $yellow;
}
.is-danger {
  color: $red;
}
.is-danger.is-light {
  color: $red;
}
.is-unknown {
  color: $custom-grayer-blue;
}
</style>
