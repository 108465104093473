<template>
  <div>
    <h3 class="title normal-tig-status-title">
      {{ $tf("projectPage.tigStatus.title|Mérföldkő áttekintés") }}
      <b-tooltip
        type="is-info"
        :triggers="['click']"
        :auto-close="['outside', 'escape']"
      >
        <template v-slot:content>
          <div class="content tooltip">
            <p>
              {{
                $tf(
                  "projectPage.tigStatus.info|A mérföldköveinek tényadatainak összefoglalója"
                )
              }}
            </p>
          </div>
        </template>
        <b-icon icon="info-circle" />
      </b-tooltip>
    </h3>
    <div class="workers mx-0 container normal-tig-status">
      <div class="content">
        <b-table class="normal-tig-status-table" :data="sortedTigs">
          <b-table-column
            field="nameIdentifier"
            v-slot="props"
            :label="
              $tf('projectpage.tigDetails.table.nameIdentifier|Név / Azonosító')
            "
            header-class="normal-tig-status-table-header"
          >
            <div class="normal-tig-status-table-identifier">
              {{ props.row.identifier }}
            </div>
            <div class="normal-tig-status-table-name">{{ props.row.name }}</div>
          </b-table-column>
          <b-table-column
            field="startDueDate"
            v-slot="props"
            :label="
              $tf('projectpage.tigDetails.table.startDueDate|Kezdés / Határidő')
            "
            header-class="normal-tig-status-table-header"
          >
            <div class="normal-tig-status-table-upper">
              {{ props.row.startDate }}
            </div>
            <div class="normal-tig-status-table-lower">
              {{ props.row.dueDate }}
            </div>
          </b-table-column>
          <b-table-column
            field="commitmentSpent"
            v-slot="props"
            :label="
              $tf(
                'projectpage.tigDetails.table.commitmentSpent|Vállalás / MWD Költés'
              )
            "
            header-class="normal-tig-status-table-header"
          >
            <template v-if="props.row.type === 'UNTRACKED'">
              <div class="normal-tig-status-table-upper">Nincs</div>
            </template>
            <template v-else>
              <div class="normal-tig-status-table-upper">
                {{ workdayify(props.row.commitment) }}
              </div>
              <div class="normal-tig-status-table-lower">
                {{ workdayify(props.row.sumSpentMd) }}
              </div>
            </template>
          </b-table-column>
          <b-table-column
            field="budgetSpent"
            v-slot="props"
            :label="
              $tf('projectpage.tigDetails.table.budgetSpent|Büdzsé / Költés')
            "
            cell-class="is-fullwidth"
            header-class="normal-tig-status-table-header"
          >
            <template v-if="props.row.type === 'UNTRACKED'">
              <div class="normal-tig-status-table-upper">Nincs</div>
            </template>
            <template v-else>
              <div class="normal-tig-status-table-upper">
                {{ moneyify(props.row.budget) }}
              </div>
              <div class="normal-tig-status-table-lower">
                {{ moneyify(props.row.sumSpentBudget) }}
              </div>
            </template>
          </b-table-column>
          <b-table-column
            field="readynessSpent"
            v-slot="props"
            :label="
              $tf('projectpage.tigDetails.table.readynessSpent|Költöttség')
            "
            cell-class="is-fullwidth"
            header-class="normal-tig-status-table-header"
          >
            <div
              class="normal-tig-status-table-upper"
              v-if="props.row.type !== 'UNTRACKED'"
            >
              <b-icon icon-pack="fa" icon="check" />
              {{ percentify(props.row.sumSpentBudget / props.row.budget) }}
            </div>
            <div
              class="normal-tig-status-table-lower"
              v-if="props.row.type !== 'UNTRACKED'"
            >
              <b-icon icon="hand-holding-dollar" />
              {{ percentify(props.row.sumSpentMd / props.row.commitment) }}
            </div>
          </b-table-column>
          <b-table-column
            field="KPI"
            :label="$tf('projectpage.tigDetails.table.kpi|KPI')"
            v-slot="props"
            header-class="normal-tig-status-table-header"
          >
            <risk-kpi-tag :value="calculateKPI(props.row)" />
          </b-table-column>
          <b-table-column
            field="issueCount"
            v-slot="props"
            :label="$tf('projectpage.tigDetails.table.issueCount|Jegyek száma')"
            header-class="normal-tig-status-table-header"
            >{{ props.row.issueCount }}</b-table-column
          >
          <b-table-column
            field="status"
            v-slot="props"
            :label="$tf('projectpage.tigDetails.table.status|Státusz')"
            header-class="normal-tig-status-table-header"
          >
            <b-tag rounded :type="getTigStatusObject(props.row.status).type">
              {{ $tf(getTigStatusObject(props.row.status).text) }}
            </b-tag>
          </b-table-column>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
import {
  secondToWorkDayFormat,
  secondToShortWorkDayFormat,
  moneyify,
  deepCopy,
  percentify,
  workdayify,
  getTigStatusObject,
} from "@/utils/util";
import RiskKpiTag from "@/components/enterprise/project/module/RiskKpiTag.vue";

export default {
  name: "NormalTigStatus",
  props: {
    projectDetails: {
      type: Object,
      required: true,
    },
  },
  components: {
    RiskKpiTag,
  },
  data() {
    return {
      workerTab: 0,
      moneyify,
      secondToWorkDayFormat,
      secondToShortWorkDayFormat,
      selectedEmployees: [],
      selectedCompetencies: [],
      graphType: 0,
      showSaturday: false,
      showSunday: false,
    };
  },
  created() {},
  computed: {
    sortedTigs() {
      if (this.projectDetails?.tigDetails) {
        const tigs = deepCopy(this.projectDetails.tigDetails);
        tigs.sort(function (a, b) {
          return new Date(a.startDate) - new Date(b.startDate);
        });
        return tigs;
      }
      return [];
    },
  },
  methods: {
    workdayify,
    getTigStatusObject,
    percentify,
    calculateKPI(tig) {
      let timeRatio = 0;
      if (tig.remainingDays < 1) {
        timeRatio = 1;
      } else if (new Date(tig.startDate) < new Date()) {
        timeRatio = 1 - tig.daysLeftPercentage / 100;
      }
      let costBudgetRatio =
        tig.budget === 0 ? 1 : tig.sumSpentBudget / tig.budget;
      return timeRatio === 0 ? costBudgetRatio : costBudgetRatio / timeRatio;
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/scss/colors.scss";

.normal-tig-status {
  box-shadow: none !important;
  padding-top: 6px;

  &-title {
    padding-top: 30px;
  }

  &-table {
    color: $custom-dark-blue !important;

    table {
      border-spacing: 0 1rem !important;

      & tr {
        td {
          border-top: 1px solid #f3f5f9 !important;
          border-bottom: 1px solid #f3f5f9 !important;
          padding-top: 0.65rem;
          padding-bottom: 0.65rem;
        }

        td:first-of-type {
          border-left: 1px solid #f3f5f9 !important;
          border-top-left-radius: 0.625rem;
          border-bottom-left-radius: 0.625rem;
        }

        td:last-of-type {
          border-right: 1px solid #f3f5f9 !important;
          border-top-right-radius: 0.625rem;
          border-bottom-right-radius: 0.625rem;
        }
      }
    }

    th,
    td {
      color: $custom-dark-blue !important;
      border: none !important;
    }

    &-header {
      background: none !important;

      div {
        font-size: 10px !important;
      }
    }

    &-identifier {
      font-weight: 500;
      font-size: 0.875rem;
    }

    &-name {
      font-size: 0.75rem;
    }

    &-upper {
      font-size: 0.875rem;
    }

    &-lower {
      font-size: 0.75rem;
    }
  }
}

.legend-stripe {
  height: 0.5rem;
  width: 1.5rem;
  border-radius: 2rem;
}

.legend-stripe-warning {
  background: #ce9700;
}

.legend-stripe-default {
  background: #167df0;
}
</style>
