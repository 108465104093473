<template>
  <div class="modal-card" style="width: auto">
    <div class="container overflow-y-auto" style="width: 30rem">
      <section class="modal-card-body">
        <h1 class="title" v-if="isCreate">Új szín felvétele</h1>
        <h1 class="title" v-else>Szín módosítása</h1>

        <form @submit.prevent="submit" class="mt-5">
          <b-field class="mb-1" label="Szín kiválasztása">
            <b-colorpicker
              v-model="editData.colorInHex"
              append-to-body
              @update:modelValue="getColorName"
            />
            <b-input v-model="editData.colorName" />
          </b-field>

          <b-field label="Projektek">
            <b-taginput
              v-model="editData.projects"
              :data="filteredProjects"
              autocomplete
              append-to-body
              :allow-new="false"
              :open-on-focus="true"
              icon="list"
              field="name"
              placeholder="Projektek választása"
              @typing="filterProjects"
              label-position="on-border"
            >
            </b-taginput>
          </b-field>

          <b-field class="mt-5 is-pulled-right">
            <b-button @click="close"> Mégse </b-button>
            <b-button native-type="submit" type="is-info" class="ml-2">
              Mentés
            </b-button>
          </b-field>
        </form>
      </section>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { useVuelidate } from "@vuelidate/core";
import { getCurrentInstance } from "vue";

export default {
  name: "ProjectColorForm",
  setup: () => {
    const instance = getCurrentInstance();
    return {
      v$: useVuelidate(),
      instance: instance,
    };
  },
  props: {
    color: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      editData: {},
      isCreate: true,
      projects: [],
      filteredProjects: [],
      colorName: "",
    };
  },
  mounted() {
    this.$store.getters["enterprise_core/projects"].forEach((project) => {
      this.projects.push({
        identifier: project.identifier,
        name: project.name,
      });
    });

    if (this.color) {
      this.isCreate = false;
      this.editData = JSON.parse(JSON.stringify(this.color));

      let selectedProjects = [];
      if (this.editData.projects) {
        this.editData.projects.forEach((colorProject) => {
          selectedProjects.push(
            this.projects.find(
              (project) => project.identifier === colorProject.identifier
            )
          );
        });
      }
      this.filteredProjects = this.projects;
      this.editData.projects = selectedProjects;
    } else {
      this.isCreate = true;
    }
  },
  methods: {
    close() {
      this.instance.emit("close");
    },
    afterSave() {
      this.$emit("after-save");
      this.close();
    },
    filterProjects(text) {
      this.filteredProjects = this.projects.filter((projects) => {
        return (
          projects.name.toString().toLowerCase().indexOf(text.toLowerCase()) >=
          0
        );
      });
    },
    submit() {
      this.editData.colorInHex = this.editData.colorInHex
        ? this.editData.colorInHex.toString("hex")
        : null;
      if (this.isCreate) {
        this.$store
          .dispatch("project_dimensions/createColor", this.editData)
          .then(() => {
            this.afterSave();
          });
      } else {
        this.$store
          .dispatch("project_dimensions/updateColor", this.editData)
          .then(() => {
            this.afterSave();
          });
      }
    },
    async getColorName() {
      if (!this.editData?.colorInHex) return;
      try {
        const response = await axios.get(
          `https://www.thecolorapi.com/id?hex=${this.editData.colorInHex
            .toString()
            .slice(1)}&format=json`
        );
        this.editData.colorName = response.data.name.value;
      } catch (error) {
        console.error("Error fetching color name:", error);
      }
    },
  },
};
</script>
