<template>
  <section class="section">
    <div class="container">
      <h1 class="title">{{ $tf("absenceTypeList.title|Szabadságtípusok") }}</h1>
      <h2 class="subtitle">
        {{
          $tf(
            "absenceTypeList.subtitle|Szabadságtípusok létrehozása, szerkesztése"
          )
        }}
      </h2>

      <div class="button-container">
        <b-button
          type="is-info"
          class="is-pulled-right"
          @click="openEditPopup(null)"
          ><b-icon icon="plus-circle" class="mr-2" />
          {{ $tf("absenceTypeList.add|Új szabadságtípus") }}
        </b-button>
      </div>
    </div>

    <div class="container">
      <b-table
        :data="absenceTypes.filter((type) => true)"
        ref="table"
        hoverable
        paginated
        per-page="15"
        :show-detail-icon="true"
      >
        <b-table-column
          field="color"
          :label="$tf('absenceTypeList.table.color|Szín')"
          v-slot="props"
        >
          <div class="color">
            <div class="color-box" :style="style(props.row.color)"></div>
            {{ props.row.color }}
          </div>
        </b-table-column>

        <b-table-column
          field="name"
          :label="$tf('absenceTypeList.table.name|Megnevezés')"
          v-slot="props"
          sortable
          searchable
        >
          {{ props.row.name }}
        </b-table-column>

        <b-table-column
          field="identifier"
          :label="$tf('absenceTypeList.table.id|Azonosító')"
          v-slot="props"
        >
          {{ props.row.identifier }}
        </b-table-column>

        <b-table-column
          field="sign"
          :label="$tf('absenceTypeList.table.sign|Jel')"
          v-slot="props"
        >
          {{ props.row.sign }}
        </b-table-column>

        <b-table-column
          field="usingQuota"
          :label="$tf('absenceTypeList.table.usingQuota|Kvótahasználat')"
          v-slot="props"
        >
          {{
            props.row.usingQuota
              ? $tf("absenceTypeList.table.usingQuota.yes|Igen")
              : $tf("absenceTypeList.table.usingQuota.no|Nem")
          }}
        </b-table-column>

        <b-table-column
          field="selectable"
          :label="$tf('absenceTypeList.table.selectable|Kivehető')"
          v-slot="props"
        >
          {{
            props.row.selectable
              ? $tf("absenceTypeList.table.selectable.yes|Igen")
              : $tf("absenceTypeList.table.selectable.no|Nem")
          }}
        </b-table-column>

        <b-table-column
          field="actions"
          :label="$tf('absenceTypeList.table.actions|Műveletek')"
          centered
          v-slot="props"
          cell-class="do-not-wrap"
          width="80"
        >
          <clickable-icon
            icon="pencil"
            type="is-info"
            class="mr-2"
            @click="openEditPopup(props.row)"
          ></clickable-icon>
          <clickable-icon
            icon="trash"
            type="is-danger"
            @click="deleteRow(props.row)"
          ></clickable-icon>
        </b-table-column>
      </b-table>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import ClickableIcon from "@/components/module/icon/ClickableIcon";
import { openDeleteConfirm } from "@/utils/util";
import AbsenceTypeForm from "../../../components/absence/type/AbsenceTypeForm";
import LoadingMixin from "@/mixins/LoadingMixin";

export default {
  name: "AbsenceTypeList",
  components: { ClickableIcon },
  mixins: [LoadingMixin],
  async mounted() {
    await this.doStartLoading();
    await this.fetchList();
    await this.doFinishLoading();
  },
  data() {
    return {
      color: "#000000",
    };
  },
  computed: {
    ...mapGetters({
      absenceTypes: "absence_request/types",
    }),
  },
  methods: {
    openEditPopup(row) {
      this.$buefy.modal.open({
        parent: this,
        component: AbsenceTypeForm,
        props: { absenceType: row },
        hasModalCard: true,
        trapFocus: true,
      });
    },
    deleteRow(row) {
      openDeleteConfirm(this.$buefy, async () => {
        await this.$store.dispatch("absence_request/deleteType", row.id);
        await this.fetchList();
      });
    },
    async fetchList() {
      await this.$store.dispatch("absence_request/getAdminTypes");
    },
    style(color) {
      return (
        "width: 10px; height: 10px;  display: inline-block; background-color:" +
        color
      );
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/assets/scss/colors.scss";

.button-container {
  display: flex;

  button {
    margin-left: auto;
  }
}

.tab-content {
  padding: 0 !important;
  padding-top: 2rem !important;
}

.color {
  position: relative;
}

.color-box {
  width: 10px;
  height: 10px;
  display: inline-block;
  background-color: $black;
}
</style>
