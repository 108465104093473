<template>
  <b-modal
    v-model="modalOpen"
    :aria-label="label"
    :close-button-aria-label="$tf('moneyModal.close|Bezárás')"
    aria-modal
    aria-role="dialog"
    has-modal-card
    @close="close"
  >
    <div class="card" style="width: 640px">
      <div class="card-content">
        <h3 v-if="editData" class="title">
          {{
            $tf("moneyModal.editTitle|{type} szerkesztése", { type: dataType })
          }}
        </h3>
        <h3 v-else class="title">
          {{
            $tf("moneyModal.newTitle|{type} létrehozása", { type: dataType })
          }}
        </h3>
        <form>
          <div class="mt-4 is-flex is-flex-direction-column">
            <template v-if="type === 'overrides'">
              <b-field
                :label="$tf('moneyModal.employee|Dolgozó')"
                label-position="on-border"
              >
                <b-autocomplete
                  v-model="name"
                  :data="filteredEmployees"
                  :placeholder="
                    $tf('moneyModal.employee.placeholder|pl. Elektrom Ágnes')
                  "
                  clearable
                  field="name"
                  icon="search"
                  rounded
                  @select="(option) => (modifiedData.userId = option.id)"
                >
                  <template #empty
                    >{{ $tf("moneyModal.employee.empty|Nincs találat") }}
                  </template>
                </b-autocomplete>
              </b-field>
              <money-input
                :label="$tf('moneyModal.cost|Pontos óradíj')"
                :modelValue="modifiedData.cost ?? ''"
                label-position="on-border"
                @update:modelValue="(val) => (modifiedData.cost = val)"
              />
            </template>
            <template v-else>
              <b-field
                v-for="column in MONEY_COLUMNS[type].filter(
                  (col) => !col.notOnForm
                )"
                :key="column.field"
                :label="$tf(column.label)"
                label-position="on-border"
              >
                <template v-if="column.field === 'date'">
                  <f-datepicker
                    v-model="modifiedData.date"
                    :placeholder="$tf('moneyModal.choose|Válassz')"
                    icon="calendar-day"
                    position="is-top-right"
                  >
                  </f-datepicker>
                </template>
                <template v-else-if="column.field === 'tigIdentifiers'">
                  <b-select
                    v-model="modifiedData[column.field]"
                    :native-size="tigs.length > 4 ? 4 : tigs.length"
                    expanded
                    multiple
                  >
                    <option
                      v-for="tig in tigs"
                      :key="tig.id"
                      :value="tig.identifier"
                    >
                      {{ `${tig.identifier} - ${tig.name}` }}
                    </option>
                  </b-select>
                </template>
                <template
                  v-else-if="
                    Object.hasOwn(modifiedData, column.field) || !editData
                  "
                >
                  <money-input
                    v-if="column.mask"
                    :modelValue="
                      modifiedData[column.field]
                        ? modifiedData[column.field]
                        : ''
                    "
                    label-position="on-border"
                    @update:modelValue="
                      (val) => (modifiedData[column.field] = val)
                    "
                  />
                  <b-input
                    v-else
                    v-model="modifiedData[column.field]"
                    :type="column.field === 'summary' ? 'textarea' : null"
                  />
                </template>
              </b-field>
            </template>
          </div>
          <div class="is-flex is-justify-content-flex-end mt-4">
            <b-button
              native-type="button"
              type="is-info is-light"
              @click="close()"
            >
              {{ $tf("moneyModal.cancel|Mégse") }}
            </b-button>
            <b-button
              native-type="button"
              type="is-info"
              @click="submit(modifiedData)"
            >
              {{ $tf("moneyModal.save|Mentés") }}
            </b-button>
          </div>
        </form>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { deepCopy } from "@/utils/util";
import MoneyInput from "@/components/module/input/MoneyInput";
import { MONEY_COLUMNS, PROJECT_TYPES } from "@/utils/const";
import FDatepicker from "@/components/module/input/FDatepicker.vue";

export default {
  name: "MoneyModal",
  components: {
    FDatepicker,
    MoneyInput,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    show: {
      type: Boolean,
      required: true,
    },
    editData: {
      type: Object,
      required: false,
    },
    type: {
      type: String,
      required: true,
    },
    employees: {
      type: Array,
      required: false,
    },
    projectType: {
      type: String,
      required: false,
      default: PROJECT_TYPES.BASIC,
    },
    tigs: {
      type: Array,
      required: false,
      default: () => [],
    },
    selectedTigs: {
      type: Array,
      required: false,
      default: () => [],
    },
    modify: {
      type: Function,
      required: false,
    },
    create: {
      type: Function,
      required: false,
    },
    dataType: {
      type: String,
      required: true,
    },
  },
  mounted() {
    if (this.type === "invoices") {
      this.modifiedData.tigIdentifiers = this.selectedTigs;
    }
  },
  watch: {
    editData() {
      this.modifiedData = deepCopy(this.editData);
      if (this.modifiedData.date) {
        this.modifiedData.date = new Date(this.modifiedData.date);
      }

      if (this.type === "invoices") {
        this.modifiedData.tigIdentifiers =
          this.modifiedData.tigIdentifiers ?? [];
      }
    },
    show(newVal) {
      this.modalOpen = newVal;
    },
  },
  data() {
    return {
      MONEY_COLUMNS,
      PROJECT_TYPES,
      modifiedData: {},
      name: "",
      modalOpen: false,
    };
  },
  computed: {
    filteredEmployees() {
      let employees = this.employees || [];
      return employees.filter((element) => {
        if (!this.name || !element.name || !element.username) return false;
        return (
          element.name
            .toString()
            .toLowerCase()
            .indexOf(
              this.name
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .toLowerCase()
            ) >= 0 ||
          element.username
            .toString()
            .toLowerCase()
            .indexOf(
              this.name
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .toLowerCase()
            ) >= 0
        );
      });
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
    submit(data) {
      for (let i in MONEY_COLUMNS[this.type]) {
        if (
          !this.modifiedData[MONEY_COLUMNS[this.type][i].field] &&
          !MONEY_COLUMNS[this.type][i].notOnForm
        ) {
          this.$buefy.toast.open({
            message: this.$tf(
              "moneyModal.missingFields|Minden mező kitöltése kötelező!"
            ),
            type: "is-danger",
          });
          return;
        }
      }
      if (
        this.type === "invoices" &&
        this.modifiedData.tigIdentifiers.length < 1
      ) {
        this.$buefy.toast.open({
          message: this.$tf(
            "moneyModal.missingFields|Legalább egy mérföldkő kiválasztása kötelező!"
          ),
          type: "is-danger",
        });
        return;
      }
      if (this.modify) this.modify(data);
      else if (this.create) this.create(data);
      this.close();
    },
  },
};
</script>

<style lang="scss" scoped>
.is-flex {
  gap: 15px;
}
</style>
