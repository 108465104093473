<template>
  <div class="modal-card" style="width: auto">
    <div class="container" style="width: 42rem">
      <section class="modal-card-body">
        <h1 v-if="createMode" class="title">
          {{
            $tf("tiers.assign.modal.newTitle|Új szint összerendelés felvétele")
          }}
        </h1>
        <h1 v-else class="title">
          {{
            $tf("tiers.assign.modal.editTitle|Szint összerendelés módosítása")
          }}
        </h1>

        <form v-if="loaded">
          <b-field
            :label="$tf('tiers.assign.modal.tier|Szint')"
            class="mt-5"
            label-position="on-border"
          >
            <b-select
              v-model="editUserTier.tier.id"
              :placeholder="$tf('tiers.assign.modal.tier|Szint')"
              expanded
            >
              <option v-for="tier in tiers" :key="tier.id" :value="tier.id">
                {{ tier.tier }}
              </option>
            </b-select>
          </b-field>

          <vuelidated-form :validations="v$.editUserTier.userId" class="mt-5">
            <template v-slot="{ errors, invalid }">
              <b-field
                :message="v$.editUserTier.userId.$error ? errors[0] : null"
                :type="{ 'is-danger': invalid }"
                label-position="on-border"
              >
                <template #label>
                  {{ $tf("tiers.assign.modal.user|Dolgozó") }}
                  <span class="has-text-danger">*</span>
                </template>

                <b-autocomplete
                  v-model="editUserTier.userDisplayName"
                  :data="selectableNewUsers"
                  :placeholder="
                    $tf(
                      'tiers.assign.modal.user.placeholder|pl. Elektrom Ágnes'
                    )
                  "
                  clearable
                  field="name"
                  icon="search"
                  rounded
                  @blur="v$.editUserTier.userId.$touch()"
                  @select="
                    (option) =>
                      (editUserTier.userId = option ? option.id : null)
                  "
                  @update:modelValue="
                    selectableNewUsers = filterEmployees(
                      editUserTier.userDisplayName
                    )
                  "
                >
                  <template #empty
                    >{{ $tf("tiers.assign.modal.user.empty|Nincs találat") }}
                  </template>
                </b-autocomplete>
              </b-field>
            </template>
          </vuelidated-form>

          <b-field
            :label="$tf('tiers.assign.modal.validFrom|Érvényesség kezdete')"
            class="mt-5"
            label-position="on-border"
          >
            <f-datepicker
              v-model="editUserTier.date"
              :placeholder="
                $tf('tiers.assign.modal.validFrom.placeholder|Kezdeti dátum')
              "
              append-to-body
              icon="calendar-alt"
            >
            </f-datepicker>
          </b-field>

          <b-field class="mt-5 is-pulled-right">
            <b-button @click="close">
              {{ $tf("tiers.assign.modal.cancel|Mégse") }}
            </b-button>
            <b-button class="ml-3" type="is-info" @click="save">
              {{ $tf("tiers.assign.modal.save|Mentés") }}
            </b-button>
          </b-field>
        </form>

        <LoadingSkeleton v-else />
      </section>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { deepCopy, employeeFilter, formatDate } from "@/utils/util";
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import VuelidatedForm from "@/components/module/input/VuelidatedForm";
import LoadingSkeleton from "../../../../components/module/loading/LoadingSkeleton";
import { getCurrentInstance } from "vue";
import FDatepicker from "@/components/module/input/FDatepicker.vue";

export default {
  name: "UserTiersEditModal",
  setup: () => {
    const instance = getCurrentInstance();
    return {
      v$: useVuelidate(),
      instance: instance,
    };
  },
  props: {
    userTier: {
      type: Object,
      required: false,
    },
  },
  async mounted() {
    this.createMode = !this.userTier;

    //await this.$store.dispatch("employee/fetch");
    await this.$store.dispatch("enterprise_tiers/fetchTiers");

    if (this.userTier) {
      this.editUserTier = deepCopy(this.userTier);
      this.editUserTier.date = new Date(this.userTier.since);
    } else {
      if (this.tiers && this.tiers.length > 0) {
        this.editUserTier.tier = deepCopy(this.tiers[0]);
      }
    }

    this.loaded = true;
  },
  data() {
    return {
      loaded: false,
      createMode: false,
      editUserTier: {
        userId: undefined,
        tier: {},
        userDisplayName: undefined,
        date: new Date(),
      },
      selectableNewUsers: [],
      selectableEditUsers: [],
    };
  },
  components: { FDatepicker, LoadingSkeleton, VuelidatedForm },
  validations: {
    editUserTier: {
      userId: {
        required,
      },
    },
  },
  computed: {
    ...mapGetters({
      tiers: "enterprise_tiers/tiers",
      employees: "employee/employees",
    }),
  },
  methods: {
    close() {
      this.instance.emit("close");
    },
    filterEmployees(name) {
      return employeeFilter(name, this.employees || []);
    },
    async save() {
      this.v$.editUserTier.$touch();
      if (!this.v$.editUserTier.$invalid) {
        let request = deepCopy(this.editUserTier);
        request.since = formatDate(this.editUserTier.date);

        if (this.createMode) {
          await this.$store.dispatch(
            "enterprise_tiers/createUserTier",
            request
          );
        } else {
          await this.$store.dispatch(
            "enterprise_tiers/modifyUserTier",
            request
          );
        }
        this.$emit("save");
        this.close();
      }
    },
  },
};
</script>
