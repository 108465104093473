<template>
  <div>
    <loading-skeleton v-if="loading" />
    <div v-else>
      <b-modal
        v-model="isModalOpen"
        aria-modal
        aria-role="dialog"
        has-modal-card
        style="height: 100%"
        v-on:cancel="editedActivity = null"
      >
        <div class="card p-4">
          <support-activity-form
            :activity-type="activityType"
            :edited-activity="editedActivity"
            :prefix="prefix"
            :project-identifier="projectDetails.identifier"
            v-on:activity-submitted="handleCreate"
          />
        </div>
      </b-modal>
      <div class="is-flex">
        <div>
          <div v-if="filteredActivities.length > 0" class="is-flex has-gap-2">
            <b-dropdown v-model="selectedActivity" aria-role="list">
              <template #trigger="{ active }">
                <b-button
                  :icon-right="active ? 'chevron-up' : 'chevron-down'"
                  :label="`${selectedActivity.name} (${selectedActivity.identifier})`"
                  rounded
                  size="is-small"
                  type="is-info"
                />
              </template>

              <b-dropdown-item
                v-for="activity in filteredActivities"
                :key="activity.id"
                :value="activity"
                aria-role="listitem"
                >{{ activity.name + " (" + activity.identifier + ")" }}
              </b-dropdown-item>
            </b-dropdown>
            <div class="is-flex is-align-items-center">
              <b-tooltip
                :label="
                  $tf('projectpage.override.table.actions.edit|Szerkesztés')
                "
                type="is-light"
              >
                <clickable-icon
                  icon="edit"
                  type="is-info"
                  @click="handleEdit"
                />
              </b-tooltip>
              <b-tooltip
                :label="$tf('projectpage.tigList.table.actions.delete|Törlés')"
                type="is-light"
              >
                <clickable-icon
                  icon="trash"
                  type="is-danger"
                  @click="handleDelete"
                />
              </b-tooltip>
            </div>
            <div
              v-if="issueTrackingSystem !== ISSUE_TRACKING_SYSTEM.SELF.enum"
              class="is-flex is-align-items-center"
            >
              <external-issue-targeting-link
                :modelValue="selectedActivity.externalIssueTargeting"
              />
            </div>
          </div>
          <span v-else>
            {{
              $tf(
                "projectPage.activities.empty|Ehhez a projekthez még nincs létrehozva ilyen aktivitás."
              )
            }}
          </span>
        </div>
        <div class="ml-auto">
          <b-button
            rounded
            size="is-small"
            type="is-info"
            @click="isModalOpen = true"
          >
            {{ $tf("projectpage.activities.add|Új aktivitás hozzáadása") }}
          </b-button>
        </div>
      </div>
      <div v-if="selectedActivity">
        <support-fee-activity-details
          :activity="selectedActivity"
          :activity-type="activityType"
          :disable-fee="projectDetails.internal"
          :project-identifier="projectDetails.identifier"
          @refresh="() => refreshData(true, false)"
        />
        <MoneyTable
          :data="
            projectDetails.invoices.filter((invoice) =>
              invoice.tigIdentifiers.includes(selectedActivity.identifier)
            )
          "
          :on-approve="canEvaluateInvoice ? payInvoice : null"
          :on-cancel="canEvaluateInvoice ? cancelInvoice : null"
          :on-create="createInvoice"
          :on-modify="modifyInvoice"
          :tigs="[selectedActivity]"
          type="invoices"
        />
      </div>
    </div>
  </div>
</template>

<script>
import SupportActivityForm from "@/components/enterprise/project/form/SupportActivityForm.vue";
import LoadingSkeleton from "@/components/module/loading/LoadingSkeleton.vue";
import SupportFeeActivityDetails from "@/components/enterprise/project/activity/SupportFeeActivityDetails.vue";
import ExternalIssueTargetingLink from "@/components/enterprise/targeting/ExternalIssueTargetingLink.vue";
import ClickableIcon from "@/components/module/icon/ClickableIcon.vue";
import { formatDate, numberify, openDeleteConfirm } from "@/utils/util";
import {
  INVOICE_STATUS,
  ISSUE_TRACKING_SYSTEM,
  PRIVILEGES,
} from "@/utils/const";
import { mapGetters } from "vuex";
import MoneyTable from "@/components/enterprise/ProjectDetails/TabItems/MoneyTable.vue";

export default {
  name: "SupportActivityTabItem",
  components: {
    MoneyTable,
    ClickableIcon,
    ExternalIssueTargetingLink,
    SupportFeeActivityDetails,
    LoadingSkeleton,
    SupportActivityForm,
  },
  data() {
    return {
      ISSUE_TRACKING_SYSTEM,
      selectedActivity: this.projectDetails[this.activityField][0],
      editedActivity: null,
      isModalOpen: false,
      loading: false,
      refreshKey: 0,
    };
  },
  props: {
    activityType: {
      type: String,
      required: true,
    },
    activityField: {
      type: String,
      required: true,
    },
    projectDetails: {
      type: Object,
      required: true,
    },
    prefix: {
      type: String,
      required: true,
    },
    employees: {
      type: Array,
      required: true,
    },
  },
  computed: {
    canEvaluateInvoice() {
      return this.$store.getters["session/hasAnyAuthority"](
        PRIVILEGES.ENTERPRISE.PROJECT_EXTRAS.EVALUATE_INVOICE,
        PRIVILEGES.ENTERPRISE.PROJECT_EXTRAS.ADMIN
      );
    },
    filteredActivities() {
      return this.projectDetails[this.activityField];
    },
    ...mapGetters({
      issueTrackingSystem: "uiConfigStore/getIssueTrackingSystem",
    }),
  },
  methods: {
    formatDate,
    async handleCreate() {
      this.isModalOpen = false;
      await this.refreshData(true, false);
    },
    handleEdit() {
      this.editedActivity = this.selectedActivity;
      this.isModalOpen = true;
    },
    async handleDelete() {
      openDeleteConfirm(this.$buefy, async () => {
        await this.$store.dispatch("support_projects/deleteActivity", {
          projectId: this.projectDetails.id,
          activityType: this.activityType,
          id: this.selectedActivity.id,
        });
        await this.refreshData(true, true);
      });
    },
    async refreshData(force, refreshSelectedActivity) {
      this.loading = true;
      let params = new URLSearchParams();
      params.append("year", new Date().getFullYear());
      params.append("includeFixes", "true");
      params.append("includeLockups", "true");
      params.append("includeBillables", "true");
      await this.$store.dispatch("support_projects/fetchProjectDetails", {
        projectIdentifier: this.projectDetails.identifier,
        requestParams: { params },
        force,
      });
      const field = this.projectDetails[this.activityField];
      if (refreshSelectedActivity || !this.selectedActivity) {
        this.selectedActivity = field ? field[0] : undefined;
      } else {
        this.selectedActivity = field?.find(
          (a) => a.id === this.selectedActivity.id
        );
      }
      this.refreshKey++;
      this.loading = false;
    },
    async createInvoice(invoice) {
      await this.$store.dispatch("enterprise_projects/createOrEditInvoice", {
        projectId: this.projectData.id,
        number: invoice.number,
        status: INVOICE_STATUS.DUE.enum,
        tigIdentifiers: invoice.tigIdentifiers,
        summary: invoice.summary,
        cost: numberify(invoice.cost),
        date: formatDate(invoice.date),
      });
      await this.fetchData(true);
    },
    async modifyInvoice(invoice) {
      invoice.projectId = this.projectData.id;
      await this.$store.dispatch("enterprise_projects/createOrEditInvoice", {
        id: invoice.id,
        projectId: this.projectData.id,
        number: invoice.number,
        status: invoice.status,
        tigIdentifiers: invoice.tigIdentifiers,
        summary: invoice.summary,
        cost: numberify(invoice.cost),
        date: formatDate(invoice.date),
      });
      await this.fetchData(true);
    },
    async payInvoice(id) {
      this.$buefy.dialog.prompt({
        title: this.$tf(
          "projectpage.invoice.markPaidModal.title|Számla fizetettnek jelölése"
        ),
        message: this.$tf(
          "projectpage.invoice.markPaidModal.message|Kifizetés dátuma"
        ),
        confirmText: this.$tf(
          "projectpage.invoice.markPaidModal.confirm|Jelölés"
        ),
        cancelText: this.$tf("projectpage.invoice.markPaidModal.cancel|Mégsem"),
        inputAttrs: {
          type: "date",
          value: formatDate(new Date()),
        },
        trapFocus: true,
        onConfirm: async (value) => {
          await this.$store.dispatch("enterprise_projects/evaluateInvoice", {
            id: id,
            request: {
              status: INVOICE_STATUS.PAID.enum,
              date: formatDate(value),
            },
          });
          await this.fetchData(true);
        },
      });
    },
    async cancelInvoice(id) {
      this.$buefy.dialog.confirm({
        title: this.$tf(
          "projectpage.invoice.cancelModal.title|Számla visszavonása"
        ),
        message: this.$tf(
          "projectpage.invoice.cancelModal.message|Biztosan vissza szeretnéd vonni a számlát?"
        ),
        confirmText: this.$tf(
          "projectpage.invoice.cancelModal.confirm|Visszavonás"
        ),
        cancelText: this.$tf("projectpage.invoice.cancelModal.cancel|Mégse"),
        trapFocus: true,
        type: "is-danger",
        onConfirm: async () => {
          await this.$store.dispatch("enterprise_projects/evaluateInvoice", {
            id: id,
            request: {
              status: INVOICE_STATUS.CANCELLED.enum,
              date: formatDate(new Date()),
            },
          });
          await this.fetchData(true);
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
