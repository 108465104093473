import network from "../utils/network";
import app from "@/main.js";

export default class StoreCore {
  constructor() {
    this.requestBuffer = {}; // buffer for ongoing promises
    this.requestCache = {}; // cache for responses
    this.cacheTiming = {};
    this.cachePruner = setInterval(() => {
      let now = new Date();
      for (let opId in this.cacheTiming) {
        if (now - this.cacheTiming[opId] > 5 * 60 * 1000) {
          delete this.cacheTiming[opId];
          delete this.requestCache[opId];
        }
      }
    }, 30 * 1000);
  }

  clearCoreCache() {
    this.requestCache = {};
    this.requestBuffer = {};
  }

  async remoteRequestObject({
    method,
    url,
    data,
    cacheable,
    dispatch,
    flair,
    timeout,
    force,
    toast,
    event,
    messages,
    announceError,
  }) {
    return await this.remoteRequest(
      method,
      url,
      data,
      cacheable,
      dispatch,
      flair,
      timeout,
      force,
      toast,
      event,
      messages,
      announceError
    );
  }

  async remoteRequest(
    reqType,
    url,
    params,
    cacheable,
    dispatch,
    flair,
    timeout = undefined,
    force = undefined,
    toast = false,
    event = false,
    messages = {
      progress: "Művelet folyamatban",
      fail: "Sikertelen művelet",
      success: "Sikeres művelet",
    },
    announceError = true
  ) {
    // normalizing
    reqType = reqType.toLowerCase();
    let operationId = url;
    if (params) {
      let paramsString;
      if (
        params.params &&
        URLSearchParams.prototype.isPrototypeOf(params.params)
      ) {
        paramsString = params.params.toString();
      } else {
        paramsString = JSON.stringify(params);
      }
      operationId = operationId + "_" + paramsString;
    }

    if (reqType === "get" && this.requestBuffer[operationId]) {
      // the request is already executing, ignore, return earlier promise
      return this.requestBuffer[operationId];
    }
    cacheable = cacheable && reqType === "get"; // only cache GET
    if (cacheable && !force && !!this.requestCache[operationId]) {
      return this.requestCache[operationId];
    }

    let promise = async () => {
      let reqId = this.generateRequestId();
      this.startLoading(reqId, reqType, url, flair, dispatch, event, messages);
      let axios = await network.connection();
      if (timeout) {
        axios.defaults.timeout = timeout;
      }
      try {
        let response = await axios[reqType](url, params);
        this.endLoading(reqId, dispatch, true, event);
        this.requestCache[operationId] = response.data;
        this.cacheTiming[operationId] = new Date();
        this.requestBuffer[operationId] = null;

        return this.requestCache[operationId];
      } catch (error) {
        console.error(error);
        if (announceError) this.announceError(error.response);
        this.endLoading(reqId, dispatch, false, event);
        this.requestBuffer[operationId] = null;

        throw error;
      }
    };
    this.requestBuffer[operationId] = promise.call(this);
    return this.requestBuffer[operationId];
  }

  generateRequestId() {
    return Math.floor(Math.random() * 100000);
  }

  startLoading(reqId, reqType, url, flair, dispatch, event = false, messages) {
    dispatch(
      "loading/startLoading",
      {
        id: reqId,
        type: reqType,
        url: url,
        flair: flair,
        event: event,
        messages: messages,
      },
      { root: true }
    );
  }

  endLoading(reqId, dispatch, result, event = false) {
    dispatch(
      "loading/finishLoading",
      { id: reqId, result: result, event: event },
      { root: true }
    );
  }

  announceError(error) {
    if (error?.data) {
      let message;
      if ("GENERIC" === error.data.type) {
        message = `<strong>${error.data.statusCode}</strong> - ${error.data.errorMessage}`;
      } else if ("DUPLICATE_ENTRY" === error.data.type) {
        message = `<strong>${error.status}</strong> - A(z) ${error.data.constraintName} mező értéke már foglalt!`;
      } else if (error.status === 503) {
        window.location.reload();
        return;
      } else if (error.data.error) {
        message = `<strong>${error.status}</strong> - ${error.data.error}`;
      } else {
        message = `<strong>${error.status}</strong> - ${error.data}`;
      }
      app.config.globalProperties.$buefy.toast.open({
        duration: 5000,
        message:
          String(message).length < 255 ? message : "Something went wrong",
        position: "is-bottom",
        type: "is-danger",
      });
    }
  }
}
