<template>
  <div>
    <template v-if="isClosingDay">
      <div>
        <h4 class="subtitle">
          {{ $tf("logging.counter.endDay.subtitle|Nap összesítő") }}
        </h4>
        <div style="display: grid; grid-template-columns: 1fr 1fr 2fr">
          <div>
            <h4 class="subtitle">
              {{ $tf("logging.counter.endDay.projectTask|Projekt jegy") }}
            </h4>
          </div>
          <div class="has-text-centered">
            <h4 class="subtitle">
              {{ $tf("logging.counter.endDay.duration|Időtartam") }}
            </h4>
          </div>
          <div>
            <h4 class="subtitle">
              {{ $tf("logging.counter.endDay.comment|Megjegyzés") }}
            </h4>
          </div>
        </div>
        <div
          style="display: grid; grid-template-columns: 1fr 1fr 2fr"
          v-for="issue in issuesForm"
          :key="issue.issueKey"
        >
          <div class="has-font-weight-700 end-day-cell">
            {{ issue.issueKey }}
          </div>
          <div class="end-day-cell is-inline-flex">
            <b-button
              icon-left="minus"
              @click="subtractFromIssue(issue.issueKey)"
            ></b-button>
            <b-input
              class="mx-2"
              custom-class="has-text-centered"
              disabled="true"
              :value="secondsToJiraTime(issue.timeSpentSeconds)"
            ></b-input>
            <b-button
              icon-left="plus"
              @click="addToIssue(issue.issueKey)"
            ></b-button>
          </div>
          <div class="end-day-cell">
            <b-input v-model="issue.comment" expanded></b-input>
          </div>
        </div>
        <div style="display: grid; grid-template-columns: 1fr 1fr 2fr">
          <div class="has-font-weight-700 p-2">
            {{ $tf("logging.counter.endDay.sum|Összesen") }}
          </div>
          <div class="p-2 has-text-centered">
            {{
              secondsToJiraTime(
                Object.values(issuesForm).reduce(
                  (a, b) => a + b.timeSpentSeconds,
                  0
                )
              )
            }}
          </div>
        </div>

        <div class="mt-4">
          <p class="has-font-weight-700">
            {{
              $tf(
                "logging.counter.endDay.p1|A nap addig nem zárható le, amíg az összes rögzített sor időtartama nem kerekíthető negyed órás intervallumra!"
              )
            }}
          </p>
          <p>
            {{
              $tf(
                "logging.counter.endDay.p2|Kérlek használd a plusz/minusz gombokat a legközelebbi negyed órára kerekítéshez, utána pedig 15 perc hozzáadásához/levonásához"
              )
            }}
          </p>
        </div>
        <div class="is-inline-flex mt-4">
          <b-button
            icon-left="lock"
            type="is-primary"
            :disabled="!areIssuesRounded"
            :loading="isPostingWorklog"
            class="mr-2"
            @click="closeDay"
            >{{ $tf("logging.counter.closeDay|Nap zárása") }}</b-button
          >
          <b-button @click="isClosingDay = false">{{
            $tf("logging.counter.cancel|Mégsem")
          }}</b-button>
        </div>
      </div>
    </template>
    <loading-skeleton v-else-if="loading" />
    <template v-else>
      <div>
        <div class="is-inline-flex mb-2" style="width: 100%">
          <b-field
            :label="$tf('logging.counter.daySelect|Nap')"
            label-position="on-border"
            class="mb-0"
          >
            <b-select
              v-model="selectedDate"
              :disabled="daysWithCounters.length === 1"
            >
              <option v-for="day in daysWithCounters" :key="day" :value="day">
                {{ day }}
              </option>
            </b-select>
          </b-field>
          <b-field
            :label="$tf('logging.counter.modal.task|Feladat')"
            label-position="on-border"
            class="ml-2 mb-0"
          >
            <b-autocomplete
              v-model="taskSearch"
              icon="search"
              :data="filteredIssues"
              :custom-formatter="issueFormatter"
              clearable
              rounded
              class="has-blue-icon"
              ref="autocomplete"
              :loading="isFetchingTasks"
              :placeholder="
                $tf('logging.counter.issueSearch.placeholder|Keresés...')
              "
              @select="(option) => (selectedIssue = option)"
              :disabled="
                selectedDate !== formatDate(new Date()) || isTodayClosed
              "
            >
              <template v-slot:empty>
                <template v-if="taskSearch?.length > 2 && !isFetchingTasks">
                  {{ $tf("logging.counter.issueSearch.empty|Nincs találat") }}
                </template>
                <template v-else-if="isFetchingTasks">
                  {{ $tf("logging.counter.issueSearch.loading|Betöltés...") }}
                </template>
                <template v-else>
                  {{
                    $tf(
                      "logging.counter.issueSearch.notEnoughCharacters|Gépelj legalább 3 karaktert a kereséshez"
                    )
                  }}
                </template>
              </template>
            </b-autocomplete>
          </b-field>
          <b-button
            class="ml-2"
            type="is-primary"
            @click="startCounter"
            :disabled="
              selectedDate !== formatDate(new Date()) || !selectedIssue
            "
            >{{
              $tf("logging.counter.selectIssue|Jegy kiválasztása")
            }}</b-button
          >
          <template v-if="this.countedIssue">
            <div class="is-align-self-center ml-auto">
              <span>{{
                $tf("logging.counter.countedIssue|Jelenleg számlált jegy: ")
              }}</span>
              <span class="has-font-weight-700">
                {{ ` ${this.countedIssue.key} (${countedIssueDuration})` }}
              </span>
            </div>
            <b-button
              class="ml-2"
              type="is-primary"
              icon-left="pause"
              @click="stopCounter"
            ></b-button>
          </template>
        </div>
        <vue-cal
          hide-view-selector
          hide-title-bar
          active-view="day"
          ref="calendar"
          :time-from="7 * 60"
          :time-to="19 * 60"
          :selected-date="selectedDate"
          :locale="VUE_CAL_LOCALE[storedLocale]"
          :split-days="splitDays"
          :editable-events="{
            title: false,
            drag: false,
            resize: false,
            delete: false,
            create: false,
          }"
          :events="events ?? []"
        >
          <template #no-event>
            <span></span>
          </template>
          <template #event="{ event }">
            <span class="has-font-weight-700">{{ event.issue.key }}</span> -
            <span>{{ event.issue.name }}</span>
            <span class="ml-4 has-font-weight-700">{{
              `(${formatTime(event.start)} - ${formatTime(event.end)})`
            }}</span>
          </template>
        </vue-cal>
      </div>
      <div class="mt-4">
        <div class="is-inline-flex" style="width: 100%">
          <div
            v-if="
              selectableRecommendedIssues?.length > 0 &&
              selectedDate === formatDate(new Date()) &&
              !isTodayClosed
            "
            class="columns m-0"
          >
            <div class="column is-narrow px-0 py-0 is-align-self-center">
              {{
                $tf("logging.calendar.modal.recommendedIssues|Ajánlott jegyek:")
              }}
            </div>
            <div
              v-for="issue in selectableRecommendedIssues"
              :key="issue.id"
              class="column py-0 pr-0"
            >
              <b-button expanded @click="selectIssue(issue)">{{
                issue.key
              }}</b-button>
            </div>
          </div>
          <div class="ml-auto is-inline-flex">
            <b-tag
              v-if="isTodayClosed"
              class="is-align-self-center"
              icon="triangle-exclamation"
              type="is-warning"
            >
              {{
                $tf(
                  "logging.counter.dayClosed|A mai napi számlálók le lettek zárva!"
                )
              }}
            </b-tag>
            <b-button
              class="ml-2"
              icon-left="lock"
              type="is-primary"
              @click="toCloseDayPage"
              :disabled="
                selectedDate === formatDate(new Date()) && isTodayClosed
              "
              >{{ $tf("logging.counter.closeDay|Nap zárása") }}</b-button
            >
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import VueCal from "vue-cal";
import "vue-cal/dist/vuecal.css";
import {
  formatDate,
  formatTime,
  localFetch,
  secondsToJiraTime,
} from "@/utils/util";
import { mapGetters } from "vuex";
import LoadingSkeleton from "@/components/module/loading/LoadingSkeleton.vue";
import { VUE_CAL_LOCALE } from "@/utils/const";

export default {
  name: "LoggingCounter",
  components: { LoadingSkeleton, VueCal },
  data() {
    return {
      formatDate,
      VUE_CAL_LOCALE,
      selectedDate: formatDate(new Date()),
      loading: false,
      isFetchingTasks: false,
      isClosingDay: false,
      isPostingWorklog: false,
      events: [],
      storedLocale: localFetch("stored_locale") ?? "hu",
      tasks: [],
      taskSearch: null,
      selectedIssue: null,
      countedIssue: null,
      timer: null,
      issuesForm: {},
    };
  },
  computed: {
    filteredIssues() {
      return (
        this.searchedIssues(this.taskSearch?.substring(0, 3))
          ?.filter((issue) => {
            if (
              !this.taskSearch ||
              !issue.name ||
              !issue.projectName ||
              !issue.key
            )
              return false;
            return (
              issue.name
                .toString()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .toLowerCase()
                .indexOf(
                  this.taskSearch
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                    .toLowerCase()
                ) >= 0 ||
              issue.projectName
                .toString()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .toLowerCase()
                .indexOf(
                  this.taskSearch
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                    .toLowerCase()
                ) >= 0 ||
              issue.key
                .toString()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .toLowerCase()
                .indexOf(
                  this.taskSearch
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                    .toLowerCase()
                ) >= 0
            );
          })
          .filter((issue) => issue.id !== this.countedIssue?.id) ?? []
      );
    },
    daysWithCounters() {
      let days = [formatDate(new Date())];
      this.counters.forEach((counter) => {
        const counterDate = formatDate(counter.start);
        if (!days.find((day) => day === counterDate)) {
          days.push(counterDate);
        }
      });
      return days;
    },
    splitDays() {
      let issues = [];
      this.counters
        .filter((counter) => formatDate(counter.start) === this.selectedDate)
        .forEach((counter) => {
          if (!issues.find((issue) => issue.id === counter.issueId)) {
            issues.push({ id: counter.issueId });
          }
        });
      return issues;
    },
    selectableRecommendedIssues() {
      return (
        this.recommendedIssues?.filter(
          (issue) => issue.id !== this.countedIssue?.id
        ) ?? []
      );
    },
    areIssuesRounded() {
      for (let key in this.issuesForm) {
        if (this.issuesForm[key].timeSpentSeconds % (15 * 60) !== 0) {
          return false;
        }
      }
      return true;
    },
    countedIssueDuration() {
      this.events; // This makes duration update with interval
      const secondsPassed = (new Date() - this.countedIssue.start) / 1000;
      return secondsPassed > 60
        ? secondsToJiraTime(secondsPassed)
        : this.$tf("logging.counter.justStarted|Most kezdve");
    },
    isTodayClosed() {
      return !!this.counters.find(
        (counter) =>
          formatDate(counter.started) === formatDate(new Date()) &&
          counter.closed === true
      );
    },
    ...mapGetters({
      recommendedIssues: "enterprise_core/recommendedIssues",
      searchedIssues: "enterprise_core/filteredIssues",
      counters: "enterprise_counter/getCounters",
    }),
  },
  methods: {
    secondsToJiraTime,
    formatTime,
    issueFormatter(issue) {
      return `${issue.key} - ${issue.name}`;
    },
    async startCounter() {
      if (
        this.countedIssue &&
        new Date() - this.countedIssue.start < 15 * 60 * 1000
      ) {
        this.$buefy.dialog.confirm({
          title: this.$tf(
            "logging.counter.recentCounterModal.title|Nem rég indult számláló"
          ),
          message: this.$tf(
            "logging.counter.recentCounterModal.message|Az előző számlálót kevesebb, mint 15 perce indítottad. Új jegy választásával a jelenlegi számláló felül lesz írva!"
          ),
          confirmText: this.$tf(
            "logging.counter.recentCounterModal.confirm|Felülírás"
          ),
          cancelText: this.$tf(
            "logging.counter.recentCounterModal.cancel|Mégse"
          ),
          type: "is-warning",
          hasIcon: true,
          onConfirm: async () => await this.postCounter(true),
        });
      } else {
        await this.postCounter(false);
      }
    },
    async postCounter(override) {
      await this.$store.dispatch("enterprise_counter/startCounter", {
        issueId: this.selectedIssue.id,
        override: override,
      });
      await this.fetchCounters();
      this.timer = setInterval(this.refreshEvents, 60000);
      this.selectedIssue = null;
      this.taskSearch = "";
    },
    async stopCounter() {
      await this.$store.dispatch("enterprise_counter/stopCounter");
      this.countedIssue = null;
      clearInterval(this.timer);
      await this.fetchCounters();
    },
    async fetchCounters() {
      await this.$store.dispatch("enterprise_counter/fetchCounters");
      this.refreshEvents();
    },
    refreshEvents() {
      let e = [];
      this.counters.forEach((counter) => {
        const issue = {
          id: counter.issueId,
          key: counter.issueKey,
          name: counter.issueName,
        };
        e.push({
          start: new Date(counter.start),
          end: this.setEventEnd(counter),
          id: counter.id,
          split: counter.issueId,
          issue: issue,
        });
        if (
          counter.end === null &&
          formatDate(counter.start) === formatDate(new Date())
        ) {
          this.countedIssue = {
            ...issue,
            start: new Date(counter.start),
          };
        }
      });
      this.events = e;
    },
    setEventEnd(counter) {
      if (counter.end) {
        return new Date(counter.end);
      } else if (formatDate(counter.start) === formatDate(new Date())) {
        return new Date();
      } else {
        const loggedTime = this.counters
          .filter(
            (c) => formatDate(c.start) === formatDate(counter.start) && c.end
          )
          .reduce(
            (partialSum, a) =>
              partialSum +
              (new Date(a.end).valueOf() - new Date(a.start).valueOf()),
            0
          );
        return new Date(
          new Date(counter.start).valueOf() + 8 * 60 * 60 * 1000 - loggedTime
        );
      }
    },
    selectIssue(issue) {
      this.selectedIssue = issue;
      this.$refs.autocomplete.setSelected(issue);
    },
    toCloseDayPage() {
      let issues = {};
      this.events
        .filter((event) => formatDate(event.start) === this.selectedDate)
        .forEach((event) => {
          if (issues[event.issue.key]) {
            issues[event.issue.key].timeSpentSeconds +=
              (event.end.valueOf() - event.start.valueOf()) / 1000;
          } else {
            issues[event.issue.key] = {
              issueId: event.issue.id,
              issueKey: event.issue.key,
              timeSpentSeconds:
                (event.end.valueOf() - event.start.valueOf()) / 1000,
              started: event.start,
              comment: null,
            };
          }
        });
      this.issuesForm = issues;
      this.isClosingDay = true;
    },
    async closeDay() {
      this.isPostingWorklog = true;
      await this.$store.dispatch("enterprise_counter/closeDay", {
        date: this.selectedDate,
        worklogs: Object.values(this.issuesForm),
      });
      await this.fetchCounters();
      this.isClosingDay = false;
      this.isPostingWorklog = false;
    },
    addToIssue(issueKey) {
      this.issuesForm[issueKey].timeSpentSeconds +=
        this.issuesForm[issueKey].timeSpentSeconds % 900 === 0
          ? 900
          : 900 - (this.issuesForm[issueKey].timeSpentSeconds % 900);
    },
    subtractFromIssue(issueKey) {
      this.issuesForm[issueKey].timeSpentSeconds -=
        this.issuesForm[issueKey].timeSpentSeconds % 900 === 0
          ? 900
          : this.issuesForm[issueKey].timeSpentSeconds % 900;
    },
  },
  watch: {
    async taskSearch(newVal) {
      if (newVal?.length === 3) {
        this.isFetchingTasks = true;
        await this.$store.dispatch("enterprise_core/searchIssues", newVal);
        this.isFetchingTasks = false;
      }
    },
  },
  async mounted() {
    this.loading = true;
    await this.$store.dispatch("enterprise_core/fetchRecommendedIssues");
    await this.fetchCounters();
    if (this.countedIssue) {
      this.timer = setInterval(this.refreshEvents, 60000);
    }
    this.loading = false;
  },
};
</script>

<style lang="scss">
.end-day-cell {
  border-bottom: 2px rgb(128, 128, 128) solid;
  padding: 0.5rem;
  justify-content: center;
}
</style>
