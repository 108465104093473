<template>
  <div>
    <div
      class="information-card"
      :style="`box-shadow: 0 4px 14px 0 rgba(0, 0, 0, ${
        isDark ? '0.7' : '0.05'
      });`"
    >
      <div class="information-card-upper">
        <div class="information-card-upper-left">
          <slot name="card-upper-left" />
        </div>
        <div class="information-card-upper-right">
          <slot name="card-upper-right" />
        </div>
      </div>
      <div class="information-card-lower">
        <div class="information-card-lower-left">
          <slot name="card-lower-left" />
        </div>
        <div class="information-card-lower-right">
          <slot name="card-lower-right" />
        </div>
      </div>
      <b-loading :is-full-page="false" :modelValue="loading" />
    </div>
  </div>
</template>

<script>
import { UI_THEMES } from "@/utils/const";
import { mapState } from "vuex";

export default {
  name: "InformationCard",
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState("uiConfigStore", {
      currentTheme: (state) => state.currentTheme,
    }),
    isDark() {
      return this.currentTheme === UI_THEMES.DARK;
    },
  },
};
</script>

<style scoped lang="scss">
.information {
  display: flex;
  flex-direction: row;

  &-card {
    padding: 16px;
    box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.05);
    margin: 8px;
    min-height: 136px;
    height: calc(100% - 16px);
    border-radius: 8px;
    position: relative;

    &-upper,
    &-lower {
      display: flex;
      flex-direction: row;
    }

    &-upper {
      &-left {
        flex: 1 0 70%;
      }

      &-right {
        flex: 1 0 30%;
      }

      &-right {
        text-align: right;
      }
    }

    &-lower {
      padding-top: 24px;

      &-left {
        flex: 1 0 70%;
      }

      &-right {
        flex: 1 0 30%;
      }

      &-right {
        text-align: right;
      }
    }
  }
}
</style>
