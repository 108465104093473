<!--suppress CssUnusedSymbol -->
<template>
  <div class="commented-approval">
    <section v-if="commentedApproval && commentedApproval.length > 0">
      <template v-for="(comment, index) in commentedApproval">
        <article
          class="media"
          :class="`media-${getApprovalClass(comment.approval)}`"
          v-if="comment.absenceRequestId === absenceRequestId"
          :key="index"
        >
          <figure class="media-left">
            <template v-if="getUser(comment.user) != null">
              <user-info display-mode="compact" :user="getUser(comment.user)" />
            </template>
            <template v-else>
              <avatar-placeholder
                :username="comment.user"
                class="approval-avatar-placeholder"
              />
            </template>
          </figure>
          <div class="media-content">
            <div class="content content-approval">
              <strong class="content-item"> {{ comment.user }} </strong>
              <small class="content-item">
                {{ formatDate(comment.created) }}
              </small>
              <strong class="content-item-approval">
                <span :class="getApprovalClass(comment.approval)">
                  {{ getApprovalDisplay(comment.approval) }}
                </span>
              </strong>
            </div>
            <div v-if="comment.comment" class="content-comment">
              {{ comment.comment }}
            </div>
          </div>
        </article>
      </template>
    </section>
    <section v-else>
      {{
        $tf(
          "commentedApproval.nothingHappenedYet|Még nem történt semmi a szabadságkérelemmel."
        )
      }}
    </section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { formatDate } from "@/utils/util";
import AvatarPlaceholder from "../../module/icon/AvatarPlaceholder.vue";
import UserInfo from "../../module/info/UserInfo.vue";

export default {
  name: "CommentedApproval",
  components: { UserInfo, AvatarPlaceholder },
  mounted() {
    //this.$store.dispatch("employee/fetchColleagues");
  },

  props: {
    absenceRequestId: null,
    reload: null,
  },
  data() {
    return {
      isLoading: true,
      avatars: {},
      formatDate,
    };
  },
  computed: {
    ...mapGetters({
      commentedApproval: "absence_request/commentedApproval",
      employees: "employee/currentEmployees",
    }),
  },

  watch: {
    async reload() {
      await this.$store.dispatch("absence_request/getCommentedApproval", {
        absenceRequestId: this.absenceRequestId,
        force: true,
      });
    },
  },
  created: async function () {
    await this.$store.dispatch("absence_request/getCommentedApproval", {
      absenceRequestId: this.absenceRequestId,
    });
  },

  methods: {
    getAvatar(user) {
      let employee = this.employees.filter((emp) => {
        return emp.name === user;
      });
      if (employee[0]) return employee[0].avatar;
      else return null;
    },
    getUser(user) {
      let employee = this.employees.filter((emp) => {
        return emp.name === user;
      });
      if (employee[0]) return employee[0];
      else return null;
    },
    getApprovalDisplay(approval) {
      switch (approval) {
        case "APPROVE":
          return "ELFOGADVA";
        case "DENY":
          return "ELUTASÍTVA";
        case "CLARIFY":
          return "EGYEZTETÉS SZÜKSÉGES";
      }
    },
    getApprovalClass(approvalDecision) {
      if (approvalDecision === "APPROVE") {
        return "green";
      } else if (approvalDecision === "DENY") {
        return "red";
      } else if (approvalDecision === "CLARIFY") {
        return "yellow";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/colors.scss";

.green {
  background: $green;
  color: $always-white;
  padding: 4px;
  border-radius: 4px;
}
.red {
  color: $always-white;
  padding: 4px;
  border-radius: 4px;
  background: $red;
}
.yellow {
  background: $yellow;
  color: $always-white;
  padding: 4px;
  border-radius: 4px;
}

.commented-approval {
  margin-top: 30px;
  margin-bottom: 30px;

  .approval-avatar-placeholder {
    padding: 10px;
    height: 42px;
    width: 42px;
  }

  .content {
    &-item {
      display: block;
      flex: 1 0 25%;

      &-approval {
        display: block;
        flex: 1 0 50%;
      }
    }

    &-approval {
      margin-bottom: 0 !important;
    }
  }

  .content {
    display: flex;

    &-comment {
      font-size: 14px;
      color: $grey;
      padding-top: 10px;
    }
  }

  .media-left {
    margin-bottom: 0 !important;
  }

  .media-content {
    padding-right: 2em;
  }

  .media {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    align-items: center !important;
    border: 1px solid #eceff5;
    border-radius: 10px;

    &-green {
      border-left: 5px solid $green;
    }

    &-yellow {
      border-left: 5px solid $yellow;
    }

    &-red {
      border-left: 5px solid $red;
    }
  }

  .media + .media {
    margin-top: 0.5rem;
  }
}
</style>
