import { newLogger } from "@/utils/util";
import StoreCore from "@/store/StoreCore";

let logger = newLogger("Enterprise-InsightsHistoryStore");

/**
 * @typedef {{
 *   type: string,
 *   mode: string,
 *   easySubMode: ?string,
 *   prefix: string,
 *   query: string,
 *   task: ?object,
 *   searchQuery: ?object,
 *   link: string
 * }} ExternalIssueTargeting
 * @typedef {{
 *   id: number,
 *   name: string,
 *   projectName: string,
 *   projectId: number,
 *   identifier: string,
 *   type: string,
 *   startDate: string,
 *   dueDate: string,
 *   commitment: number,
 *   budget: number,
 *   spent: ?number,
 *   billableAmount: number,
 *   billablePercentage: number,
 *   progressPercentage: ?number,
 *   status: string,
 *   expectedLogTime: ?number,
 *   grossFlatRate: number,
 *   externalIssueTargeting: ExternalIssueTargeting
 * }} Tig
 */

const urls = {
  history: "/enterprise/insights/history",
};

const initState = () => {
  return {
    /**
     * @type {[{
     *     tig: Tig,
     *     records: [{
     *       date: string,
     *       logTime: number,
     *       logCost: number
     *     }]
     * }]}
     */
    projectHistory: [],
  };
};

const storeCore = new StoreCore();
const actions = {
  /**
   * fetch the thing
   * @type {(
   *      context: ActionContext<S, R>,
   *      { force?: boolean, params: { projectId: number }}
   *    ) => Promise<void>}
   */
  fetchProjectHistory: async function (
    { commit, dispatch },
    { force, params } = {}
  ) {
    try {
      const urlSearchParams = new URLSearchParams();
      urlSearchParams.set("projectId", params.projectId);
      let data = await this.remoteRequest(
        "get",
        urls.history + "?" + urlSearchParams.toString(),
        true,
        true,
        dispatch,
        "Project história betöltése...",
        null,
        force
      );
      commit("saveProjectHistory", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
};

const mutations = {
  saveProjectHistory: (state, data) => {
    state.projectHistory = data;
  },
};

const getters = {
  projectHistory: (state) => state.projectHistory,
};

export default {
  namespaced: true,
  state: initState(),
  mutations: mutations,
  actions: actions,
  getters: getters,
};
