<template>
  <section class="section">
    <div class="container">
      <h1 class="title">Hozzáférésváltoztatási kérelmek</h1>
      <h2 class="subtitle">
        Beküldött Hozzáférésváltoztatási kérelmek kezelése
      </h2>
    </div>
    <hr />

    <div class="container" v-if="toApproveRequests">
      <PermissionRequestTable
        :items="toApproveRequests.items"
        :pagination="pagination"
        :sort="sort"
        :on-sort="onSort"
        :on-page-change="onPageChange"
        empty-message="Jelenleg nincs elbírálásra váró hozzáférésváltoztatási kérelem."
        @change="getToApproveRequests(true)"
        :loading="loading"
      />
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import { createUrlSearchParams, formatDate } from "@/utils/util";
import PermissionRequestTable from "./PermissionRequestTable";

export default {
  components: { PermissionRequestTable },
  name: "HandlePermissionRequestList",
  async mounted() {
    await this.getToApproveRequests();
  },
  data() {
    return {
      formatDate,
      pagination: {
        totalCount: 0,
        page: 0,
        size: 10,
      },
      sort: {
        field: "lastModified",
        order: "desc",
      },
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      toApproveRequests: "user_permission/toApproveRequests",
    }),
  },
  methods: {
    async getToApproveRequests(force) {
      this.loading = true;
      await this.$store.dispatch(
        "user_permission/getPermissionRequestToApprove",
        {
          params: { params: createUrlSearchParams(this.pagination, this.sort) },
          force,
        }
      );
      this.pagination = this.toApproveRequests.pagination;
      this.loading = false;
    },
    onPageChange: function (page) {
      this.pagination.page = page - 1;
      this.getToApproveRequests();
    },
    onSort: function (field, order) {
      this.sort.field = field;
      this.sort.order = order;
      this.getToApproveRequests();
    },
  },
};
</script>

<style scoped></style>
