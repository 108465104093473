import StoreCore from "../../StoreCore";
import axios from "axios";
import { isNullOrUndefined } from "@/utils/util";
import { UI_THEMES } from "@/utils/const";

const urls = {
  config: "/api/tenant/ui-config",
};

const initState = () => {
  return {
    flags: {},
    enterprise: {},
    issueTracking: {},
    currentTheme: UI_THEMES.SYSTEM,
  };
};

const storeCore = new StoreCore();
const actions = {
  fetch: async function ({ commit }, tenantId) {
    try {
      const { data } = await axios.get(urls.config, {
        headers: {
          "X-Tenant-Id": tenantId,
        },
      });
      commit("save", data);
    } catch (err) {
      console.error(err);
      await Promise.reject(err);
    }
  }.bind(storeCore),
  clearCache: async function ({}) {
    this.clearCoreCache();
  }.bind(storeCore),
};

const mutations = {
  save: (state, data) => {
    state.flags = data["flags"];
    state.enterprise = data["enterprise"];
    state.issueTracking = data["issueTracking"];
  },
  setCurrentTheme: (state, theme) => {
    state.currentTheme = theme;
  },
};

const getters = {
  isLoaded: (state) => {
    return (
      Object.keys(state.flags ?? []).length &&
      Object.keys(state.enterprise ?? []).length
    );
  },
  getCurrencySymbol: (state) => {
    return state.enterprise?.currency?.symbol ?? "";
  },
  getCurrencyTier: (state) => (tier) => {
    return state.enterprise?.currency?.tiers?.find((t) => t.tier === tier);
  },
  getCurrencyPrecision: (state) => {
    return isNullOrUndefined(state.enterprise?.currency?.decimals)
      ? 2
      : state.enterprise.currency.decimals;
  },
  getIssueTrackingSystem: (state) => {
    return state.issueTracking?.system;
  },
  getIssueTrackingData: (state) => {
    return state.issueTracking;
  },
  getFlags: (state) => {
    return state.flags;
  },
  getEnterprise: (state) => {
    return state.enterprise;
  },
  getRiskKpiLimits: (state) => {
    return [
      state.enterprise?.lowerRiskKpiLimit ?? 1,
      state.enterprise?.upperRiskKpiLimit ?? 1,
    ];
  },
  getCurrentTheme: (state) => {
    return state.currentTheme;
  },
};

export default {
  namespaced: true,
  state: initState(),
  mutations: mutations,
  actions: actions,
  getters: getters,
};
