<template>
  <div>
    <template v-if="response == null">
      <p>
        {{
          $tf(
            "logging.import.p1|Az Excel import segítségével gyorsan tudod rögzíteni az elvégzett munkát."
          )
        }}
        <br />
        {{
          $tf(
            "logging.import.p2|A feltöltéshez a Forecastify által biztosított sablont kell importálnod. A sablont"
          )
        }}
        <a @click="getTemplate">{{
          $tf("logging.import.downloadLink|itt tudod letölteni")
        }}</a
        >.
      </p>
      <p>
        {{
          $tf(
            "logging.import.p3|A feltöltött fájlnak tartalmaznia kell a fejléceket és az oszlopok nem törölhetőek. Amennyiben kezdési időpont nem kerül megadásra, az idő a nap legelejére (8:00) lesz rögzítve."
          )
        }}
      </p>
      <ForcaUpload
        v-model="file"
        :accept="UPLOAD_ACCEPT.SPREADSHEET"
        size="is-large"
        :loading="isImportInProgress"
      />
    </template>
    <template v-else>
      <h4 class="subtitle mb-4">
        {{ $tf("logging.import.result.subtitle|Import eredménye") }}
      </h4>
      <b-button icon-left="chevron-left" class="mb-4" @click="response = null"
        >Vissza</b-button
      >
      <p>
        {{
          $tf(
            "logging.import.result.lines|Az import során {lineCount} sort találtunk, ezek eredményét a táblázatban láthatod.",
            { lineCount: importedRowsLength }
          )
        }}
      </p>
      <b-table
        :data="importedData"
        detailed
        detail-key="date"
        custom-detail-row
        :show-detail-icon="true"
      >
        <b-table-column field="date" label="Dátum" v-slot="props">
          <span class="has-font-weight-700">
            {{ props.row.date }}
          </span>
        </b-table-column>
        <b-table-column field="issueKey" label="Jegy" v-slot="props">
          <span class="has-font-weight-700">{{
            props.row.worklogs[0].issueKey
          }}</span>
          <template v-if="!!(props.row.worklogs.length - 1)">
            {{
              $tf(
                "logging.import.result.remainingKeys| és {remainingKeys} további",
                { remainingKeys: props.row.worklogs.length - 1 }
              )
            }}
          </template>
        </b-table-column>
        <b-table-column field="started" label="Kezdési időpont" v-slot="props">
          {{ props.row.started }}
        </b-table-column>
        <b-table-column field="seconds" label="Időtartam" v-slot="props">
          {{ secondsToJiraTime(props.row.seconds) }}
        </b-table-column>
        <b-table-column field="comment" label="Megjegyzés" v-slot="props">
          {{ props.row.comment }}
        </b-table-column>

        <template #detail="props">
          <tr v-for="(item, i) in props.row.worklogs" :key="i">
            <td></td>
            <td></td>
            <td class="has-font-weight-700">
              {{ item.issueKey }}
            </td>
            <td>{{ item.started }}</td>
            <td>{{ secondsToJiraTime(item.seconds) }}</td>
            <td>{{ item.comment }}</td>
          </tr>
        </template>
      </b-table>

      <template v-if="!!errorData">
        <h4 class="subtitle mb-4">
          {{
            $tf("logging.import.result.error.subtitle|Nem menthető bejegyzések")
          }}
        </h4>
        <p>
          {{
            $tf(
              "logging.import.result.error.lines|Az import során {lineCount} el nem menthető sort találtunk, ezeket a táblázatban láthatod.",
              { lineCount: errorData.length }
            )
          }}
        </p>
        <b-table :data="errorData">
          <b-table-column field="date" label="Dátum" v-slot="props">
            <span class="has-font-weight-700">
              {{ props.row.date }}
            </span>
          </b-table-column>
          <b-table-column field="issueKey" label="Jegy" v-slot="props">
            <span class="has-font-weight-700">{{ props.row.issueKey }}</span>
          </b-table-column>
          <b-table-column
            field="started"
            label="Kezdési időpont"
            v-slot="props"
          >
            {{ props.row.started }}
          </b-table-column>
          <b-table-column field="seconds" label="Időtartam" v-slot="props">
            {{ secondsToJiraTime(props.row.seconds) }}
          </b-table-column>
          <b-table-column field="comment" label="Megjegyzés" v-slot="props">
            {{ props.row.comment }}
          </b-table-column>
          <b-table-column field="errors" label="Hibák" v-slot="props">
            {{ getErrorString(props.row.errors) }}
          </b-table-column>
        </b-table>
      </template>
      <b-button
        type="is-primary"
        class="is-pulled-right"
        @click="postWorklogs"
        :loading="isUploadInProgress"
        >Munka rögzítése</b-button
      >
    </template>
  </div>
</template>

<script>
import { secondsToJiraTime } from "@/utils/util";
import FileSaver from "file-saver";
import ForcaUpload from "@/components/ForcaUpload.vue";
import { UPLOAD_ACCEPT } from "@/utils/const";

export default {
  name: "LoggingImport",
  components: { ForcaUpload },
  data() {
    return {
      file: null,
      isImportInProgress: false,
      isUploadInProgress: false,
      response: null,
      UPLOAD_ACCEPT,
    };
  },
  watch: {
    file(file) {
      if (file) {
        this.onUpload(file);
      }
    },
  },
  computed: {
    importedData() {
      return this.response?.imported || [];
    },
    errorData() {
      return this.response?.errors || [];
    },
    importErrors() {
      return {
        ISSUE_NOT_FOUND: this.$tf(
          "logging.import.error.issueNotFound|A jegy nem található a rendszerben."
        ),
        INVALID_TIME_FORMAT: this.$tf(
          "logging.import.error.invalidTimeFormat|Az időtartam formátuma nem megfelelő."
        ),
        INVALID_DATE: this.$tf(
          "logging.import.error.invalidDate|A dátum formátuma nem megfelelő."
        ),
      };
    },
    importedRowsLength() {
      return this.importedData.flatMap((day) => day.worklogs).length;
    },
  },
  methods: {
    secondsToJiraTime,
    getErrorString(errors) {
      if (!errors) return undefined;
      return errors.map((error) => this.importErrors[error]).join(", ");
    },
    async getTemplate() {
      let data = await this.$store.dispatch("worklog/exportTemplate");
      FileSaver.saveAs(data, "worklog_template.xlsx");
    },
    async onUpload(file) {
      this.isImportInProgress = true;
      let formData = new FormData();
      formData.append("file", file);
      this.$store.dispatch("worklog/importLogs", formData).then((result) => {
        if (result) {
          this.$buefy.toast.open({
            duration: 2000,
            message: this.$tf(
              "logging.import.upload.success|Sikeres fájlfeltöltés!"
            ),
            type: "is-success",
          });
          this.response = result;
        } else {
          this.$buefy.toast.open({
            duration: 5000,
            message: this.$tf(
              "logging.import.upload.error|Hiba történt a fájl feltöltése közben!"
            ),
            type: "is-danger",
          });
          this.file = null;
        }
        this.isImportInProgress = false;
      });
    },
    async postWorklogs() {
      this.isUploadInProgress = true;
      let worklogs = [];
      this.importedData.forEach((day) => {
        worklogs.push(
          day.worklogs
            .filter((worklog) => worklog.issueId != null)
            .map((worklog) => {
              return {
                issueKey: worklog.issueKey,
                issueId: worklog.issueId,
                timeSpentSeconds: worklog.seconds,
                comment: worklog.comment,
                started: new Date(`${day.date}T${worklog.started}`),
              };
            })
        );
      });
      this.$store.dispatch("worklog/postWorklogs", worklogs.flat()).then(() => {
        this.$buefy.toast.open({
          duration: 5000,
          message: this.$tf("logging.import.success|Sikeres munka rögzítés!"),
          type: "is-success",
        });
        this.response = null;
        this.isUploadInProgress = false;
      });
    },
  },
};
</script>

<style scoped lang="scss"></style>
