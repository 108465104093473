<template>
  <div>
    <table aria-label="since-start" class="whole-table">
      <thead>
        <tr>
          <th></th>
          <th class="icon-cell"></th>
          <th>
            {{ $tf("projectPage.forecastWholeStatus.table.mwd|Munkaidő") }}
          </th>
          <th class="icon-cell"></th>
          <th>
            {{
              $tf(
                "projectPage.forecastWholeStatus.table.budgetSpent|Büdzsé/Költés"
              )
            }}
          </th>
          <th class="icon-cell"></th>
          <th>
            {{ $tf("projectPage.forecastWholeStatus.table.time|Átfutási idő") }}
          </th>
          <th>
            {{ $tf("projectPage.forecastWholeStatus.table.dueDate|Határidő") }}
          </th>
          <th class="icon-cell"></th>
          <th>
            {{ $tf("projectPage.forecastWholeStatus.table.income|Bevétel") }}
          </th>
          <th class="icon-cell"></th>
          <th>
            {{ $tf("projectPage.forecastWholeStatus.table.profit|Profit") }}
          </th>
          <th>
            {{
              $tf(
                "projectPage.forecastWholeStatus.table.profitPercentage|Profit%"
              )
            }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr class="plan-row">
          <td class="has-font-weight-500">
            <b-tooltip
              position="is-right"
              :label="
                $tf(
                  'projectPage.forecastWholeStatus.table.plan.tooltip|A projekt létrehozásakor megadott paraméterek a projektre vonatkozóan.'
                )
              "
              >{{
                $tf("projectPage.forecastWholeStatus.table.plan|Terv")
              }}</b-tooltip
            >
          </td>
          <td class="icon-cell"></td>
          <td>{{ workdayify(commitment) }}</td>
          <td class="icon-cell"></td>
          <td>{{ moneyify(budget) }}</td>
          <td class="icon-cell"></td>
          <td>{{ numberify(time) }} nap</td>
          <td>
            {{ formatDate(dueDate) }}
          </td>
          <td class="icon-cell"></td>
          <td>
            <template v-if="projectData.internal"> - </template>
            <template v-else>
              {{ moneyify(income) }}
            </template>
          </td>
          <td class="icon-cell"></td>
          <td>
            <template v-if="projectData.internal"> - </template>
            <template v-else>
              {{ moneyify(profit) }}
            </template>
          </td>
          <td>
            <template v-if="projectData.internal"> - </template>
            <template v-else>
              {{ validPercentage(profitPercent) }}
            </template>
          </td>
        </tr>
        <tr class="blank-row-small">
          <td colspan="13" class="blank-row-small-td"></td>
        </tr>
        <tr class="forecast-trend-row">
          <td class="has-font-weight-500">
            <b-tooltip position="is-right">
              <template v-slot:content>
                <div class="content tooltip">
                  <p>
                    {{
                      $tf(
                        "projectPage.forecastWholeStatus.table.trendingForecast.tooltip|A projekt trendtartó előrejelzése szerint várható adatok. A trendtartó előrejelzés a már rögzített adatok alapjánszámolt trend alapján jelzi az adatokat."
                      )
                    }}
                  </p>
                </div>
              </template>
              {{
                $tf(
                  "projectPage.forecastWholeStatus.table.trendingForecast|Trendtartó FC"
                )
              }}
            </b-tooltip>
          </td>
          <td class="icon-cell"></td>
          <td>{{ workdayify(trendingCommitment) }}</td>
          <td class="icon-cell"></td>
          <td>{{ moneyify(trendingBudget) }}</td>
          <td class="icon-cell"></td>
          <td>{{ numberify(trendingTime) }} nap</td>
          <td>
            {{ formatDate(trendingDueDate) }}
          </td>
          <td class="icon-cell"></td>
          <td>
            <template v-if="projectData.internal"> - </template>
            <template v-else>
              {{ moneyify(trendingIncome) }}
            </template>
          </td>
          <td class="icon-cell"></td>
          <td>
            <template v-if="projectData.internal"> - </template>
            <template v-else>
              {{ moneyify(trendingProfit) }}
            </template>
          </td>
          <td>
            <template v-if="projectData.internal"> - </template>
            <template v-else>
              {{ validPercentage(trendingProfitPercent) }}
            </template>
          </td>
        </tr>
        <tr>
          <td class="is-vertical-align-middle has-font-weight-500">
            <b-tooltip position="is-right">
              <template v-slot:content>
                <div class="content tooltip">
                  <p>
                    {{
                      $tf(
                        "projectPage.forecastWholeStatus.table.planVsFc.tooltip|A projekt létrehozásakor megadott terv adatok, és a trendtartó előrejelzés közötti különbség. Amennyiben az adatok megfelelőek a felület zölddel jelzi. Nem optimális adatok esetén az érték pirossal kerül megjelölésre."
                      )
                    }}
                  </p>
                </div>
              </template>
              {{
                $tf("projectPage.forecastWholeStatus.table.planVsFc|Terv vs FC")
              }}
            </b-tooltip>
          </td>
          <td class="icon-cell">
            <div class="diff-icon" :class="getRiskColor(diffPercentTrendingMd)">
              <b-icon
                size="is-small"
                :icon="getRiskIcon(diffPercentTrendingMd)"
              ></b-icon>
            </div>
          </td>
          <td>
            {{ workdayify(diffTrendingMd) }}
            <span class="table-percent">
              ({{ validPercentage(diffPercentTrendingMd) }})
            </span>
          </td>
          <td class="icon-cell">
            <div
              class="diff-icon"
              :class="getRiskColor(diffPercentTrendingBudget)"
            >
              <b-icon
                size="is-small"
                :icon="getRiskIcon(diffPercentTrendingBudget)"
              ></b-icon>
            </div>
          </td>
          <td>
            {{ moneyify(diffTrendingBudget) }}
            <span class="table-percent">
              ({{ validPercentage(diffPercentTrendingBudget) }})
            </span>
          </td>
          <td class="icon-cell">
            <div
              class="diff-icon"
              :class="getRiskColor(diffPercentTrendingTime)"
            >
              <b-icon
                size="is-small"
                :icon="getRiskIcon(diffPercentTrendingTime)"
              ></b-icon>
            </div>
          </td>
          <td>
            {{ numberify(diffTrendingTime) }} nap
            <span class="table-percent">
              ({{ validPercentage(diffPercentTrendingTime) }})
            </span>
          </td>
          <td></td>
          <td class="icon-cell">
            <div
              v-if="!projectData.internal"
              class="diff-icon"
              :class="getRiskColor(diffPercentTrendingIncome)"
            >
              <b-icon
                size="is-small"
                :icon="getRiskIcon(diffPercentTrendingIncome)"
              ></b-icon>
            </div>
          </td>
          <td>
            <template v-if="projectData.internal"> - </template>
            <template v-else>
              {{ moneyify(diffTrendingIncome) }}
              <span class="table-percent">
                ({{ validPercentage(diffPercentTrendingIncome) }})
              </span>
            </template>
          </td>
          <td class="icon-cell">
            <div
              v-if="!projectData.internal"
              class="diff-icon"
              :class="getRiskColor(diffPercentTrendingProfit)"
            >
              <b-icon
                size="is-small"
                :icon="getRiskIcon(diffPercentTrendingProfit)"
              ></b-icon>
            </div>
          </td>
          <td>
            <template v-if="projectData.internal"> - </template>
            <template v-else>
              {{ moneyify(diffTrendingProfit) }}
              <span class="table-percent">
                ({{ validPercentage(diffPercentTrendingProfit) }})
              </span>
            </template>
          </td>
          <td>
            <template v-if="projectData.internal"> - </template>
            <template v-else>
              {{ validPercentage(diffTrendingProfitPercent) }}
            </template>
          </td>
        </tr>
        <tr>
          <td class="has-font-weight-500">
            <b-tooltip position="is-right">
              <template v-slot:content>
                <div class="content tooltip">
                  <p>
                    {{
                      $tf(
                        "projectPage.forecastWholeStatus.table.projectRiskKPI.tooltip|Az előrjelzés és a terv közötti arányszám. Amennnyiben az érték 1.00 alatt van, a projekt az elvártnál jobban teljesít, 1.00 érték felett pedig rosszabbul. Az optimális érték 1.00, illetve az alatti szám."
                      )
                    }}
                  </p>
                </div>
              </template>
              {{
                $tf(
                  "projectPage.forecastWholeStatus.table.projectRiskKPIWithLimits|Projekt Risk KPI"
                )
              }}
            </b-tooltip>
          </td>
          <td class="icon-cell"></td>
          <td>
            <risk-kpi-tag :value="riskTrendingMd" />
          </td>
          <td class="icon-cell"></td>
          <td>
            <risk-kpi-tag :value="riskTrendingBudget" />
          </td>
          <td class="icon-cell"></td>
          <td colspan="7">
            <risk-kpi-tag :value="riskTrendingTime" />
          </td>
        </tr>
        <tr class="blank-row-small">
          <td colspan="13" class="blank-row-small-td"></td>
        </tr>
        <tr class="forecast-plan-row">
          <td class="has-font-weight-500">
            <b-tooltip position="is-right">
              <template v-slot:content>
                <div class="content tooltip">
                  <p>
                    {{
                      $tf(
                        "projectPage.forecastWholeStatus.table.planningForecast.tooltip|A projekt tervtartó előrejelzése szerint várható adatok. A tervtartó előrejelzés a már eltelt idő és az idő alatt rögzített ráfordítás alapján számolt trend alapján jelzi az adatokat."
                      )
                    }}
                  </p>
                </div>
              </template>
              {{
                $tf(
                  "projectPage.forecastWholeStatus.table.planningForecast|Tervtartó FC"
                )
              }}
            </b-tooltip>
          </td>
          <td class="icon-cell"></td>
          <td>{{ workdayify(planningCommitment) }}</td>
          <td class="icon-cell"></td>
          <td>{{ moneyify(planningBudget) }}</td>
          <td class="icon-cell"></td>
          <td>{{ numberify(planningTime) }} nap</td>
          <td>
            {{ formatDate(planningDueDate) }}
          </td>
          <td class="icon-cell"></td>
          <td>
            <template v-if="projectData.internal"> - </template>
            <template v-else>
              {{ moneyify(planningIncome) }}
            </template>
          </td>
          <td class="icon-cell"></td>
          <td>
            <template v-if="projectData.internal"> - </template>
            <template v-else>
              {{ moneyify(planningProfit) }}
            </template>
          </td>
          <td>
            <template v-if="projectData.internal"> - </template>
            <template v-else>
              {{ validPercentage(planningProfitPercent) }}
            </template>
          </td>
        </tr>
        <tr>
          <td class="is-vertical-align-middle has-font-weight-500">
            <b-tooltip position="is-right">
              <template v-slot:content>
                <div class="content tooltip">
                  <p>
                    {{
                      $tf(
                        "projectPage.forecastWholeStatus.table.planVsFc.tooltip|A projekt létrehozásakor megadott terv adatok, és a tervtartó előrejelzés közötti különbség. Amennyiben az adatok megfelelőek a felület zölddel jelzi. Nem optimális adatok esetén az érték pirossal kerül megjelölésre."
                      )
                    }}
                  </p>
                </div>
              </template>
              {{
                $tf("projectPage.forecastWholeStatus.table.planVsFc|Terv vs FC")
              }}
            </b-tooltip>
          </td>
          <td class="icon-cell">
            <div class="diff-icon" :class="getRiskColor(diffPercentPlanningMd)">
              <b-icon
                size="is-small"
                :icon="getRiskIcon(diffPercentPlanningMd)"
              ></b-icon>
            </div>
          </td>
          <td>
            {{ workdayify(diffPlanningMd) }}
            <span class="table-percent">
              ({{ validPercentage(diffPercentPlanningMd) }})
            </span>
          </td>
          <td class="icon-cell">
            <div
              class="diff-icon"
              :class="getRiskColor(diffPercentPlanningBudget)"
            >
              <b-icon
                size="is-small"
                :icon="getRiskIcon(diffPercentPlanningBudget)"
              ></b-icon>
            </div>
          </td>
          <td>
            {{ moneyify(diffPlanningBudget) }}
            <span class="table-percent">
              ({{ validPercentage(diffPercentPlanningBudget) }})
            </span>
          </td>
          <td class="icon-cell">
            <div
              class="diff-icon"
              :class="getRiskColor(diffPercentPlanningTime)"
            >
              <b-icon
                size="is-small"
                :icon="getRiskIcon(diffPercentPlanningTime)"
              ></b-icon>
            </div>
          </td>
          <td>
            {{ numberify(diffPlanningTime) }} nap
            <span class="table-percent">
              ({{ validPercentage(diffPercentPlanningTime) }})
            </span>
          </td>
          <td></td>
          <td class="icon-cell">
            <div
              v-if="!projectData.internal"
              class="diff-icon"
              :class="getRiskColor(diffPercentPlanningIncome)"
            >
              <b-icon
                size="is-small"
                :icon="getRiskIcon(diffPercentPlanningIncome)"
              ></b-icon>
            </div>
          </td>
          <td>
            <template v-if="projectData.internal"> - </template>
            <template v-else>
              {{ moneyify(diffPlanningIncome) }}
              <span class="table-percent">
                ({{ validPercentage(diffPercentPlanningIncome) }})
              </span>
            </template>
          </td>
          <td class="icon-cell">
            <div
              v-if="!projectData.internal"
              class="diff-icon"
              :class="getRiskColor(diffPercentPlanningProfit)"
            >
              <b-icon
                size="is-small"
                :icon="getRiskIcon(diffPercentPlanningProfit)"
              ></b-icon>
            </div>
          </td>
          <td>
            <template v-if="projectData.internal"> - </template>
            <template v-else>
              {{ moneyify(diffPlanningProfit) }}
              <span class="table-percent">
                ({{ validPercentage(diffPercentPlanningProfit) }})
              </span>
            </template>
          </td>
          <td>
            <template v-if="projectData.internal"> - </template>
            <template v-else>
              {{ validPercentage(diffPlanningProfitPercent) }}
            </template>
          </td>
        </tr>
        <tr>
          <td class="has-font-weight-500">
            <b-tooltip position="is-right">
              <template v-slot:content>
                <div class="content tooltip">
                  <p>
                    {{
                      $tf(
                        "projectPage.forecastWholeStatus.table.projectRiskKPI.tooltip|Az előrjelzés és a terv közötti arányszám. Amennnyiben az érték 1.00 alatt van, a projekt az elvártnál jobban teljesít, 1.00 érték felett pedig rosszabbul. Az optimális érték 1.00, illetve az alatti szám."
                      )
                    }}
                  </p>
                </div>
              </template>
              {{
                $tf(
                  "projectPage.forecastWholeStatus.table.projectRiskKPI|Projekt risk KPI"
                )
              }}
            </b-tooltip>
          </td>
          <td class="icon-cell"></td>
          <td>
            <risk-kpi-tag :value="riskPlanningMd" />
          </td>
          <td class="icon-cell"></td>
          <td>
            <risk-kpi-tag :value="riskPlanningBudget" />
          </td>
          <td class="icon-cell"></td>
          <td colspan="7">
            <risk-kpi-tag :value="riskPlanningTime" />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import {
  formatDate,
  moneyify,
  numberify,
  percentify,
  roundToTwoDecimals,
  workdayify,
  getCurrentColorSet,
} from "@/utils/util";
import { PROJECT_PROGRESS_TYPE } from "@/utils/const";
import { mapGetters } from "vuex";
import KpiMixin from "@/mixins/KpiMixin";
import RiskKpiTag from "../RiskKpiTag.vue";

export default {
  name: "ForecastWholeTable",
  components: { RiskKpiTag },
  mixins: [KpiMixin],
  props: {
    projectDetails: {
      type: Object,
      required: true,
    },
    projectData: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      riskKpiLimits: "uiConfigStore/getRiskKpiLimits",
    }),
    notTracked() {
      return this.projectDetails.progressType === PROJECT_PROGRESS_TYPE.NONE;
    },
    currentColors() {
      return getCurrentColorSet();
    },
    commitment() {
      return this.projectDetails.commitment;
    },
    trendingCommitment() {
      if (this.notTracked) return "-";
      return this.projectDetails.forecastTrendingMd;
    },
    diffTrendingMd() {
      if (this.notTracked) return "-";
      return this.trendingCommitment - this.commitment;
    },
    diffPercentTrendingMd() {
      if (this.notTracked) return "-";
      return this.diffTrendingMd / this.commitment;
    },
    riskTrendingMd() {
      if (this.notTracked) return "-";
      return roundToTwoDecimals(this.trendingCommitment / this.commitment);
    },
    planningCommitment() {
      if (this.notTracked) return "-";
      return this.projectDetails.forecastPlanningMd;
    },
    diffPlanningMd() {
      if (this.notTracked) return "-";
      return this.planningCommitment - this.commitment;
    },
    diffPercentPlanningMd() {
      if (this.notTracked) return "-";
      return this.diffPlanningMd / this.commitment;
    },
    riskPlanningMd() {
      if (this.notTracked) return "-";
      return roundToTwoDecimals(this.planningCommitment / this.commitment);
    },

    budget() {
      return this.projectDetails.budget;
    },
    trendingBudget() {
      if (this.notTracked) return "-";
      return this.projectDetails.forecastTrendingBudget;
    },
    diffTrendingBudget() {
      if (this.notTracked) return "-";
      return this.trendingBudget - this.budget;
    },
    diffPercentTrendingBudget() {
      if (this.notTracked) return "-";
      return this.diffTrendingBudget / this.budget;
    },
    riskTrendingBudget() {
      if (this.notTracked) return "-";
      return roundToTwoDecimals(this.trendingBudget / this.budget);
    },
    planningBudget() {
      if (this.notTracked) return "-";
      return this.projectDetails.forecastPlanningBudget;
    },
    diffPlanningBudget() {
      if (this.notTracked) return "-";
      return this.planningBudget - this.budget;
    },
    diffPercentPlanningBudget() {
      if (this.notTracked) return "-";
      return this.diffPlanningBudget / this.budget;
    },
    riskPlanningBudget() {
      if (this.notTracked) return "-";
      return roundToTwoDecimals(this.planningBudget / this.budget);
    },

    time() {
      return this.projectDetails.daysBetweenDue;
    },
    trendingTime() {
      if (this.notTracked) return "-";
      return this.projectDetails.forecastTrendingTime;
    },
    diffTrendingTime() {
      if (this.notTracked) return "-";
      return this.trendingTime - this.time;
    },
    diffPercentTrendingTime() {
      if (this.notTracked) return "-";
      return this.diffTrendingTime / this.time;
    },
    riskTrendingTime() {
      if (this.notTracked) return "-";
      return roundToTwoDecimals(this.trendingTime / this.time);
    },
    planningTime() {
      if (this.notTracked) return "-";
      return this.projectDetails.forecastPlanningTime;
    },
    diffPlanningTime() {
      if (this.notTracked) return "-";
      return this.planningTime - this.time;
    },
    diffPercentPlanningTime() {
      if (this.notTracked) return "-";
      return this.diffPlanningTime / this.time;
    },
    riskPlanningTime() {
      if (this.notTracked) return "-";
      return roundToTwoDecimals(this.planningTime / this.time);
    },

    dueDate() {
      return new Date(this.projectDetails.dueDate);
    },
    trendingDueDate() {
      if (this.notTracked) return "-";
      return new Date(this.projectData.startDate).addDaysSkippingWeekends(
        this.trendingTime
      );
    },
    planningDueDate() {
      if (this.notTracked) return "-";
      return new Date(this.projectData.startDate).addDaysSkippingWeekends(
        this.planningTime
      );
    },

    income() {
      return this.projectDetails.expectedIncome;
    },
    trendingIncome() {
      if (this.notTracked) return "-";
      return this.income;
    },
    diffTrendingIncome() {
      if (this.notTracked) return "-";
      return this.trendingIncome - this.income;
    },
    diffPercentTrendingIncome() {
      if (this.notTracked) return "-";
      return this.diffTrendingIncome / this.income;
    },
    planningIncome() {
      if (this.notTracked) return "-";
      return this.income;
    },
    diffPlanningIncome() {
      if (this.notTracked) return "-";
      return this.planningIncome - this.income;
    },
    diffPercentPlanningIncome() {
      if (this.notTracked) return "-";
      return this.diffPlanningIncome / this.income;
    },

    profit() {
      return this.income - this.budget;
    },
    trendingProfit() {
      if (this.notTracked) return "-";
      return this.trendingIncome - this.trendingBudget;
    },
    diffTrendingProfit() {
      if (this.notTracked) return "-";
      return this.trendingProfit - this.profit;
    },
    diffPercentTrendingProfit() {
      if (this.notTracked) return "-";
      return this.diffTrendingProfit / this.profit;
    },
    planningProfit() {
      if (this.notTracked) return "-";
      return this.planningIncome - this.planningBudget;
    },
    diffPlanningProfit() {
      if (this.notTracked) return "-";
      return this.planningProfit - this.profit;
    },
    diffPercentPlanningProfit() {
      if (this.notTracked) return "-";
      return this.diffPlanningProfit / this.profit;
    },

    profitPercent() {
      return this.profit / this.income;
    },
    trendingProfitPercent() {
      if (this.notTracked) return "-";
      return this.trendingProfit / this.trendingIncome;
    },
    diffTrendingProfitPercent() {
      if (this.notTracked) return "-";
      return this.trendingProfitPercent - this.profitPercent;
    },
    planningProfitPercent() {
      if (this.notTracked) return "-";
      return this.planningProfit / this.planningIncome;
    },
    diffPlanningProfitPercent() {
      if (this.notTracked) return "-";
      return this.planningProfitPercent - this.profitPercent;
    },
  },
  data() {
    return {
      formatDate,
      percentify,
      moneyify,
      workdayify,
      numberify,
    };
  },
  methods: {
    validPercentage(val) {
      if (isFinite(val)) {
        return percentify(val);
      }
      return "-";
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/assets/scss/colors.scss";

.is-vertical-align-middle {
  vertical-align: middle !important;
}

.is-rounded {
  border-radius: 8px;
}

.bg-lightgray {
  background: $grey-lighter;
}

.blank-row-big {
  height: 24px !important;
}

.blank-row-big > td {
  padding: 0;
}

.blank-row-small {
  height: 4px !important;
  line-height: 0;

  &-td {
    padding: 0 !important;
    border-bottom: 1px dashed #cad3de !important;
    border-left: none !important;
    border-right: none !important;
    border-top: none !important;
    border-radius: 0 !important;

    &:first-of-type {
      border-radius: 0 !important;
      border-left: 0 !important;
    }

    &:last-of-type {
      border-radius: 0 !important;
      border-right: 0 !important;
    }
  }
}

.whole-table th,
.whole-table td {
  color: $custom-dark-blue;
}

.plan-row > td {
  background: $grey-lightest;
  color: $custom-dark-blue !important;
}

.forecast-trend-row > td {
  // background: $forecast-secondary;
  color: $custom-dark-blue !important;
}

.forecast-trend-row > td:first-child {
  border-left: 5px solid $forecast-tertiary !important;
}

.forecast-plan-row > td {
  // background: $forecast-tertiary;
  color: $custom-dark-blue;
}

.forecast-plan-row > td:first-child {
  border-left: 5px solid $forecast-primary !important;
}

.plan-row > td:first-child,
.forecast-trend-row > td:first-child,
.forecast-plan-row > td:first-child {
  border-bottom-left-radius: 8px;
  border-top-left-radius: 8px;
}
.plan-row > td:last-child,
.forecast-trend-row > td:last-child,
.forecast-plan-row > td:last-child {
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
}

.rpi-tag {
  font-weight: 500;
}

.icon-cell {
  width: 28px;
  padding: 0 !important;
  vertical-align: middle;
}

.diff-icon {
  // display: flex;
  display: none;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  color: $white;
  margin: 2px;
  height: 2rem;
}
.diff-icon.is-fatal {
  background: $black;
}
.diff-icon.is-danger {
  background: $red;
}
.diff-icon.is-warning {
  background: $yellow;
}
.diff-icon.is-success {
  background: $green;
}

table {
  border-collapse: unset;
  border-spacing: 0 0.5rem;
}

th {
  border: none !important;
  font-size: 10px !important;
  font-weight: 400 !important;
  line-height: 22px !important;
}

tr {
  td:first-child {
    border-top-left-radius: 10px !important;
    border-bottom-left-radius: 10px !important;
    border-left: 1px solid #eceff5 !important;
    font-weight: 700 !important;
  }

  td:last-child {
    border-top-right-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
    border-right: 1px solid #eceff5 !important;
  }

  td {
    border-bottom: 1px solid #eceff5 !important;
    border-top: 1px solid #eceff5 !important;
    padding: 16px !important;
    font-weight: 500 !important;
    font-size: 14px !important;
  }
}

.table-percent {
  display: block;
  font-size: 12px;
  font-weight: 400;
}
</style>
