<template>
  <div class="mt-4">
    <b-modal
      v-model="isIssueModalOpen"
      aria-modal
      aria-role="dialog"
      has-modal-card
      v-on:cancel="issueEdit = null"
    >
      <div class="card p-4">
        <task-form
          :edited-task="issueEdit"
          :employees="employees"
          :is-billable="true"
          :project-id="projectId"
          :tigs="[activity]"
          project-type="SUPPORT"
          @task-submitted="handleIssueSubmit"
        ></task-form>
      </div>
    </b-modal>

    <div class="is-flex is-align-items-center mb-2 has-gap-1">
      <div class="is-flex is-justify-content-space-between">
        <div>
          <h4 class="title mb-0">
            {{
              $tf(
                "supportBillableDetails.title.task|A feladathoz tartozó taszkok"
              )
            }}
          </h4>
        </div>
        <b-button
          v-if="isSelfTrackingMode"
          rounded
          size="is-small"
          type="is-primary"
          @click="isIssueModalOpen = true"
          >{{ $tf("projectPage.tasks.list.actions.create|Jegy létrehozása") }}
        </b-button>
      </div>
      <external-issue-targeting-link
        v-if="!isSelfTrackingMode"
        :modelValue="activity.externalIssueTargeting"
      />
    </div>
    <b-table
      :key="tableKey"
      ref="expenseTable"
      :data="activity.activityTasks || []"
      :per-page="10"
      :show-detail-icon="false"
      aria-current-label="Aktuális lap"
      aria-next-label="Következő lap"
      aria-page-label="Lap"
      aria-previous-label="Előző lap"
      detail-key="identifier"
      detailed
    >
      <template #empty>
        <div class="has-text-centered">
          {{ $tf("projectpage.billableTasks.table.empty|Nincsenek jegyek") }}
        </div>
      </template>
      <b-table-column
        v-slot="props"
        centered
        field="issueKey"
        label="Jegy azonosító"
        sortable
      >
        <template v-if="!isSelfTrackingMode">
          <a :href="createTicketUrl(props.row.issueKey)" target="_blank">{{
            props.row.issueKey
          }}</a>
        </template>
        <template v-else>
          {{ props.row.issueKey }}
        </template>
      </b-table-column>
      <b-table-column
        v-slot="props"
        centered
        field="issueName"
        label="Jegy neve"
        sortable
      >
        {{ props.row.issueName }}
      </b-table-column>
      <b-table-column
        v-slot="props"
        centered
        field="loggedSeconds"
        label="Logolt munkaidő"
        sortable
      >
        <div class="is-flex is-align-items-center is-justify-content-center">
          <div v-if="props.row.hasLateTimeLog">
            <b-tooltip
              :label="
                $tf(
                  'projectpage.billableTasks.table.hasLateTimeLog|Feladat számlázási dátum utáni logbejegyzés található!'
                )
              "
              multilined
              size="is-small"
              type="is-warning"
            >
              <b-icon icon="triangle-exclamation" type="is-warning"></b-icon>
            </b-tooltip>
          </div>
          <div>
            {{ $filterSecondToShortWorkDayFormat(props.row.loggedSeconds) }}
          </div>
        </div>
      </b-table-column>
      <b-table-column
        v-slot="props"
        centered
        field="cost"
        label="Költség"
        sortable
      >
        {{ moneyify(props.row.cost) }}
      </b-table-column>
      <b-table-column
        v-if="isSelfTrackingMode"
        v-slot="props"
        :label="$tf('projectpage.billableTasks.table.actions|Műveletek')"
        centered
        field="actions"
      >
        <b-tooltip
          :label="
            $tf('projectpage.billableTasks.table.actions.edit|Szerkesztés')
          "
          type="is-light"
        >
          <clickable-icon
            icon="wrench"
            type="is-info"
            @click="editIssue(props.row)"
          />
        </b-tooltip>
        <b-tooltip
          :label="$tf('projectpage.billableTasks.table.actions.delete|Törlés')"
          type="is-light"
        >
          <clickable-icon
            icon="trash"
            type="is-danger"
            @click="deleteIssue(props.row.id)"
          />
        </b-tooltip>
      </b-table-column>
    </b-table>
  </div>
</template>

<script>
import ClickableIcon from "@/components/module/icon/ClickableIcon.vue";
import {
  createTicketUrl,
  moneyify,
  moneyifyAs,
  openDeleteConfirm,
} from "@/utils/util";
import { ISSUE_TRACKING_SYSTEM } from "@/utils/const";
import { mapGetters } from "vuex";
import TaskForm from "@/components/enterprise/project/form/TaskForm.vue";
import ExternalIssueTargetingLink from "@/components/enterprise/targeting/ExternalIssueTargetingLink.vue";

export default {
  name: "SupportBillableDetails",
  components: { ExternalIssueTargetingLink, TaskForm, ClickableIcon },
  emits: ["refresh"],
  props: {
    activity: {
      type: Object,
      required: true,
    },
    projectId: {
      type: Number,
      requried: true,
    },
    projectIdentifier: {
      type: String,
      requried: true,
    },
    employees: {
      type: Array,
      required: true,
    },
  },
  computed: {
    isSelfTrackingMode() {
      return this.issueTrackingSystem === ISSUE_TRACKING_SYSTEM.SELF.enum;
    },
    ...mapGetters({
      issues: "enterprise_core/issues",
      issueTrackingSystem: "uiConfigStore/getIssueTrackingSystem",
    }),
  },
  data() {
    return {
      tableKey: 0,
      issueEdit: null,
      isIssueModalOpen: false,
    };
  },
  methods: {
    moneyify,
    createTicketUrl,
    moneyifyAs,

    async handleIssueSubmit() {
      this.isIssueModalOpen = false;
      let params = new URLSearchParams();
      params.append("year", new Date().getFullYear());
      params.append("includeFixes", "true");
      params.append("includeLockups", "true");
      params.append("includeBillables", "true");
      this.$emit("refresh");
      this.issueEdit = null;
    },
    editIssue(task) {
      this.issueEdit = this.issues(this.projectId).find(
        (issue) => issue.id === task.issueId
      );
      this.issueEdit.budget = task.budget;
      this.issueEdit.income = task.income;
      this.issueEdit.taskId = task.id;
      this.isIssueModalOpen = true;
    },
    deleteIssue(id) {
      openDeleteConfirm(this.$buefy, async () => {
        await this.$store.dispatch("support_projects/deleteBillableTask", id);
        this.$emit("refresh");
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
