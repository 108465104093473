import StoreCore from "../../StoreCore";
import axios from "axios";

const urls = {
  config: "/api/env",
};

function flattenFeatureFlag(obj, parentKey = "") {
  if (!obj) return [];
  const result = [];

  for (const key in obj) {
    const fullPath = parentKey ? `${parentKey}/${key}` : key;
    if (Array.isArray(obj[key])) {
      obj[key].forEach((item) => {
        result.push(`${fullPath}/${item}`);
      });
    } else if (typeof obj[key] === "object" && obj[key] !== null) {
      result.push(...flattenFeatureFlag(obj[key], fullPath));
    } else {
      result.push(fullPath);
    }
  }

  return result;
}

const initState = () => {
  return {
    FEATURE_FLAGS: {},
  };
};

const storeCore = new StoreCore();
const actions = {
  fetch: async function ({ commit }) {
    try {
      const { data } = await axios.get(urls.config);
      commit("save", data);
    } catch (err) {
      console.error(err);
    }
  }.bind(storeCore),
  clearCache: async function ({}) {
    this.clearCoreCache();
  }.bind(storeCore),
};

const mutations = {
  save: (state, data) => {
    Object.keys(data).forEach((key) => {
      if (key === "FEATURE_FLAGS") {
        state[key] = flattenFeatureFlag(data[key]);
      } else {
        state[key] = data[key];
      }
    });
  },
};

const getters = {
  getField: (state) => (field) => {
    return state[field];
  },
  isFeatureFlagOn: (state) => (flag) => {
    try {
      return state.FEATURE_FLAGS?.includes(flag);
    } catch (err) {
      console.warn(err);
      return false;
    }
  },
};

export default {
  namespaced: true,
  state: initState(),
  mutations: mutations,
  actions: actions,
  getters: getters,
};
