<template>
  <section class="section">
    <div class="container">
      <h1 class="title">{{ $tf("forecastDashboard.title|Dashboard") }}</h1>
      <h2 class="subtitle">
        {{ $tf("forecastDashboard.title|Pénzügyi forecast dashboard") }}
      </h2>
      <b-field grouped grouped-multiline>
        <ForecastYearSelector
          v-model="year"
          @select="selectedYearObject = $event"
          :key="yearSelectorKey"
        />
        <b-field
          :label="$tf('forecastDashboard.filter.segment|Szegmens típusa')"
        >
          <multiselect-dropdown
            v-model="filter.segments"
            :items="segmentsWithUnassigned"
            append-to-body
            has-select-all-option
            identifier-field="id"
            name-field="name"
          />
        </b-field>
        <b-field :label="$tf('forecastDashboard.filter.client|Ügyfél')">
          <multiselect-dropdown
            v-model="filter.clients"
            :items="clientsWithUnassigned"
            append-to-body
            has-select-all-option
            identifier-field="id"
            name-field="name"
          />
        </b-field>
        <b-field :label="$tf('forecastDashboard.filter.client|Sales típus')">
          <multiselect-dropdown
            v-model="filter.salesType"
            :items="salesTypeWithUnassigned"
            append-to-body
            has-select-all-option
            identifier-field="id"
            name-field="name"
          />
        </b-field>
        <b-field
          :label="$tf('forecastDashboard.filter.client|Szolgáltatás típus')"
        >
          <multiselect-dropdown
            v-model="filter.serviceType"
            :items="serviceTypeWithUnassigned"
            append-to-body
            has-select-all-option
            identifier-field="id"
            name-field="name"
          />
        </b-field>
      </b-field>
      <b-field grouped>
        <b-field :label="$tf('forecastDashboard.quarter|Negyedév')">
          <b-select
            v-model="selectedQuarter"
            :items="FORECAST_YEAR_QUARTER"
            append-to-body
          >
            <option
              v-for="option in FORECAST_YEAR_QUARTER"
              :value="option"
              :key="'FILTER_Q_' + option"
            >
              {{ option }}
            </option>
          </b-select>
        </b-field>
        <b-field :label="$tf('forecastDashboard.scenario|Scenario')">
          <b-select v-model="scenario" :items="scenarios" append-to-body>
            <option
              v-for="option in scenarios"
              :value="option"
              :key="'FILTER_SC_' + option.id"
            >
              {{ option.name }}
            </option>
          </b-select>
        </b-field>
        <b-field :label="$tf('forecastDashboard.view|Nézet')">
          <b-select v-model="view" :items="views" append-to-body>
            <option
              v-for="option in views"
              :value="option"
              :key="'FILTER_VIEW_' + option.id"
            >
              {{ $tf(option.name) }}
            </option>
          </b-select>
        </b-field>
      </b-field>
    </div>
    <div class="container">
      <div class="is-flex is-justify-content-space-between">
        <div :class="$style['info-card']" class="card-shadowed">
          <div>
            {{
              moneyify(
                forecastIncome,
                CURRENCY_TIERS.LEVEL_TWO,
                true,
                true,
                true,
                2
              )
            }}
          </div>
          <div>
            {{ $tf("forecastDashboard.summary.forecastIncome|FC bevétel") }}
          </div>
        </div>
        <div :class="$style['info-card']" class="card-shadowed">
          <div>
            <p
              :class="{
                ['is-danger']: forecastPlanIncomeDiff < 0,
                ['is-success']: forecastPlanIncomeDiff >= 0,
              }"
            >
              <template v-if="forecastPlanIncomeDiff > 0">+</template>
              {{
                moneyify(
                  forecastPlanIncomeDiff,
                  CURRENCY_TIERS.LEVEL_TWO,
                  true,
                  true,
                  true,
                  2
                )
              }}
            </p>
          </div>
          <div>
            {{ $tf("forecastDashboard.summary.forecastIncomeDiff|vs TERV") }}
          </div>
        </div>
        <div :class="$style['info-card']" class="card-shadowed">
          <div>
            <p
              :class="{
                ['is-danger']: prevYearFullIncomeDiff < 0,
                ['is-success']: prevYearFullIncomeDiff >= 0,
              }"
            >
              <template v-if="prevYearFullIncomeDiff > 0">+</template>
              {{
                moneyify(
                  prevYearFullIncomeDiff,
                  CURRENCY_TIERS.LEVEL_TWO,
                  true,
                  true,
                  true,
                  2
                )
              }}
            </p>
          </div>
          <div>
            {{
              $tf("forecastDashboard.summary.forecastPrevYearDiff|vs előző év")
            }}
          </div>
        </div>
      </div>
      <div>
        <forecast-dashboard-table
          :data="sumData"
          :total-data="totalSumData"
          :value-column-label="$tf('forecastDashboard.sumTable.value|FC+Tény')"
        />
        <forecast-dashboard-table
          :data="planData"
          :total-data="totalPlanData"
          :value-column-label="$tf('forecastDashboard.planTable.value|Terv')"
        />
        <forecast-dashboard-table
          :data="versusData"
          :total-data="totalVersusData"
          :value-column-label="
            $tf('forecastDashboard.versusTable.value|FC vs Terv')
          "
        />
      </div>
      <h1 class="title mx-6">
        {{ $tf("forecastDashboard.versusTitle|FC vs Terv") }}
      </h1>
      <div
        class="mt-5 is-flex has-gap-3 is-justify-content-space-around"
        ref="chartContainer"
      >
        <div class="is-flex-1">
          <apex-chart
            :series="versusMixedChartSeries"
            :options="versusMixedChartOptions"
          />
        </div>
        <div class="is-flex-1">
          <apex-chart
            :series="versusBarChartSeries"
            :options="versusBarChartOptions"
          />
        </div>
        <div class="is-flex-1">
          <apex-chart
            :series="scenarioIncomeChartSeries"
            :options="scenarioIncomeChartOptions"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import { findForecastRule, getCurrentQuarter, moneyify } from "@/utils/util";
import LoadingMixin from "@/mixins/LoadingMixin";
import ForecastYearSelector from "@/components/forecast/ForecastYearSelector.vue";
import MultiselectDropdown from "@/components/MultiselectDropdown.vue";
import {
  FORECAST_BASE_DATA_TYPE,
  FORECAST_RULES,
  FORECAST_YEAR_QUARTER,
  CURRENCY_TIERS,
} from "@/utils/const";
import { camelCase, isEmpty } from "lodash";
import ForecastDashboardTable from "@/components/forecast/ForecastDashboardTable.vue";

export default {
  name: "ForecastDashboard",
  data() {
    return {
      loading: false,
      filter: {
        segments: [],
        clients: [],
        salesType: [],
        serviceType: [],
      },
      scenario: undefined,
      selectedQuarter: getCurrentQuarter(new Date()),
      views: [
        {
          id: "SEGMENT",
          field: "segment",
          fieldKey: "id",
          fieldValue: "name",
          name: "forecastDashboard.view.segment|Szegmens",
        },
        {
          id: "ACCOUNT",
          field: "account",
          fieldKey: "id",
          fieldValue: "name",
          name: "forecastDashboard.view.account|Ügyfél",
        },
        ...Object.keys(FORECAST_BASE_DATA_TYPE.ENUM).map((it) => {
          const rule = findForecastRule(it);
          return {
            id: it,
            field: camelCase(it.toLowerCase()),
            fieldKey: rule.get.key,
            fieldValue: rule.get.value,
            name: rule.label,
          };
        }),
      ],
      view: "SEGMENT",
      yearSelectorKey: 0,
      year: new Date().getFullYear(),
      selectedYearObject: {},
      FORECAST_YEAR_QUARTER,
      CURRENCY_TIERS,
    };
  },
  components: {
    ForecastDashboardTable,
    MultiselectDropdown,
    ForecastYearSelector,
  },
  watch: {
    year() {
      this.load();
    },
  },
  computed: {
    ...mapGetters({
      dashboardData: "forecast_segment/dashboard",
      chanceCategories: "forecast_chance_category/chanceCategories",
      selectedForecastYear: "session/selectedForecastYear",
      plans: "forecast_plan/plans",
      facts: "forecast_fact/facts",
      prevYearIncome: "forecast_fact/income",
      segments: "forecast_segment/allSegments",
      clients: "enterprise_clients/clients",
      currency: "uiConfigStore/getCurrencySymbol",
      scenarios: "forecast_scenario/scenarios",
      ...FORECAST_RULES.map((rule) => {
        return {
          [rule.enum]: rule.get.function,
        };
      }).reduce((acc, val) => Object.assign(acc, val)),
    }),
    salesTypeWithUnassigned() {
      const rule = findForecastRule(FORECAST_BASE_DATA_TYPE.ENUM.SALES_TYPE);
      let data = this[FORECAST_BASE_DATA_TYPE.ENUM.SALES_TYPE];
      if (rule.get.index) data = data(rule.get.index);
      if (!data) data = [];
      return [
        {
          id: null,
          name: this.$tf("forecast.unassignedFilter|Hozzárendelés nélkül"),
        },
        ...data,
      ];
    },
    serviceTypeWithUnassigned() {
      const rule = findForecastRule(FORECAST_BASE_DATA_TYPE.ENUM.SERVICE_TYPE);
      let data = this[FORECAST_BASE_DATA_TYPE.ENUM.SERVICE_TYPE];
      if (rule.get.index) data = data(rule.get.index);
      if (!data) data = [];
      return [
        {
          id: null,
          name: this.$tf("forecast.unassignedFilter|Hozzárendelés nélkül"),
        },
        ...data,
      ];
    },
    segmentsWithUnassigned() {
      return [
        {
          id: null,
          name: this.$tf("forecast.unassignedFilter|Hozzárendelés nélkül"),
        },
        ...this.segments,
      ];
    },
    clientsWithUnassigned() {
      return [
        {
          id: null,
          name: this.$tf("forecast.unassignedFilter|Hozzárendelés nélkül"),
        },
        ...this.clients,
      ];
    },
    pastQuarters() {
      const index = Object.keys(FORECAST_YEAR_QUARTER).indexOf(
        this.selectedQuarter
      );
      return Object.keys(FORECAST_YEAR_QUARTER).slice(0, index + 1);
    },

    filteredPlans() {
      return this.filterData(this.plans);
    },
    filteredFacts() {
      return this.filterData(this.facts);
    },
    forecasts() {
      return this.filteredPlans.filter((it) => !!it.parent?.id);
    },
    truePlans() {
      return this.filteredPlans.filter((it) => !it.parent?.id);
    },
    factData() {
      return this.mapToData(this.filteredFacts);
    },
    forecastData() {
      return this.mapToData(this.forecasts);
    },
    forecastIncome() {
      return this.getAllIncome(this.forecasts);
    },
    planIncome() {
      return this.getAllIncome(this.truePlans);
    },
    forecastPlanIncomeDiff() {
      return this.forecastIncome - this.planIncome;
    },
    prevYearFullIncome() {
      return Object.values(this.prevYearIncome).reduce(
        (acc, val) => (acc += val),
        0
      );
    },
    prevYearFullIncomeDiff() {
      return this.forecastIncome - this.prevYearFullIncome;
    },
    planData() {
      return this.mapToData(this.truePlans);
    },
    totalPlanData() {
      return this.mapToTotalData(this.planData);
    },
    sumData() {
      return this.mapToData([...this.forecasts, ...this.filteredFacts]);
    },
    totalSumData() {
      return this.mapToTotalData(this.sumData);
    },
    versusData() {
      return this.mapToData([...this.forecasts, ...this.truePlans], true);
    },
    totalVersusData() {
      return this.mapToTotalData(this.versusData);
    },

    versusMixedChartSeries() {
      const self = this;
      return [
        {
          name: this.$tf("forecastDashboard.versusMixedChart.sum|FC+TÉNY"),
          type: "column",
          data: Object.keys(FORECAST_YEAR_QUARTER).map((quarter) =>
            moneyify.call(
              self,
              this.totalSumData[quarter],
              CURRENCY_TIERS.LEVEL_TWO,
              false,
              false,
              false,
              2
            )
          ),
        },
        {
          name: this.$tf("forecastDashboard.versusMixedChart.plan|TERV"),
          type: "line",
          data: Object.keys(FORECAST_YEAR_QUARTER).map((quarter) =>
            moneyify.call(
              self,
              this.totalPlanData[quarter],
              CURRENCY_TIERS.LEVEL_TWO,
              false,
              false,
              false,
              2
            )
          ),
        },
        {
          name: this.$tf(
            "forecastDashboard.versusMixedChart.prevYear|TÉNY ELŐZŐ ÉV"
          ),
          type: "line",
          data: Object.keys(FORECAST_YEAR_QUARTER).map((quarter) =>
            moneyify.call(
              self,
              this.prevYearIncome[quarter],
              CURRENCY_TIERS.LEVEL_TWO,
              false,
              false,
              false,
              2
            )
          ),
        },
      ];
    },
    versusMixedChartOptions() {
      return {
        chart: {
          type: "line",
        },
        title: {
          text: this.$tf("forecastDashboard.versusChart.value|FC vs Terv"),
          style: {
            color: "var(--text)",
          },
        },
        dataLabels: {
          enabled: true,
        },
        legend: {
          labels: {
            colors: "var(--text)",
          },
        },
        labels: Object.keys(FORECAST_YEAR_QUARTER),
        xaxis: {
          type: "category",
        },
        yaxis: {
          labels: {
            style: { colors: "var(--text)" },
          },
        },
      };
    },
    versusBarChartSeries() {
      const data = this.versusData.map((it) => it.income.total);
      const self = this;
      return [
        {
          data: [
            ...data.map((it) =>
              moneyify.call(
                self,
                it,
                CURRENCY_TIERS.LEVEL_TWO,
                false,
                false,
                false,
                2
              )
            ),
            moneyify.call(
              self,
              data.reduce((acc, val) => acc + val, 0),
              CURRENCY_TIERS.LEVEL_TWO,
              false,
              false,
              false,
              2
            ),
          ],
        },
      ];
    },
    versusBarChartOptions() {
      return {
        chart: {
          type: "bar",
        },
        title: {
          text: this.$tf("forecastDashboard.versusChart.value|FC vs Terv"),
          style: {
            color: "var(--text)",
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: true,
          },
        },
        dataLabels: {
          enabled: true,
        },
        legend: {
          labels: {
            colors: "var(--text)",
          },
        },
        xaxis: {
          categories: [
            ...this.versusData.map((it) => it.value),
            this.$tf("forecastDashboard.versusChart.total|TOTAL"),
          ],
        },
        yaxis: {
          labels: {
            style: { colors: "var(--text)" },
          },
        },
      };
    },
    scenarioIncomeChartSeries() {
      const self = this;
      return [
        {
          data: [
            moneyify.call(
              self,
              self.getAllIncome(self.filteredPlans),
              CURRENCY_TIERS.LEVEL_TWO,
              false,
              false,
              false,
              2
            ),
            ...self.scenarios.map((it) =>
              moneyify.call(
                self,
                self.getIncomeForScenario(self.filteredPlans, it),
                CURRENCY_TIERS.LEVEL_TWO,
                false,
                false,
                false,
                2
              )
            ),
          ],
        },
      ];
    },
    scenarioIncomeChartOptions() {
      return {
        chart: {
          type: "bar",
        },
        title: {
          text: this.$tf("forecastDashboard.scenarioIncomeChart.title|Bevétel"),
          style: {
            color: "var(--text)",
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: true,
          },
        },
        dataLabels: {
          enabled: true,
        },
        legend: {
          labels: {
            colors: "var(--text)",
          },
        },
        xaxis: {
          categories: [
            this.$tf("forecastDashboard.scenarioIncomeChart.plan|TERV"),
            ...this.scenarios.map((it) => it.name),
          ],
        },
        yaxis: {
          labels: {
            style: { colors: "var(--text)" },
          },
        },
      };
    },
    scenarioPlanDiffChartSeries() {
      const self = this;
      const base = this.getAllIncome(this.filteredPlans);
      return [
        {
          data: self.scenarios.map((it) =>
            moneyify.call(
              self,
              self.getIncomeForScenario(self.filteredPlans, it) - base,
              CURRENCY_TIERS.LEVEL_TWO,
              false,
              false,
              false,
              2
            )
          ),
        },
      ];
    },
    scenarioPlanDiffChartOptions() {
      return {
        chart: {
          type: "bar",
        },
        title: {
          text: this.$tf("forecastDashboard.scenarioIncomeChart.title|Bevétel"),
          style: {
            color: "var(--text)",
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: true,
          },
        },
        dataLabels: {
          enabled: true,
        },
        legend: {
          labels: {
            colors: "var(--text)",
          },
        },
        xaxis: {
          categories: this.scenarios.map((it) => it.name),
        },
        yaxis: {
          labels: {
            style: { colors: "var(--text)" },
          },
        },
      };
    },
  },
  methods: {
    moneyify,
    getIncomeForScenario(data, scenario) {
      const chanceCategories = scenario.chanceCategories.map((it) => it.id);
      return data.reduce((acc, val) => {
        Object.keys(FORECAST_YEAR_QUARTER).forEach((quarter) => {
          if (chanceCategories.includes(val[`chanceCategory${quarter}`]?.id))
            acc += val[`income${quarter}`];
        });
        return acc;
      }, 0);
    },
    getAllIncome(data) {
      return data.reduce((acc, val) => {
        Object.keys(FORECAST_YEAR_QUARTER).forEach(
          (quarter) => (acc += val[`income${quarter}`])
        );
        return acc;
      }, 0);
    },
    mapToData(data, subtract = false) {
      return data
        .map((it) => {
          let field = it[this.view.field];
          return {
            key: field ? field[this.view.fieldKey] : "null",
            type: it.parent?.id ? "FC" : "PLAN",
            value: field
              ? field[this.view.fieldValue]
              : this.$tf("forecastDashboard.noKey|Nincs hozzárendelve"),
            income: {
              ...Object.keys(FORECAST_YEAR_QUARTER)
                .map((quarter) => {
                  let num =
                    subtract && !it.parent?.id
                      ? 0 - it[`income${quarter}`]
                      : it[`income${quarter}`];
                  if (
                    !this.scenario?.chanceCategories
                      ?.map((sc) => sc.id)
                      .includes(it[`chanceCategory${quarter}`]?.id)
                  )
                    num = 0;
                  return {
                    [quarter]: num,
                  };
                })
                .reduce((acc, val) => Object.assign(acc, val), {}),
            },
          };
        })
        .filter((it) => it)
        .reduce((acc, val) => {
          const found = acc.find((it) => it.key === val.key);
          if (found) {
            Object.keys(found.income).forEach((it) => {
              found.income[it] += val.income[it];
            });
          } else {
            acc.push(val);
          }
          return acc;
        }, [])
        .map((it) => {
          it.income.total = 0;
          it.income.ytd = 0;
          Object.keys(FORECAST_YEAR_QUARTER).forEach((quarter) => {
            it.income.total += it.income[quarter];
            if (this.pastQuarters.includes(quarter)) {
              it.income.ytd += it.income[quarter];
            }
          });
          return it;
        })
        .sort((a, b) => {
          if (a.key === "null") return 1;
          if (b.key === "null") return -1;
          return a.key.toString().localeCompare(b.key.toString());
        });
    },
    mapToTotalData(data) {
      return data.reduce(
        (acc, val) => {
          acc.ytd += val.income.ytd;
          acc.total += val.income.total;
          Object.keys(FORECAST_YEAR_QUARTER).forEach(
            (quarter) => (acc[quarter] += val.income[quarter])
          );
          return acc;
        },
        {
          ytd: 0,
          total: 0,
          ...Object.keys(FORECAST_YEAR_QUARTER)
            .map((quarter) => {
              return {
                [quarter]: 0,
              };
            })
            .reduce((acc, val) => Object.assign(acc, val), {}),
        }
      );
    },
    filterData(data) {
      const salesTypeRule = findForecastRule(
        FORECAST_BASE_DATA_TYPE.ENUM.SALES_TYPE
      );
      const serviceTypeRule = findForecastRule(
        FORECAST_BASE_DATA_TYPE.ENUM.SERVICE_TYPE
      );
      return data.filter(
        (it) =>
          !(
            (!isEmpty(this.filter.segments) &&
              !this.filter.segments.some(
                (segment) =>
                  (segment === null && !it.segment?.id) ||
                  it.segment?.id === segment
              )) ||
            (!isEmpty(this.filter.clients) &&
              !this.filter.clients.some(
                (client) =>
                  (client === null && !it.account?.id) ||
                  it.account?.id === client
              )) ||
            (!isEmpty(this.filter.salesType) &&
              !this.filter.salesType.some(
                (salesType) =>
                  (salesType === null &&
                    !it[camelCase(salesTypeRule.enum.toLowerCase())]) ||
                  (it[camelCase(salesTypeRule.enum.toLowerCase())] &&
                    it[camelCase(salesTypeRule.enum.toLowerCase())][
                      salesTypeRule.get.key
                    ] === salesType)
              )) ||
            (!isEmpty(this.filter.serviceType) &&
              !this.filter.serviceType.some(
                (serviceType) =>
                  (serviceType === null &&
                    !it[camelCase(serviceTypeRule.enum.toLowerCase())]) ||
                  (it[camelCase(serviceTypeRule.enum.toLowerCase())] &&
                    it[camelCase(serviceTypeRule.enum.toLowerCase())][
                      serviceTypeRule.get.key
                    ] === serviceType)
              ))
          )
      );
    },
    async init() {
      await this.doStartLoading();
      this.view = this.views[0];
      const promises = [];

      const salesTypeRule = findForecastRule(
        FORECAST_BASE_DATA_TYPE.ENUM.SALES_TYPE
      );
      const serviceTypeRule = findForecastRule(
        FORECAST_BASE_DATA_TYPE.ENUM.SERVICE_TYPE
      );
      promises.push(
        this.$store.dispatch(
          salesTypeRule.fetch.function,
          salesTypeRule.fetch.params
        )
      );
      promises.push(
        this.$store.dispatch(
          serviceTypeRule.fetch.function,
          serviceTypeRule.fetch.params
        )
      );
      promises.push(this.$store.dispatch("forecast_segment/fetchAllSegments"));
      promises.push(this.$store.dispatch("enterprise_clients/fetchClients"));
      promises.push(this.$store.dispatch("forecast_scenario/fetchScenarios"));
      await Promise.all(promises);
      this.scenario = this.scenarios.find((it) => it.isDefault);
      if (!this.scenario) this.scenario = this.scenarios[0];
    },
    async load(force) {
      await this.doStartLoading();

      let params = new URLSearchParams();
      params.append("year", this.year);
      let requestParams = { params: params };

      await this.$store.dispatch("forecast_plan/fetchPlans", {
        force,
        params: requestParams,
      });
      await this.$store.dispatch("forecast_fact/fetchFacts", {
        force,
        params: requestParams,
      });

      let prevYearParams = new URLSearchParams();
      prevYearParams.append("year", this.year - 1);
      let prevYearRequestParams = { params: prevYearParams };
      await this.$store.dispatch("forecast_fact/getIncome", {
        force,
        params: prevYearRequestParams,
      });

      await this.doFinishLoading();
    },
  },
  mixins: [LoadingMixin],
  async mounted() {
    await this.init();
    await this.load();
  },
};
</script>
<style scoped module lang="scss">
@import "~@/assets/scss/colors.scss";

.info-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  padding: 8px;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;

  :first-child {
    font-size: 36px;
    font-weight: 700;
  }

  :nth-child(2) {
    font-size: 14px;
    color: $grey;
  }
}
</style>
