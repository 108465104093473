<template>
  <seating-arrangement-canvas is-read-only></seating-arrangement-canvas>
</template>

<script>
import SeatingArrangementCanvas from "./SeatingArrangementCanvas";

export default {
  name: "SeatingArrangementCanvasReadOnly",
  components: { SeatingArrangementCanvas },
};
</script>

<style scoped></style>
