<template>
  <div class="modal-card">
    <section class="modal-card-body">
      <h3 class="heading mb-2">
        <span v-if="isLead">
          <b-icon icon="lead" type="is-title" class="mr-1" />
          {{ $tf("teamMemberSelector.leadSelect.title|Vezetők kiválasztása") }}
        </span>
        <span v-else>
          <b-icon icon="team-members" type="is-title" class="mr-2" />
          {{
            $tf(
              "teamMemberSelector.memberSelect.title|Csapattagok kiválasztása"
            )
          }}
        </span>
      </h3>

      <h2 class="subtitle">
        {{
          $tf(
            "teamMemberSelector.help|A keresés név és pozíció alapján is lehetséges."
          )
        }}
      </h2>

      <b-field grouped>
        <b-field
          :label="$tf('teamMemberSelector.collegaues|Kollégák')"
          label-position="on-border"
          class="is-long mb-5"
        >
          <b-input
            v-model="textFilter"
            :placeholder="
              $tf('teamMemberSelector.collegaues.placeholder|Név, titulus')
            "
            icon="search"
          />
        </b-field>

        <b-field
          :label="$tf('teamMemberSelector.leadRole|Szerep a csapaton belül')"
          label-position="on-border"
          v-if="isLead"
        >
          <b-select v-model="leadType" @update:modelValue="onLeadTypeSelect">
            <option
              v-for="(key, value) in TEAM_LEAD_ROLE.LABEL"
              :key="value"
              :value="value"
            >
              {{ $tf(key) }}
            </option>
          </b-select>
        </b-field>
      </b-field>

      <h2 class="subtitle">
        {{
          $tf("teamMemberSelector.filterCompentencies|Szűrés kompetenciákra")
        }}
      </h2>

      <div class="block">
        <b-checkbox
          v-for="competency in competencies"
          v-model="selectedCompetencies"
          :native-value="competency.id"
          :key="competency.id"
          class="mr-5"
        >
          {{ competency.name }}
        </b-checkbox>
      </div>

      <b-table
        :data="filtered"
        paginated
        per-page="5"
        height="350"
        checkable
        v-model:checked-rows="selected"
        ref="dataTable"
      >
        <b-table-column
          field="name"
          :label="$tf('teamMemberSelector.table.name|Kolléga neve')"
          sortable
          v-slot="props"
        >
          {{ props.row.name }}
        </b-table-column>
        <b-table-column
          field="title"
          :label="$tf('teamMemberSelector.table.title|Titulus / Terület')"
          sortable
          v-slot="props"
        >
          <label class="title-label">{{ props.row.title }}</label> <br />
          {{ props.row.department }}
        </b-table-column>
        <b-table-column
          :label="$tf('teamMemberSelector.table.competencies|Kompetenciák')"
          sortable
          v-slot="props"
        >
          <label
            class="competency-label"
            v-for="competency in props.row.competencies"
            :key="competency.id"
          >
            {{ competency.name }}
          </label>
        </b-table-column>

        <template #bottom-left>
          {{
            $tf(
              "teamMemberSelector.table.shownCount|Megjelenítve: {shown} / {all} db",
              {
                shown: $refs.dataTable ? $refs.dataTable.visibleData.length : 0,
                all: filtered.length,
              }
            )
          }}
          <br />
          {{
            $tf(
              "teamMemberSelector.table.selectedCount|Kijelölve: {selected} db",
              { selected: selected.length }
            )
          }}
        </template>
      </b-table>

      <b-field grouped class="mt-5 is-pulled-right">
        <b-button @click="cancel" class="mr-4">
          {{ $tf("teamMemberSelector.cancel|Mégse") }}
        </b-button>
        <b-button icon-left="save" type="is-info" @click="save">
          {{ $tf("teamMemberSelector.save|Mentés") }}
        </b-button>
      </b-field>
    </section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { TEAM_LEAD_ROLE, USER_INDICATOR_TYPE_ENUM } from "@/utils/const";
import { getCurrentInstance } from "vue";

export default {
  name: "TeamMemberSelector",
  components: {},
  setup: () => {
    const instance = getCurrentInstance();
    return {
      instance: instance,
    };
  },
  props: {
    members: {
      type: Array,
    },
    isLead: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.setSelectables();
    this.competencies = this.indicators.filter(
      (indicator) => indicator.type === USER_INDICATOR_TYPE_ENUM.COMPETENCY
    );
  },
  data() {
    return {
      TEAM_LEAD_ROLE,
      selectable: [],
      filtered: [],
      selected: [],
      competencies: [],
      selectedCompetencies: [],
      textFilter: "",
      leadType: TEAM_LEAD_ROLE.ENUM.FINANCIAL,
    };
  },
  watch: {
    textFilter() {
      this.doFilter();
    },
    selectedCompetencies() {
      this.doFilter();
    },
  },
  computed: {
    ...mapGetters({
      employees: "employee/employees",
      indicators: "user_indicator/indicators",
    }),
  },
  methods: {
    cancel() {
      this.instance.emit("close");
    },
    save() {
      this.$emit("save", this.selected, this.leadType);
    },
    setSelectables() {
      this.selectable = this.employees.filter(
        (employee) =>
          !this.members.some((member) => {
            let idMatch = member.employeeId === employee.id;
            if (this.isLead) {
              return idMatch && member.role === this.leadType;
            }
            return idMatch;
          })
      );

      this.doFilter();
    },
    onLeadTypeSelect() {
      this.setSelectables();
      this.selected = this.selected.filter((selected) =>
        this.selectable.some((employee) => employee.id === selected.id)
      );
    },
    doFilter() {
      this.filtered = this.selectable;

      let text = this.textFilter.toLowerCase().trim();
      if (text) {
        this.filtered = this.filtered.filter(
          (employee) =>
            employee.name?.toLowerCase().includes(text) ||
            employee.title?.toLowerCase().includes(text)
        );
      }

      if (this.selectedCompetencies.length > 0) {
        this.filtered = this.filtered.filter((employee) =>
          this.selectedCompetencies.every((competencyId) =>
            employee.competencies?.some(
              (employeeCompetency) => employeeCompetency.id === competencyId
            )
          )
        );
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../../assets/scss/colors";

.title-label {
  font-weight: 500;
}

.competency-label {
  color: $custom-grayer-blue;
  margin-right: 0.5rem;
  &:before {
    content: "•";
  }
}

.modal-card {
  overflow: scroll;
}
</style>
