<template>
  <div class="synchronization container">
    <h3 class="title">
      Adatok szinkronizálása
      <b-tooltip
        :auto-close="['outside', 'escape']"
        :triggers="['click']"
        type="is-info"
      >
        <template v-slot:content>
          <div class="content tooltip">
            <p>Erőltetett frissítésre akkor lehet szükség, ha:</p>
            <ul>
              <li>Reggel óta logolt valaki, és fontos lenne rögtön látni</li>
              <li>
                Reggel óta készültek új / záródtak le jegyek és fontos lenne ezt
                rögtön látni
              </li>
            </ul>
            <p>
              Amennyiben egy olyan ember logolt reggel óta a projektre aki addig
              sosem, úgy ezek frissítésére a lenti gomb hatástalan lesz - ekkor
              a kérdéses dolgozó Timesheetjeinél frissíts!
            </p>
            <br />
            <p>
              A Jirával történő szinkronizáció minden hajnalban automatikusan
              lezajlik, a balra jelzett frissítési időpont valós!
              <br />
              A frissítés funkciót akkor használd ha a jelzett időpont óta
              történt módosításokra vagy kíváncsi.
              <br />
              A szinkronizáció a Projekt méretétől (jegyei, rajta dolgozók
              számától) függően hosszabb időt is igénybe vehet.
            </p>
          </div>
        </template>
        <b-icon icon="info-circle" />
      </b-tooltip>
    </h3>
    <div
      class="is-flex content is-justify-content-space-between is-align-items-center"
    >
      <div>
        <p>
          {{
            $tf(
              "syncData.refresh.lastTime|A látható adatok szinkronizálásának időpontja:"
            )
          }}
          <span>{{
            !!projectDetails.issueStatusUpdate
              ? formatDateTime(new Date(projectDetails.issueStatusUpdate))
              : $tf(
                  "syncData.refresh.neverBefore|Még sosem volt szinkronizálva"
                )
          }}</span>
        </p>
      </div>
      <div class="">
        <b-button
          type="is-info"
          @click="refreshProject"
          :loading="refreshing"
          :disabled="refreshing"
        >
          {{ $tf("syncData.refresh.button|Frissíteni szeretnék") }}
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { formatDateTime } from "@/utils/util";

export default {
  name: "SyncData",
  components: {},
  props: {
    projectDetails: {
      type: Object,
      required: true,
    },
    projectId: {
      type: Number,
      required: true,
    },
    projectType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      formatDateTime,
      refreshing: false,
    };
  },
  methods: {
    async refreshProject() {
      this.refreshing = true;
      await this.$store.dispatch("enterprise_projects/refresh", {
        projectType: this.projectType,
        id: this.projectId,
      });
      this.$emit("refresh");
      this.refreshing = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/colors.scss";

.synchronization {
  padding: 24px;

  .is-flex.content {
    background-color: $grey-lightest;
    padding: 16px;

    div {
      p {
        font-family: "Roboto", serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 130%;
        font-feature-settings: "liga" off;
        color: $custom-grayer-blue;
      }

      span {
        font-family: "Roboto", serif;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
        color: $custom-dark-blue;
      }
    }
  }
}
</style>
