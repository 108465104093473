<template>
  <div>
    <div v-if="loading || !filtersLoaded">
      <LoadingSkeleton></LoadingSkeleton>
    </div>
    <div v-else>
      <div style="overflow-x: auto; padding-bottom: 24px">
        <table class="outsource-list-table">
          <tr class="table-header-months-row">
            <th>
              <div>
                <b-dropdown aria-role="list" v-model="selectedYear">
                  <template #trigger="{ active }">
                    <b-button
                      :label="'Év: ' + selectedYear"
                      type="is-info"
                      :icon-right="active ? 'chevron-up' : 'chevron-down'"
                    />
                  </template>

                  <b-dropdown-item
                    v-for="year in years"
                    :key="year"
                    :value="year"
                    aria-role="listitem"
                    >{{ year }}</b-dropdown-item
                  >
                </b-dropdown>
              </div>
            </th>
            <th></th>
            <th style="width: 16px"></th>
            <th class="table-header-month-cell" v-for="i in 12" :key="'a' + i">
              {{
                $tf(SHORT_MONTH_NAMES[i - 1])
                  .charAt(0)
                  .toUpperCase() + $tf(SHORT_MONTH_NAMES[i - 1]).slice(1)
              }}
            </th>
            <th class="has-text-centered">Összesítés</th>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td v-for="i in 12" :key="i">
              <div
                v-if="now.getMonth() + 1 > i"
                class="month-type-bubble bubble-green"
              >
                Tény
              </div>
              <div
                v-else-if="now.getMonth() + 1 < i"
                class="month-type-bubble bubble-blue"
              >
                FC
              </div>
              <div v-else class="month-type-bubble bubble-red">Folyamatban</div>
            </td>
            <td class="has-text-centered p-0 mr-2">
              <div>
                <b-dropdown aria-role="list" position="is-bottom-left">
                  <template #trigger="{ active }">
                    <b-button
                      :label="$tf('outsourceProjectList.summary|Összesítés')"
                      type="is-info"
                      :icon-right="active ? 'chevron-up' : 'chevron-down'"
                    />
                  </template>

                  <b-dropdown-item aria-role="listitem" class="px-5">
                    <b-field>
                      <b-slider
                        v-model="sumRange"
                        :min="1"
                        :max="12"
                        :step="1"
                        ticks
                      >
                        <b-slider-tick :value="sumRange[0]">{{
                          $tf(SHORT_MONTH_NAMES[sumRange[0] - 1])
                        }}</b-slider-tick>
                        <b-slider-tick :value="sumRange[1]">{{
                          $tf(SHORT_MONTH_NAMES[sumRange[1] - 1])
                        }}</b-slider-tick>
                      </b-slider>
                    </b-field>
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </td>
          </tr>
          <tr class="client-section-first-row">
            <td class="sum-cell" rowspan="3">Összesen</td>
            <td>Bevétel</td>
            <td></td>
            <td v-for="i in 12" :key="'d' + i">
              {{ moneyify(sumForMonth(i).income) }}
            </td>
            <td>{{ moneyify(sumTotal.income) }}</td>
          </tr>
          <tr class="client-section-second-row">
            <td rowspan="2">Profit</td>
            <td></td>
            <td v-for="i in 12" :key="'e' + i">
              {{ moneyify(sumForMonth(i).profit) }}
            </td>
            <td>
              {{ moneyify(sumTotal.profit) }}
            </td>
          </tr>
          <tr class="client-section-third-row">
            <td></td>
            <td v-for="i in 12" :key="'f' + i">
              {{ percentify(sumForMonth(i).profitPercentage) }}
            </td>
            <td>{{ percentify(sumTotal.profitPercentage) }}</td>
          </tr>
          <template v-for="project in projects.items" :key="project.id">
            <tr class="separator-row">
              <td colspan="16"></td>
            </tr>
            <tr
              class="client-section-first-row cursor-pointer"
              @click="openProjectPage(project.identifier)"
            >
              <td rowspan="3">
                <p>
                  {{ project.name }}
                </p>
                <p>
                  <UserInfo
                    :user="getEmployeeDetailsByName(project.leadName)"
                    displayMode="minimal"
                    tooltipPosition="right"
                  />
                </p>
              </td>
              <td>Bevétel</td>
              <td></td>
              <td
                v-for="(financialData, i) in monthlyFinancials(project)"
                :key="'d' + i"
              >
                {{ moneyify(financialData.income) }}
              </td>
              <td>{{ moneyify(sumForProject(project).income) }}</td>
            </tr>
            <tr
              class="client-section-second-row cursor-pointer"
              @click="openProjectPage(project.identifier)"
            >
              <td rowspan="2">Profit</td>
              <td></td>
              <td
                v-for="(financialData, i) in monthlyFinancials(project)"
                :key="'e' + i"
              >
                {{ moneyify(financialData.profit) }}
              </td>
              <td>
                {{ moneyify(sumForProject(project).profit) }}
              </td>
            </tr>
            <tr
              class="client-section-third-row cursor-pointer"
              @click="openProjectPage(project.identifier)"
            >
              <td></td>
              <td
                v-for="(financialData, i) in monthlyFinancials(project)"
                :key="'f' + i"
              >
                {{ percentify(financialData.profit / financialData.income) }}
              </td>
              <td>{{ percentify(sumForProject(project).profitPercentage) }}</td>
            </tr>
          </template>
        </table>
      </div>
      <b-pagination
        :total="pagination.value.totalCount"
        :modelValue="pagination.value.page + 1"
        :per-page="pagination.value.size"
        aria-next-label="Következő lap"
        aria-previous-label="Előző lap"
        aria-page-label="Lap"
        aria-current-label="Aktuális lap"
        @change="onPageChange"
        class="mt-4"
      />
    </div>
  </div>
</template>

<script>
import LoadingSkeleton from "@/components/module/loading/LoadingSkeleton.vue";
import { mapGetters } from "vuex";
import ProjectListMixin from "@/mixins/ProjectListMixin";
import SaveableObject from "@/objects/SaveableObject";
import debounce from "lodash.debounce";
import LocalStoreMixin from "@/mixins/LocalStoreMixin";
import UserInfo from "@/components/module/info/UserInfo.vue";

const LOCAL_KEY_PREFIX = "project_list_outsource_";

export default {
  name: "OutsourceProjectList",
  components: { LoadingSkeleton, UserInfo },
  mixins: [ProjectListMixin, LocalStoreMixin],
  data() {
    return {
      now: new Date(),
      selectedYear: new Date().getFullYear(),
      sumRange: [1, 12],
      loading: false,

      pagination: new SaveableObject(LOCAL_KEY_PREFIX + "pagination", {
        totalCount: 0,
        page: 0,
        size: 10,
      }),
      sort: new SaveableObject(LOCAL_KEY_PREFIX + "sort", {
        field: "name",
        order: "asc",
      }),
    };
  },
  computed: {
    ...mapGetters({
      projects: "outsource_projects/projectData",
      employees: "employee/currentEmployees",
    }),
    years() {
      let years = [];
      let year = new Date().getFullYear() + 1;
      while (year > 2019) {
        years.push(year);
        year--;
      }
      return years;
    },

    sumTotal() {
      let sumIncome = 0;
      let sumProfit = 0;
      this.projects?.items?.forEach((p) => {
        const sumForProject = this.sumForProject(p);
        sumIncome += sumForProject.income;
        sumProfit += sumForProject.profit;
      });
      return {
        income: sumIncome,
        profit: sumProfit,
        profitPercentage: sumProfit / sumIncome,
      };
    },
  },
  watch: {
    async selectedYear(newYear) {
      await this.fetchProjects(newYear);
    },
    filters: {
      handler: debounce(function () {
        this.pagination.resetToDefault();
        this.fetchProjects();
      }, 500),
      deep: true,
    },
  },
  methods: {
    resetFilters() {
      this.pagination.resetToDefault();

      this.$emit("reset-filters");
    },

    async fetchProjects(year, force) {
      this.loading = true;

      let requestParams = this.composeRequestParams();
      requestParams.params.set("year", year ?? this.selectedYear);

      const projects = await this.$store.dispatch(
        "outsource_projects/fetchProjectsForYear",
        {
          requestParams,
          force,
        }
      );
      this.pagination.value = projects?.pagination
        ? projects.pagination
        : {
            totalCount: 0,
            page: 0,
            size: 20,
          };
      this.pagination.save();
      this.loading = false;
    },
    onPageChange: function (page) {
      this.pagination.value.page = page - 1;
      this.pagination.save();
      this.fetchProjects();
    },
    monthlyFinancials(project) {
      let monthlyData = [];
      for (let i = 1; i <= 12; i++) {
        monthlyData.push(
          project.financials?.months?.find((f) => f.month === i) || {
            income: null,
            profit: null,
            month: i,
          }
        );
      }
      return monthlyData;
    },
    sumForProject(project) {
      let sumIncome = 0;
      let sumProfit = 0;
      this.monthlyFinancials(project).forEach((f) => {
        if (f.month >= this.sumRange[0] && f.month <= this.sumRange[1]) {
          sumIncome += f.income;
          sumProfit += f.profit;
        }
      });
      return {
        income: sumIncome,
        profit: sumProfit,
        profitPercentage: sumProfit / sumIncome,
      };
    },
    sumForMonth(month) {
      let sumIncome = 0;
      let sumProfit = 0;
      this.projects?.items?.forEach((p) => {
        const f = p.financials?.months?.find((fin) => fin.month === month);
        if (f) {
          sumIncome += f.income;
          sumProfit += f.profit;
        }
      });
      return {
        income: sumIncome,
        profit: sumProfit,
        profitPercentage: sumProfit / sumIncome,
      };
    },
    openProjectPage(identifier) {
      this.$router.push(`/projects/outsource/${identifier}`);
    },
    getEmployeeDetailsByName(name) {
      const employee = this.employees.find((emp) => emp.name === name);
      if (employee) {
        return {
          userId: employee.id,
          name: employee.name,
          avatar: employee.avatar,
          email: employee.email,
          phone: employee.phone,
        };
      } else {
        return null;
      }
    },
  },
  async mounted() {
    if (this.filtersLoaded) {
      await this.fetchProjects();
      // await this.$store.dispatch("employee/fetchColleagues");
    }
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/colors.scss";

.month-type-bubble {
  border-radius: 8px;
  text-align: center;
  padding: 4px;
  width: 120px;
  color: white;
  font-weight: 500;
  font-size: 0.9rem;
}

.bubble-green {
  background: #48c78e;
}

.bubble-red {
  background: #f14668;
}

.bubble-blue {
  background: #4e75d9;
}

.outsource-list-table {
  table-layout: fixed;
  border: none;
}

.outsource-list-table td,
.outsource-list-table th {
  border: none;
  vertical-align: middle;
}

.table-header-months-row .table-header-month-cell {
  background: $grey-light;
  color: $custom-dark-blue;
  text-align: center;
}

.table-header-months-row > td:not(:nth-child(3)),
.table-header-months-row > th:not(:nth-child(3)) {
  padding: 6px 4px;
  font-size: 0.9rem;
  width: 144px;
}

.table-header-months-row > th:nth-child(4) {
  border-radius: 8px 0 0 8px;
}

.table-header-months-row > th:nth-child(15) {
  border-radius: 0 8px 8px 0;
}

.client-section-first-row > td:nth-child(1),
.client-section-first-row > td:nth-child(2),
.client-section-second-row > td:nth-child(1) {
  background: $grey-light;
}

.client-section-first-row > td:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  text-align: center;
  font-weight: 500;
  border-right: 2px solid $grey-lighter;
}

.client-section-first-row > td:nth-child(2) {
  border-top-right-radius: 8px;
}

.client-section-second-row > td:first-child {
  border-bottom-right-radius: 8px;
}

.client-section-first-row > td:not(:first-child) {
  border-bottom: 2px solid $grey-lighter;
}

.client-section-first-row > td:not(:nth-child(1)):not(:nth-child(2)),
.client-section-second-row > td:not(:nth-child(1)),
.client-section-third-row > td:not(:nth-child(1)) {
  text-align: right;
  font-weight: 500;
  color: $custom-dark-blue;
}

.client-section-second-row > td:not(:nth-child(1)) {
  padding-bottom: 0;
}

.separator-row > td {
  height: 16px;
}

.sum-cell {
  background: #2f2e6b !important;
  color: white;
}
</style>
