<template>
  <div class="modal-card" style="width: auto">
    <div class="container" style="width: 42rem">
      <section class="modal-card-body">
        <h1 v-if="createMode" class="title">
          {{ $tf("tiers.costs.modal.newTitle|Új árszint felvétele") }}
        </h1>
        <h1 v-else class="title">
          {{ $tf("tiers.costs.modal.editTitle|Árszint módosítása") }}
        </h1>

        <form v-if="loaded">
          <b-field
            :label="$tf('tiers.costs.modal.tier|Szint')"
            class="mt-5"
            label-position="on-border"
          >
            <b-select
              v-model="editTierCost.tier.id"
              :placeholder="$tf('tiers.costs.modal.tier|Szint')"
              expanded
            >
              <option v-for="tier in tiers" :key="tier.id" :value="tier.id">
                {{ tier.tier }}
              </option>
            </b-select>
          </b-field>

          <vuelidated-input
            v-model="editTierCost.cost"
            :label="$tf('tiers.costs.modal.cost|Összeg')"
            :placeholder="$tf('tiers.costs.modal.cost|Összeg')"
            :validation-rule="v$.editTierCost.cost"
            class="mt-5"
            label-position="on-border"
          ></vuelidated-input>

          <b-field
            :label="$tf('tiers.costs.modal.validFrom|Érvényesség kezdete')"
            class="mt-5"
            label-position="on-border"
          >
            <f-datepicker
              v-model="editTierCost.date"
              :placeholder="
                $tf('tiers.costs.modal.validFrom.placeholder|Kezdeti dátum')
              "
              append-to-body
              icon="calendar-alt"
            >
            </f-datepicker>
          </b-field>

          <b-field class="mt-5 is-pulled-right">
            <b-button @click="close">
              {{ $tf("tiers.costs.modal.close|Mégse") }}
            </b-button>
            <b-button class="ml-3" type="is-info" @click="save">
              {{ $tf("tiers.costs.modal.save|Mentés") }}
            </b-button>
          </b-field>
        </form>

        <LoadingSkeleton v-else />
      </section>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { deepCopy, formatDate } from "@/utils/util";
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import VuelidatedInput from "@/components/module/input/VuelidatedInput";
import LoadingSkeleton from "../../../../components/module/loading/LoadingSkeleton";
import { getCurrentInstance } from "vue";
import FDatepicker from "@/components/module/input/FDatepicker.vue";

export default {
  name: "TiersCostsEditModal",
  components: { FDatepicker, LoadingSkeleton, VuelidatedInput },
  setup: () => {
    const instance = getCurrentInstance();
    return {
      v$: useVuelidate(),
      instance: instance,
    };
  },
  props: {
    tierCost: {
      type: Object,
      required: false,
    },
  },
  async mounted() {
    this.createMode = !this.tierCost;
    await this.$store.dispatch("enterprise_tiers/fetchTiers");

    if (this.tierCost) {
      this.editTierCost = deepCopy(this.tierCost);
      this.editTierCost.date = new Date(this.tierCost.since);
    } else {
      if (this.tiers && this.tiers.length > 0) {
        this.editTierCost.tier = deepCopy(this.tiers[0]);
      }
    }

    this.loaded = true;
  },
  data() {
    return {
      loaded: false,
      createMode: false,
      editTierCost: {
        tier: {},
        cost: undefined,
        date: new Date(),
      },
    };
  },
  validations: {
    editTierCost: {
      cost: {
        required,
      },
    },
  },
  computed: {
    ...mapGetters({
      tiers: "enterprise_tiers/tiers",
    }),
  },
  methods: {
    close() {
      this.instance.emit("close");
    },
    async save() {
      this.v$.editTierCost.$touch();
      if (!this.v$.editTierCost.$invalid) {
        let request = deepCopy(this.editTierCost);
        request.since = formatDate(this.editTierCost.date);

        if (this.createMode) {
          await this.$store.dispatch(
            "enterprise_tiers/createTierCost",
            request
          );
        } else {
          await this.$store.dispatch(
            "enterprise_tiers/modifyTierCost",
            request
          );
        }
        this.$emit("save");
        this.close();
      }
    },
  },
};
</script>
