<template>
  <div>
    <b-button
      class="ml-2 mr-2"
      type="is-primary"
      icon-left="file-spreadsheet"
      @click="openUploadModal"
    >
      {{ label }}
    </b-button>

    <b-modal
      v-model="showUploadModal"
      has-modal-card
      trap-focus
      :destroy-on-hide="true"
    >
      <template #default>
        <div class="modal-card" style="width: 800px">
          <header class="modal-card-head">
            <p class="modal-card-title">
              {{
                $tf(
                  "components.import-excel.modal.title|Importálás Excel fájlból"
                )
              }}
            </p>
          </header>
          <section class="modal-card-body">
            <ForcaUpload
              v-model="fileToImport"
              :accept="UPLOAD_ACCEPT.SPREADSHEET"
            />

            <div class="mt-5 is-flex is-justify-content-flex-end">
              <b-button
                type="is-info"
                icon-left="file-import"
                @click="closeUploadModal"
              >
                {{
                  $tf("components.import-excel.modal.button.import|Importálás")
                }}
              </b-button>
            </div>
          </section>
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>
import { defineComponent } from "vue";
import ForcaUpload from "@/components/ForcaUpload.vue";
import { UPLOAD_ACCEPT } from "@/utils/const";

export default defineComponent({
  name: "ImportExcel",
  props: {
    label: {
      type: String,
      required: false,
      default: "Import",
    },
  },
  computed: {
    UPLOAD_ACCEPT() {
      return UPLOAD_ACCEPT;
    },
  },
  components: { ForcaUpload },
  data() {
    return {
      fileToImport: null,
      showUploadModal: false,
    };
  },
  methods: {
    openUploadModal() {
      this.showUploadModal = true;
    },
    closeUploadModal() {
      this.showUploadModal = false;
    },
  },
});
</script>
<style scoped lang="scss"></style>
