<template>
  <figure class="image mx-1 my-0" :class="sizeClass">
    <img
      v-if="data?.avatar && !imageError"
      :src="data.avatar"
      :class="sizeClass"
      :alt="name"
      :width="imageWidth"
      :height="imageHeight"
      :style="imageStyle"
      v-show="!isLoading"
      @mouseenter="$emit('mouseEnterAvatar')"
      @mouseleave="$emit('mouseLeaveAvatar')"
      @load="onImageLoad"
      @error="onImageError"
    />
    <avatar-placeholder
      v-if="(isLoading && name) || ((!data?.avatar || imageError) && name)"
      :username="name"
      :font-size="fontSize"
      :predefined-color="predefinedColor"
      @mouseenter="$emit('mouseEnterAvatar')"
      @mouseleave="$emit('mouseLeaveAvatar')"
    />
    <b-icon
      v-else-if="!data?.avatar && !name"
      icon="user-secret"
      class="icon-xxl image is-block mx-1 my-0 is-flex is-align-items-center"
      :class="sizeClass"
      :width="imageWidth"
      :height="imageHeight"
      :style="imageStyle"
    />
  </figure>
</template>

<script>
import AvatarPlaceholder from "../icon/AvatarPlaceholder.vue";
import { stringToColor } from "@/utils/util";

export default {
  name: "FAvatar",
  components: { AvatarPlaceholder },
  props: {
    data: {
      type: Object,
      required: true,
      default: () => ({
        name: "",
        avatar: "",
      }),
    },
    username: {
      type: String,
      default: "",
    },
    size: {
      type: String,
      default: "48x48",
    },
    fontSize: {
      type: Number,
    },
    border: {
      type: Number,
      default: 0,
    },
    predefinedColor: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      isLoading: true,
      imageError: false,
    };
  },
  computed: {
    name() {
      return this.username || this.data?.name;
    },
    sizeClass() {
      return `is-${this.size}`;
    },
    imageWidth() {
      return this.size.split("x")[0] || 48;
    },
    imageHeight() {
      return this.size.split("x")[1] || 48;
    },
    imageStyle() {
      return `border: ${this.border}px solid ${
        this.predefinedColor || stringToColor(this.name)
      }; border-radius: 9999px; width: ${this.imageWidth}px; height: ${
        this.imageHeight
      }px;`;
    },
  },
  methods: {
    onImageLoad() {
      this.isLoading = false;
    },
    onImageError() {
      this.isLoading = true;
      this.imageError = true;
    },
  },
};
</script>

<style scoped></style>
