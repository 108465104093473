<template>
  <b-button
    :type="buttonType"
    :loading="isExportInProgress"
    :disabled="isDisabled"
    :icon-left="iconName"
    @click="excelExport"
  >
    <slot />
  </b-button>
</template>

<script>
import FileSaver from "file-saver";
import network from "@/utils/network";

export default {
  name: "ExcelExporter",
  props: {
    iconName: {
      type: String,
      required: false,
      default: null,
    },
    isDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    buttonType: {
      type: String,
      required: false,
      default: "is-info",
    },
    url: {
      type: String,
      required: true,
    },
    requestConfig: {
      type: Object,
      required: false,
    },
    filename: {
      type: String,
      required: true,
    },
  },

  data: function () {
    return {
      isExportInProgress: false,
    };
  },

  methods: {
    excelExport: async function () {
      this.isExportInProgress = true;
      try {
        const axios = await network.excelConnection();
        const { data } = await axios.get(this.url);
        FileSaver.saveAs(data, this.filename + ".xlsx");
      } finally {
        this.isExportInProgress = false;
      }
    },
  },
};
</script>
