<template>
  <div>
    <div
      v-if="displayMode === 'minimal' || displayMode === 'compact'"
      class="user-info-minimal"
    >
      <b-tooltip
        :position="`is-${tooltipPosition}`"
        multilined
        :active="active"
        always
        :append-to-body="appendTooltipToBody"
      >
        <f-avatar
          :data="user"
          :username="username"
          :size="size"
          :font-size="fontSize"
          :border="border"
          @mouseEnterAvatar="showTooltip"
          @mouseLeaveAvatar="delayedHideTooltip"
        />
        <template #content>
          <div
            @mouseenter="showTooltip"
            @mouseleave="delayedHideTooltip"
            @click.stop
          >
            {{ user?.name }}<br />
            <template v-if="displayMode === 'minimal'">
              <!-- {{ user.userId }}<br /> -->
              {{ user?.email }}<br />
              {{ $filterPhone(user?.phone) }}<br />
            </template>
          </div>
        </template>
      </b-tooltip>
    </div>
    <div v-else-if="displayMode === 'long'" class="media is-align-items-center">
      <b-tooltip
        class="mx-2"
        :position="`is-${tooltipPosition}`"
        multilined
        :active="active"
        always
        :append-to-body="appendTooltipToBody"
      >
        <f-avatar
          :data="user"
          :username="username"
          :size="size"
          :font-size="fontSize"
          :border="border"
          @mouseEnterAvatar="showTooltip"
          @mouseLeaveAvatar="delayedHideTooltip"
        />
        <template #content>
          <div
            @mouseenter="showTooltip"
            @mouseleave="delayedHideTooltip"
            @click.stop
          >
            {{ user?.email }}<br />
            {{ $filterPhone(user?.phone) }}<br />
          </div>
        </template>
      </b-tooltip>
      <div class="media-content">
        <p class="title is-5 long-name">
          <template></template>
          <nobr
            v-for="(name, i) in splitName"
            :key="`UserInfo-long-name-nobr-${i}`"
            >{{ name }}</nobr
          >
        </p>
        <slot name="additional-information"></slot>
      </div>
    </div>
    <div v-else-if="displayMode === 'full'">
      <div class="media pb-2 pt-2 user-info-full">
        <f-avatar
          :data="user"
          :username="username"
          :size="size"
          :font-size="fontSize"
          :border="border"
          @mouseEnterAvatar="showTooltip"
          @mouseLeaveAvatar="delayedHideTooltip"
          class="media-left mb-auto user-info-full-image"
        />
        <div
          class="media-content is-flex is-flex-direction-column is-justify-content-center"
        >
          <strong>{{ user?.name }}</strong>
          <small>{{ user?.email }} </small>
          <small>
            {{ $filterPhone(user?.phone) }}
          </small>
        </div>
      </div>
    </div>
    <div
      v-else-if="displayMode === 'brief'"
      class="media is-align-items-center"
    >
      <f-avatar
        :data="user"
        :username="username"
        :size="size"
        :font-size="fontSize"
        :border="border"
        @mouseEnterAvatar="showTooltip"
        @mouseLeaveAvatar="delayedHideTooltip"
      />
      <div class="media-content">
        <p class="brief-title">{{ user?.name }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import FAvatar from "@/components/module/icon/FAvatar.vue";

export default {
  name: "UserInfo",
  components: { FAvatar },
  data() {
    return {
      active: false,
    };
  },
  props: {
    user: {
      type: Object,
      required: true,
      default: () => ({
        userId: "",
        name: "",
        avatar: "",
        email: "",
        phone: "",
      }),
    },
    username: {
      type: String,
      default: "",
    },
    displayMode: {
      type: String,
      required: true,
      validator: (value) =>
        ["minimal", "compact", "long", "full", "brief"].includes(value),
    },
    tooltipPosition: {
      type: String,
      validator: function (value) {
        return ["top", "right", "bottom", "left"].includes(value);
      },
    },
    size: {
      type: String,
      default: "48x48",
    },
    fontSize: {
      type: Number,
    },
    appendTooltipToBody: {
      type: Boolean,
      default: false,
    },
    border: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    splitName() {
      return this.user?.name?.split(" ") || [];
    },
  },
  methods: {
    showTooltip() {
      if (this.hideTimeout) {
        clearTimeout(this.hideTimeout);
        this.hideTimeout = null;
      }
      this.active = true;
    },
    delayedHideTooltip() {
      this.hideTimeout = setTimeout(() => {
        this.active = false;
      }, 10);
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/assets/scss/colors.scss";

.user-info {
  &-minimal {
    display: flex;
  }

  &-full {
    align-items: center;

    &-image {
      margin-right: 0.625rem !important;
    }
  }
}

.brief-title {
  font-weight: 450;
  font-size: 1rem;
  line-height: 130%;

  color: $custom-dark-blue;
}

.long-name {
  margin-bottom: 0 !important;
  :not(:last-child) {
    margin-right: 0.5rem;
  }
}
</style>
