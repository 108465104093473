<template>
  <div class="modal-card modal-card-scrollable" style="width: auto">
    <div class="container" style="width: 50rem">
      <section class="modal-card-body">
        <h1 class="title" v-if="createMode">
          {{ $tf("forecast.segment.edit.createTitle|Új szegmens létrehozása") }}
        </h1>
        <h1 class="title" v-else>
          {{ $tf("forecast.segment.edit.createTitle|Szegmens szerkesztése") }}
        </h1>
        <form v-show="loaded && filtersLoaded">
          <vuelidated-input
            label="Név"
            placeholder="Név"
            v-model="editSegment.name"
            :validation-rule="v$.editSegment.name"
            label-position="on-border"
            class="mt-5"
          ></vuelidated-input>

          <ForecastSegmentFiltersPicker
            :initial="editSegment.filters"
            ref="filters"
            @loaded="filtersLoaded = true"
          ></ForecastSegmentFiltersPicker>

          <h2 class="has-text-dark-blue has-font-weight-500 mt-2">
            {{ $tf("forecast.segment.edit.usersLabel|Hozzárendelt kollégák") }}
          </h2>

          <div class="is-flex has-gap-4 mt-5">
            <div class="is-flex-3 max-width-100">
              <b-field
                label-position="on-border"
                :label="
                  $tf('forecast.segment.edit.usersLabel|Hozzárendelt kollégák')
                "
              >
                <multiselect-dropdown
                  v-model="editSegment.users"
                  :items="employees"
                  autocomplete
                  icon="user"
                  open-on-focus
                  searchable
                  scrollable
                  append-to-body
                  has-select-all-option
                  identifier-field="id"
                  name-field="name"
                  expanded
                />
              </b-field>
            </div>
          </div>

          <b-field class="mt-5 is-pulled-right">
            <b-button @click="close">
              {{ $tf("forecast.segment.edit.cancel|Mégsem") }}
            </b-button>
            <b-button @click="save" type="is-info" class="ml-3">
              {{ $tf("forecast.segment.edit.save|Mentés") }}
            </b-button>
          </b-field>
        </form>

        <LoadingSkeleton v-show="!(loaded && filtersLoaded)" />
      </section>
    </div>
  </div>
</template>

<script>
import {
  deepCopy,
  employeeFilter,
  generateArraySummaryTranslated,
} from "@/utils/util";
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import VuelidatedInput from "@/components/module/input/VuelidatedInput.vue";
import LoadingSkeleton from "../../module/loading/LoadingSkeleton.vue";
import { mapGetters } from "vuex";
import { FORECAST_BASE_DATA_TYPE } from "@/utils/const";
import ForecastSegmentFiltersPicker from "@/components/forecast/ForecastSegmentFiltersPicker.vue";
import MultiselectDropdown from "@/components/MultiselectDropdown.vue";
import { getCurrentInstance } from "vue";

export default {
  name: "ForecastSegmentEditModal",
  setup: () => {
    const instance = getCurrentInstance();
    return {
      v$: useVuelidate(),
      instance: instance,
    };
  },
  props: {
    segment: {
      type: Object,
      required: false,
    },
  },
  async mounted() {
    this.createMode = !this.segment;
    // await this.$store.dispatch("employee/fetch");

    if (this.segment) {
      this.editSegment = deepCopy(this.segment);
      if (this.editSegment.filters) {
        this.editSegment.filters = this.fromFiltersJSON(
          this.editSegment.filters
        );
      }
    }
    this.loaded = true;
  },
  data() {
    return {
      loaded: false,
      filtersLoaded: false,
      createMode: false,
      editSegment: {
        name: undefined,
        filters: undefined,
        users: [],
      },
      FORECAST_BASE_DATA_TYPE,
      filteredTags: [],
    };
  },
  components: {
    MultiselectDropdown,
    ForecastSegmentFiltersPicker,
    LoadingSkeleton,
    VuelidatedInput,
  },
  validations: {
    editSegment: {
      name: {
        required,
      },
    },
  },
  computed: {
    ...mapGetters({
      employees: "employee/employees",
    }),
    userArraySummary() {
      return generateArraySummaryTranslated.call(
        this,
        this.employees
          .filter((emp) => this.editSegment.users.includes(emp.id))
          .map((it) => it.name)
      );
    },
  },
  methods: {
    close() {
      this.instance.emit("close");
    },
    fromFiltersJSON(map) {
      const arr = [];
      Object.keys(map).forEach((key) => {
        arr.push({
          key,
          value: map[key],
        });
      });
      return arr;
    },
    async save() {
      this.v$.editSegment.$touch();
      if (!this.v$.editSegment.$invalid) {
        const refFilters = this.$refs.filters;
        if (!refFilters.validate()) {
          this.$buefy.toast.open({
            message: this.$tf(
              "forecast.segment.edit.invalidFilters|Legalább egy szűrő beállìtása szükséges"
            ),
            type: "is-danger",
          });
          return;
        }
        const filters = JSON.stringify(refFilters.getData());

        let request = deepCopy(this.editSegment);
        request.filters = filters;

        if (this.createMode) {
          await this.$store.dispatch("forecast_segment/createSegment", request);
        } else {
          await this.$store.dispatch("forecast_segment/updateSegment", request);
        }
        this.$emit("save");
        this.close();
      }
    },
  },
};
</script>

<style scoped>
.modal-card-scrollable {
  overflow: scroll;
}
</style>
