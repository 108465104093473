import { newLogger } from "@/utils/util";
import StoreCore from "@/store/StoreCore";

let logger = newLogger("Enterprise-InsightsFocusStore");

const urls = {
  focusByUsers: "/enterprise/insights/focus/by-users",
  focusByProject: "/enterprise/insights/focus/by-project",
};

const initState = () => {
  return {
    focusByUsers: [],
    focusByProject: [],
  };
};

const storeCore = new StoreCore();
const actions = {
  fetchFocusByUsers: async function (
    { commit, dispatch },
    { force, params } = {}
  ) {
    try {
      const urlSearchParams = new URLSearchParams();
      urlSearchParams.set("from", params.from);
      urlSearchParams.set("to", params.to);
      if (params.propertyIsEnumerable("userIds")) {
        for (const userId in params.userIds) {
          // Multiple paramateres with the same name == array
          // (according to Spring, according to ChatGPT)
          urlSearchParams.append("userId", userId);
        }
      }
      let data = await this.remoteRequest(
        "get",
        urls.focusByUsers + "?" + urlSearchParams.toString(),
        true,
        dispatch,
        "Fókusz betöltése felhasználókra...",
        null,
        force
      );
      commit("saveFocusByUsers", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  fetchFocusByProject: async function (
    { commit, dispatch },
    { force, params }
  ) {
    try {
      const urlSearchParams = new URLSearchParams();
      urlSearchParams.set("from", params.from);
      urlSearchParams.set("to", params.to);
      urlSearchParams.append("projectId", params.projectId);
      let data = await this.remoteRequest(
        "get",
        urls.focusByProject + "?" + urlSearchParams.toString(),
        null,
        true,
        dispatch,
        "Fókusz betöltése projektre...",
        null,
        force
      );
      commit("saveFocusByProject", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
};

const mutations = {
  saveFocusByUsers: (state, data) => {
    state.focusByUsers = data;
  },
  saveFocusByProject: (state, data) => {
    state.focusByProject = data;
  },
};

const getters = {
  focusByUsers: (state) => state.focusByUsers,
  focusByProject: (state) => state.focusByProject,
};

export default {
  namespaced: true,
  state: initState(),
  mutations: mutations,
  actions: actions,
  getters: getters,
};
