<template>
  <b-field
    v-bind="$attrs"
    :expanded="expanded"
    :is="withLoading ? BFieldWithLoading : BField"
    label-position="on-border"
    :type="{ 'is-danger': hasError }"
    class="validator-field"
    :class="fieldClass"
    :label="label"
    :data-has-error="hasError"
  >
    <template #label v-if="required">
      {{ label }}
      <span class="has-text-danger">*</span>
    </template>
    <div class="is-flex is-flex-direction-column">
      <vue-tel-input
        ref="tel-input"
        :value="modelValue ?? ''"
        :dropdown-options="vueTelInputOptions.dropdownOptions"
        :input-options="vueTelInputOptions.inputOptions"
        @input="onInput"
        @validate="onValidate"
        @country-changed="onCountryChanged"
        :auto-format="false"
        :class="{ 'is-danger': hasError }"
      />
      <div v-if="!isValid">
        <p class="help is-danger" v-if="!phone && required && dirty">
          {{ $tf("input.phone.requiredError|A mező kitöltése kötelező!") }}
        </p>
        <p class="help is-danger" v-else-if="phone && !validPhoneNumber">
          {{
            $tf("input.phone.formatError|Nem megfelelő telefonszám formátum!")
          }}
        </p>
      </div>
    </div>
  </b-field>
</template>

<script>
import { useVuelidate } from "@vuelidate/core";
import BFieldWithLoading from "@/components/loading/BFieldWithLoading.vue";
import { BField } from "buefy/src/components/field";
import { vMaska } from "maska/vue";

export default {
  props: {
    label: {
      type: String,
      default: "Telefonszám",
    },
    modelValue: {
      type: [String, Number],
      default: "",
    },
    fieldClass: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "Telefonszám",
    },
    required: {
      type: Boolean,
      default: false,
    },
    expanded: {
      type: Boolean,
      default: false,
    },
    withLoading: {
      type: Boolean,
      default: false,
    },
  },
  setup: () => ({ v$: useVuelidate() }),
  mounted() {
    if (this.modelValue) {
      this.phone = this.modelValue;
    }
    this.$emit("validate", this.isValid);
  },
  data() {
    return {
      BFieldWithLoading,
      BField,
      phone: null,
      validPhoneNumber: true,
      dirty: false,
      phoneRegex:
        /^(?:([+]\d{1,4})[-.\s]?)?(?:[(](\d{1,3})[)][-.\s]?)?(\d{1,4})[-.\s]?(\d{1,4})[-.\s]?(\d{1,9})$/,
    };
  },
  directives: { maska: vMaska },
  watch: {
    modelValue: {
      handler() {
        if (this.modelValue) {
          this.phone = this.modelValue;
        }
      },
    },
  },
  computed: {
    hasError() {
      return (!this.isValid && this.dirty) || this.forceError;
    },
    vueTelInputOptions() {
      return {
        dropdownOptions: {
          showDialCodeInList: true,
          showDialCodeInSelection: false,
          showFlags: true,
        },
        inputOptions: {
          placeholder: this.placeholder,
          maxlength: 25,
          name: "phone",
          required: this.required,
          type: "tel",
        },
      };
    },

    isValid() {
      if (this.required && !this.phone) return false;
      if (!this.required && !this.phone) return true;
      return this.validPhoneNumber;
    },
  },
  methods: {
    onInput(event) {
      if (event.target) {
        this.phone = event.target.value;
        if (this.phone) {
          this.touch();
        }
        if (!this.phone) {
          this.$emit("update:modelValue", null);
        } else if (this.validPhoneNumber) {
          this.$emit("update:modelValue", this.phone);
        }
        this.$emit("validate", this.isValid);
      }
    },
    onCountryChanged() {
      this.onInput({
        target: {
          value: this.$refs["tel-input"].phone,
        },
      });
    },
    onValidate(event) {
      if (!!event.valid) {
        this.$emit("update:modelValue", event.number);
      }
      this.validPhoneNumber = !!event.valid;
      this.$emit("validate", this.isValid);
    },
    touch() {
      this.dirty = true;
    },
  },
};
</script>
