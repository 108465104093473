import { newLogger } from "@/utils/util";
import StoreCore from "@/store/StoreCore";

let logger = newLogger("SpecialDayStore");

const urls = {
  specialDays: "/absence/special-day",
  specialDaysFrom: "/absence/special-day/all-from",
  allSpecialDays: (year) => `/absence/special-day/${year}/all`,
  specialDay: (id) => `/absence/special-day/${id}`,
  weekendInit: "/absence/special-day/init",
  carryOverDates: (year) => `/absence/special-day/carry-over-dates/${year}`,
};

const initState = () => {
  return {
    specialDaysFrom: {},
    specialDaysAll: {},
    specialDays: {},
    specialDay: null,
    carryOverDates: {},
    normalDayNumber: null,
  };
};

const storeCore = new StoreCore();
const actions = {
  getSpecialDaysFrom: async function (
    { commit, dispatch },
    { fromDate, force }
  ) {
    try {
      let data = await this.remoteRequest(
        "get",
        urls.specialDaysFrom,
        {
          params: { fromDate: fromDate },
        },
        true,
        dispatch,
        "Fetching all special days from...",
        undefined,
        force
      );
      commit("setSpecialDaysFrom", { data, fromDate });
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  fetchSpecialDaysAll: async function (
    { commit, dispatch, state },
    { year, force } = {}
  ) {
    try {
      let data = await this.remoteRequest(
        "get",
        urls.allSpecialDays(year),
        null,
        true,
        dispatch,
        "Év napjainak betöltése...",
        null,
        force
      );
      commit("setSpecialDaysAll", { data, year });
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  getSpecialDays: async function (
    { commit, dispatch, state },
    { params, force } = {}
  ) {
    try {
      let data = await this.remoteRequest(
        "get",
        urls.specialDays,
        params,
        true,
        dispatch,
        "Különleges napok betöltése...",
        null,
        force
      );

      commit("setSpecialDays", { data, year: params.params.get("year") });
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  getSpecialDay: async function ({ commit, dispatch }, { id, force } = {}) {
    try {
      let data = await this.remoteRequest(
        "get",
        urls.specialDay(id),
        null,
        true,
        dispatch,
        "Különleges nap betöltése...",
        null,
        force
      );
      commit("setSpecialDay", data);
    } catch (err) {
      logger.error(err);
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  initWeekends: async function ({ dispatch, state }, year) {
    try {
      const urlSearchParams = new URLSearchParams();
      urlSearchParams.set("year", year);
      await this.remoteRequest(
        "post",
        urls.weekendInit + "?" + urlSearchParams.toString(),
        null,
        false,
        dispatch,
        "specialDay.initWeekends.flair|Hétvégék felvétele",
        undefined,
        undefined,
        false,
        true,
        {
          progress:
            "specialDay.initWeekends.progress|Hétvégék felvétele folyamatban...",
          fail: "specialDay.initWeekends.fail|Hétvégék felvétele sikertelen!",
          success:
            "specialDay.initWeekends.success|Hétvégék felvétele sikeres!",
        }
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
      await Promise.reject(err);
    }
  }.bind(storeCore),
  deleteSpecialDay: async function ({ dispatch }, id) {
    try {
      await this.remoteRequest(
        "delete",
        urls.specialDay(id),
        null,
        false,
        dispatch,
        "specialDay.delete.flair|Különleges nap törlése",
        undefined,
        undefined,
        false,
        true,
        {
          progress:
            "specialDay.delete.progress|Különleges nap törlése folyamatban...",
          fail: "specialDay.delete.fail|Különleges nap törlése sikertelen!",
          success: "specialDay.delete.success|Különleges nap törlése sikeres!",
        }
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
      await Promise.reject(err);
    }
  }.bind(storeCore),
  updateSpecialDay: async function ({ dispatch }, request) {
    try {
      await this.remoteRequest(
        "put",
        urls.specialDays + `/${request.id}`,
        {
          type: request.type,
          name: request.name,
          description: request.description,
          dayDate: request.dayDate,
        },
        false,
        dispatch,
        "specialDay.modify.flair|Különleges nap módosítása",
        undefined,
        undefined,
        false,
        true,
        {
          progress:
            "specialDay.modify.progress|Különleges nap módosítása folyamatban...",
          fail: "specialDay.modify.fail|Különleges nap módosítása sikertelen!",
          success:
            "specialDay.modify.success|Különleges nap módosítása sikeres!",
        }
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
      await Promise.reject(err);
    }
  }.bind(storeCore),
  createSpecialDay: async function ({ dispatch }, request) {
    try {
      await this.remoteRequest(
        "post",
        urls.specialDays,
        request,
        false,
        dispatch,
        "specialDay.create.flair|Különleges nap létrehozása",
        undefined,
        undefined,
        false,
        true,
        {
          progress: "specialDay.create.progress|Létrehozás folyamatban...",
          fail: "specialDay.create.fail|Különleges nap létrehozása sikertelen!",
          success:
            "specialDay.create.success|Különleges nap létrehozása sikeres!",
        }
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
      await Promise.reject(err);
    }
  }.bind(storeCore),
  fetchCarryOverDates: async function (
    { dispatch, commit },
    { year, force } = {}
  ) {
    try {
      let data = await this.remoteRequest(
        "get",
        urls.carryOverDates(year),
        null,
        true,
        dispatch,
        "Fetching carry over dates...",
        null,
        force
      );
      commit("setCarryOverDates", { data, year });
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  clearCache: async function ({}) {
    this.clearCoreCache();
  }.bind(storeCore),
};

const mutations = {
  setSpecialDays: (state, { year, data }) => {
    state.specialDays[year] = data;
  },
  setSpecialDaysAll: (state, { year, data }) => {
    state.specialDaysAll[year] = data;
  },
  setSpecialDaysFrom: (state, { data, from }) => {
    state.specialDaysFrom[from] = data;
  },
  setSpecialDay: (state, specialDay) => {
    state.specialDay = specialDay;
  },
  setCarryOverDates: (state, { data, year }) => {
    state.carryOverDates[year] = data;
  },
};

const getters = {
  specialDay: (state) => {
    return state.specialDay;
  },
  specialDaysAll:
    (state) =>
    (...years) => {
      return (
        years?.flatMap((year) =>
          year ? state.specialDaysAll[year] ?? [] : []
        ) ?? []
      );
    },
  specialDaysFrom: (state) => (from) => {
    return state.specialDaysFrom[from];
  },
  specialDays: (state) => (year) => {
    return state.specialDays[year];
  },
  carryOverDates: (state) => (year) => {
    return state.carryOverDates[year];
  },
};

export default {
  namespaced: true,
  state: initState(),
  mutations: mutations,
  actions: actions,
  getters: getters,
};
