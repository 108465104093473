import { newLogger } from "@/utils/util";

/**
 * The Loader component display loaders for every single request its wired into
 */

let logger = newLogger("loading-vuex");

let fixClass = "loading-indicator__item ";
let classPrefix = "loading-indicator__item";
let eventFixClass = "event-indicator__item ";
let eventClassPrefix = "event-indicator__item";
let successSuffix = "--success";
let failureSuffix = "--failure";
let fadingSuffix = "--fading";

const DEBUG_LOADING_KEY = "erp4sd_debug";

/**
 * Static state while booting
 */
const state = {
  loaders: [], // the array used for display
  loadingObjects: {}, // the object used internally
  enabled: !!JSON.parse(localStorage.getItem(DEBUG_LOADING_KEY)),
};

/**
 * Need to be called from requestors
 */
const actions = {
  startLoading: (context, loadingObject) => {
    if (context.state.enabled || loadingObject.event === true) {
      context.commit("enableLoading", loadingObject);
    }
  },
  finishLoading: (context, loadingObject) => {
    if (context.state.enabled || loadingObject.event === true) {
      context.commit("disableLoading", loadingObject);
    }
  },
};

const getters = {
  enabled: (state) => {
    return state.enabled;
  },
};

/**
 * Finds the needle in a haystack, based on ID
 */
let findInArray = function (needle, haystack) {
  for (let i = 0; i < haystack.length; ++i) {
    if (haystack[i] && haystack[i].id === needle) {
      return i;
    }
  }
  return -1;
};

/**
 * Delayed remover
 */
let removeLater = function (loadingObjects, loaders, target) {
  loadingObjects[target] = null;
  let index = findInArray(target, loaders);
  if (index !== -1) {
    loaders.splice(index);
  } else {
    logger.warn("Remover couldn't find the element: " + target);
  }
};

/**
 * Delayed disabler
 */
let fadeLater = function (loaders, target) {
  let index = findInArray(target.id, loaders);
  if (index !== -1) {
    if (target.event === true) {
      loaders[index].classes += " " + eventClassPrefix + fadingSuffix;
    } else {
      loaders[index].classes += " " + classPrefix + fadingSuffix;
    }
  } else {
    logger.warn("Disabler couldn't find the element: " + target.id);
  }
};

const mutations = {
  enableLoading: (state, loadingObject) => {
    if (state.loadingObjects[loadingObject.id]) {
      logger.warn("Got an ID that already exists: " + loadingObject.id);
      return;
    }
    loadingObject.status = "progress";
    loadingObject.classes =
      loadingObject.event === true ? eventFixClass : fixClass;
    state.loadingObjects[loadingObject.id] = loadingObject;
    state.loaders.push(loadingObject);
  },
  disableLoading: (state, loadingObject) => {
    if (!state.loadingObjects[loadingObject.id]) {
      logger.warn("Got an ID that doesn't exists: " + loadingObject.id);
      return;
    }
    let index = findInArray(loadingObject.id, state.loaders);
    let arrayObject = state.loaders[index];
    if (!arrayObject) return;
    if (loadingObject.result) {
      arrayObject.status = "success";
      arrayObject.classes =
        (loadingObject.event === true ? eventFixClass : fixClass) +
        " " +
        (loadingObject.event === true ? eventClassPrefix : classPrefix) +
        successSuffix;

      window.setTimeout(
        fadeLater.bind(null, state.loaders, loadingObject),
        750
      );
      window.setTimeout(
        removeLater.bind(
          null,
          state.loadingObjects,
          state.loaders,
          loadingObject.id
        ),
        2750
      );
    } else {
      // failure
      arrayObject.status = "fail";
      arrayObject.classes =
        (loadingObject.event === true ? eventFixClass : fixClass) +
        " " +
        (loadingObject.event === true ? eventClassPrefix : classPrefix) +
        failureSuffix;

      window.setTimeout(
        fadeLater.bind(null, state.loaders, loadingObject),
        2500
      );
      window.setTimeout(
        removeLater.bind(
          null,
          state.loadingObjects,
          state.loaders,
          loadingObject.id
        ),
        4500
      );
    }
  },
};

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters,
};
