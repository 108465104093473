<template>
  <b-field :label="dimension.dimensionName" label-position="on-border">
    <template v-if="dimension.multipleAssignment">
      <b-taginput
        v-model="computedValue"
        :data="filteredDimensions"
        autocomplete
        :open-on-focus="true"
        icon="list"
        field="name"
        :placeholder="
          $tf('dimensionInput.placeholder|{dimensionName} választása', {
            dimensionName: dimension.dimensionName,
          })
        "
        @typing="filterValues($event)"
        class="has-blue-icon"
      >
      </b-taginput>
    </template>
    <template v-else>
      <f-autocomplete
        :placeholder="
          $tf('dimensionInput.placeholder|{dimensionName} választása', {
            dimensionName: dimension.dimensionName,
          })
        "
        v-model="computedValue"
        :data="dimension.values"
        field="name"
        id-field="id"
        append-to-body
        open-on-focus
        return-object
        clearable
      />
    </template>
  </b-field>
</template>

<script>
import FAutocomplete from "@/components/module/input/FAutocomplete.vue";

export default {
  name: "DimensionInput",
  components: { FAutocomplete },
  inheritAttrs: false,
  props: {
    modelValue: {
      type: [Number, String, Object, Array],
      required: false,
    },
    dimension: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      filteredDimensions: this.dimension.values.slice(),
      search: "",
    };
  },
  computed: {
    computedValue: {
      get() {
        if (this.dimension.multipleAssignment) return this.modelValue;
        return (this.modelValue || [{}])[0]?.id;
      },
      set(value) {
        this.$emit("update:modelValue", Array.isArray(value) ? value : [value]);
      },
    },
  },
  methods: {
    filterValues(text) {
      const filteredValues = this.dimension.values.filter((dimensionValue) => {
        return (
          dimensionValue.name
            .toString()
            .toLowerCase()
            .indexOf(text.toLowerCase()) >= 0
        );
      });

      this.filteredDimensions.splice(0, this.filteredDimensions.length);
      this.filteredDimensions.push(...filteredValues);
    },
  },
};
</script>
