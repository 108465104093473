<template>
  <div>
    <ul>
      <li>
        {{
          $tf(
            "riskManagement.loggingWillingness.average|Globális átlag logolási késedelem: "
          )
        }}
        &nbsp;
        <b>{{
          loading
            ? $tf("riskManagement.loggingWillingness.loading|Töltés...")
            : willingness.avgWillingnessTime
        }}</b>
      </li>
      <li>
        {{
          $tf(
            "riskManagement.loggingWillingness.median|Globális medián logolási késedelem: "
          )
        }}
        <b>{{
          loading
            ? $tf("riskManagement.loggingWillingness.loading|Töltés...")
            : willingness.medianWillingnessTime
        }}</b>
      </li>
    </ul>
    <b-table
      :data="willingness?.records || []"
      paginated
      :per-page="50"
      hoverable
      :loading="loading"
    >
      <b-table-column
        field="name"
        :label="
          $tf('riskManagement.loggingWillingness.table.employee|Munkavállaló')
        "
        searchable
        v-slot="props"
      >
        <UserInfo
          :user="{
            userId: props.row.userId,
            name: props.row.name,
            avatar: props.row.avatar,
            email: props.row.email,
            phone: props.row.phone,
          }"
          displayMode="long"
          tooltipPosition="bottom"
        />
      </b-table-column>

      <b-table-column
        field="avgWillingnessSeconds"
        :label="
          $tf(
            'riskManagement.loggingWillingness.table.avgWillingnessSeconds|Átlag késedelem'
          )
        "
        sortable
        v-slot="props"
      >
        {{ props.row.avgWillingnessTime }}
      </b-table-column>

      <b-table-column
        field="medianWillingnessSeconds"
        :label="
          $tf(
            'riskManagement.loggingWillingness.table.medianWillingnessSeconds|Medián késedelem'
          )
        "
        sortable
        v-slot="props"
      >
        {{ props.row.medianWillingnessTime }}
      </b-table-column>

      <b-table-column
        field="maxDifferenceSeconds"
        :label="
          $tf(
            'riskManagement.loggingWillingness.table.maxDifferenceSeconds|Legnagyobb késedelem'
          )
        "
        sortable
        v-slot="props"
      >
        {{ props.row.maxDifferenceTime }}
      </b-table-column>
    </b-table>
  </div>
</template>

<script>
import { secondToShortWorkDayFormat, formatDate, moneyify } from "@/utils/util";
import { mapGetters } from "vuex";
import UserInfo from "@/components/module/info/UserInfo.vue";
export default {
  name: "LoggingWillingnessTable",
  components: { UserInfo },
  computed: {
    ...mapGetters({
      willingness: "risk_management/getLoggingWillingness",
    }),
  },
  methods: {
    async fetchData() {
      this.loading = true;
      await this.$store.dispatch("risk_management/clearCache");
      await this.$store.dispatch("risk_management/fetchLoggingWillingness");
      this.loading = false;
    },
  },
  watch: {},
  data() {
    return {
      moneyify: moneyify,
      secondToShortWorkDayFormat: secondToShortWorkDayFormat,
      formatDate: formatDate,
      onlyMyProjects: true,
      showUnknown: false,
      loading: true,
    };
  },
  async mounted() {
    await this.fetchData();
  },
};
</script>

<style></style>
