<template>
  <section v-if="!loading">
    <div class="container ralloc-container">
      <p class="ralloc-container-title">
        {{
          $tf(
            "rallocPage.projectAllocationsAndUtilization|Projektek allokációi, utilizáltságuk"
          )
        }}
      </p>
      <div class="is-flex has-gap-5">
        <div style="width: 500px">
          <b-field
            :label="$tf('resource.utilization.filter.employee|Szűrő')"
            expanded
          >
            <multiselect-dropdown
              v-model="selectedProjects"
              :items="activeProjects"
              append-to-body
              identifier-field="id"
              name-field="name"
              use-tf
              expanded
              searchable
              icon="magnifying-glass"
            >
              <template #empty>{{
                $tf("resource.utilization.filter.empty|Nincs találat")
              }}</template>
            </multiselect-dropdown>
          </b-field>
        </div>
        <div
          v-if="extendedAssociations.length > 0"
          class="ml-4 ralloc-container-icons"
        >
          <b-icon
            class="cursor-pointer mr-2"
            icon="chevron-left"
            size="is-small"
            @click="prevWeek"
          />
          <span
            v-if="displayedWeeks.length"
            class="mr-2 ralloc-container-icons-weeks"
          >
            {{ `${displayedWeeks[0].week} ${$tf("rallocPage.nthWeek|. hét")}` }}
            -
            {{ `${displayedWeeks[2].week} ${$tf("rallocPage.nthWeek|. hét")}` }}
          </span>
          <b-icon
            class="cursor-pointer"
            icon="chevron-right"
            size="is-small"
            @click="nextWeek"
          />
          <b-icon
            class="cursor-pointer ralloc-container-icons-undo"
            icon="undo"
            size="is-small"
            @click="resetWeek"
          />
        </div>
      </div>
    </div>
    <div class="container utilization-projects">
      <div
        id="utilization-table-container"
        class="utilization-table-container overflow-x-auto"
        ref="tableWrapper"
      >
        <b-table
          :data="extendedAssociations"
          class="ralloc-container-projects-table"
          v-if="extendedAssociations.length > 0"
          detailed
        >
          <b-table-column
            field="name"
            :label="$tf('resource.utilization.projects.table.project|Projekt')"
            v-slot="{ row }"
          >
            <div
              @click="goToProject"
              class="utilization-top-row utilization-top-row-identifier"
            >
              {{ row.identifier }}
            </div>
            <div
              @click="goToProject"
              class="utilization-bottom-row utilization-bottom-row-name"
            >
              {{ row.name }}
            </div>
          </b-table-column>
          <b-table-column
            field="totalHours"
            :label="
              $tf('resource.utilization.projects.table.summary|Összefoglaló')
            "
            v-slot="{ row }"
            centered
          >
            <div class="utilization-top-row">
              <b-icon icon="sigma" /><b-icon icon="ballot-check" />{{
                workdayify(row.totalAllocated)
              }}
            </div>
          </b-table-column>
          <b-table-column
            v-for="(week, i) in displayedWeeks"
            field="weekData.week"
            :key="i"
            centered
            :header-class="
              new Date(week.start).getWeek() === new Date().getWeek() &&
              new Date(week.start).getYear() === new Date().getYear()
                ? 'ralloc-container-header-now'
                : ''
            "
            cell-class="ralloc-container-column"
          >
            <template v-slot:header="">
              <b>
                {{ `${week.week} ${$tf("rallocPage.nthWeek|. hét")}` }}
                <span
                  v-if="
                    new Date(week.start).getWeek() === new Date().getWeek() &&
                    new Date(week.start).getYear() === new Date().getYear()
                  "
                  class="ralloc-container-header-this-week"
                >
                  {{ $tf("rallocPage.thisWeek| (e hét)") }}
                </span>
              </b>
              <br />
              {{ formatDate(week.start) }} -
              {{ formatDate(week.end) }}
            </template>
            <template v-slot="{ row }">
              <div
                :class="
                  new Date(week.start).getWeek() === new Date().getWeek() &&
                  new Date(week.start).getYear() === new Date().getYear()
                    ? 'ralloc-container-projects-table-current-week'
                    : ''
                "
              >
                <WeeklyProjectUtilization
                  :weekly="
                    findWeekData(
                      { year: week.year, week: week.week },
                      row.weekly
                    )
                  "
                  :future="week.start > new Date()"
                />
              </div>
            </template>
          </b-table-column>
          <template #detail="{ row }">
            <div class="users-details">
              <b-table :data="getAllocatedUsersForProject(row)">
                <b-table-column
                  v-slot="props"
                  centered
                  header-class="utilization-project-details-header"
                >
                  <user-info
                    display-mode="full"
                    :user="props.row"
                    class="utilization-project-details-user-info"
                  />
                </b-table-column>
                <b-table-column
                  centered
                  header-class="utilization-project-details-header"
                >
                  <!-- {{ getTotalOfAllocatedUser(props.row, getWeeklyAllocationsForProject(row)) }} -->
                </b-table-column>
                <b-table-column
                  v-for="(week, index) in displayedWeeks"
                  :key="`${week}-${index}`"
                  v-slot="props"
                  centered
                  header-class="utilization-project-details-header"
                  cell-class="ralloc-container-column"
                >
                  <div
                    :class="
                      new Date(week.start).getWeek() === new Date().getWeek() &&
                      new Date(week.start).getYear() === new Date().getYear()
                        ? 'ralloc-container-projects-table-current-week-people'
                        : ''
                    "
                  >
                    <span>
                      <b-icon
                        v-if="
                          getWeeklyTotalOfAllocatedUser(
                            props.row,
                            getWeeklyAllocationsForProject(row)
                          )[week.week]
                        "
                        icon="ballot-check"
                      />
                      {{
                        getWeeklyTotalOfAllocatedUser(
                          props.row,
                          getWeeklyAllocationsForProject(row)
                        )[week.week] ||
                        $tf("rallocPage.projectUtilization.noAllocation|N/A")
                      }}
                    </span>
                  </div>
                </b-table-column>
              </b-table>
            </div>
          </template>
        </b-table>
        <div v-if="loading">
          {{
            $tf(
              "resource.utilization.projects.loading|Projektadatok betöltése folyamatban..."
            )
          }}
        </div>
        <div v-if="extendedAssociations.length <= 0">
          <div class="ralloc-container-empty-list"></div>
          <div class="ralloc-container-empty-placeholder">
            {{
              $tf(
                "resource.utilization.projects.empty|A megjelenítéshez válassz legalább egy projektet!"
              )
            }}
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import MultiselectDropdown from "@/components/MultiselectDropdown.vue";
import debounce from "lodash.debounce";
import {
  workdayify,
  convertHoursToMWD,
  formatDate,
  generateNext4Weeks,
  addDay,
  subtractDay,
  percentify,
} from "@/utils/util";
import WeeklyProjectUtilization from "@/components/ralloc/WeeklyProjectUtilization.vue";
import UserInfo from "../module/info/UserInfo.vue";

export default defineComponent({
  name: "UtilizationProjects",
  components: { UserInfo, WeeklyProjectUtilization, MultiselectDropdown },
  created() {
    window.addEventListener("resize", this.resizeHandler);
  },
  async mounted() {
    await this.fetchData();
    this.loading = false;
    this.$nextTick(() => {
      this.tableWidth = this.$refs.tableWrapper.clientWidth;
    });
  },
  unmounted() {
    window.removeEventListener("resize", this.resizeHandler);
  },
  data() {
    return {
      workdayify,
      formatDate,
      loading: true,
      selectedProjects: [],
      tableWidth: 0,
      startWeekDate: new Date(),
    };
  },
  computed: {
    extendedAssociations() {
      let data = [];
      this.associations.forEach((project) => {
        let weeklyData = [];
        project.weeklyUtilizations.forEach((week) => {
          weeklyData.push({
            year: week.year,
            week: week.week,
            allocatedHours: week.allocatedHours,
          });
        });
        data.push({
          identifier: project.projectData.identifier,
          name: project.projectData.name,
          totalAllocated: project.totalHours / 8,
          weekly: weeklyData,
        });
      });
      return data;
    },
    displayedWeeks() {
      return generateNext4Weeks(this.startWeekDate);
    },
    activeProjects() {
      return this.projects
        .filter(
          (project) =>
            !project.archived &&
            project.status !== "CANCELLED" &&
            project.status !== "DONE" &&
            project.status !== "CLOSED"
        )
        .sort((a, b) => a.name.localeCompare(b.name));
    },
    ...mapGetters({
      associations: "association/projectUtilizations",
      projects: "enterprise_core/limitedProjects",
      employees: "employee/employees",
    }),
  },
  watch: {
    "selectedProjects.length": {
      handler: debounce(async function () {
        await this.fetchDisplayData();
      }, 500),
    },
  },
  methods: {
    percentify,
    convertHoursToMWD,
    addDay,
    subtractDay,
    nextWeek() {
      this.startWeekDate = new Date(this.addDay(this.startWeekDate, 7));
    },
    prevWeek() {
      this.startWeekDate = new Date(this.subtractDay(this.startWeekDate, 7));
    },
    resetWeek() {
      this.startWeekDate = new Date();
    },
    findEmployee(employeeId) {
      return this.employees.find((employee) => employee.id === employeeId);
    },
    findWeekData({ year, week }, weeklyData) {
      for (let weekly of weeklyData) {
        if (weekly.year === year && weekly.week === week) {
          return weekly;
        }
      }
      return {
        noData: true,
      };
    },
    getMondayAndFriday(year, weekNumber) {
      const firstDayOfYear = new Date(year, 0, 1);
      const firstDayOfWeek = firstDayOfYear.getDay();
      const dayOffset = firstDayOfWeek === 0 ? 1 : 8 - firstDayOfWeek;

      const monday = new Date(year, 0, 1 + dayOffset + (weekNumber - 1) * 7);
      const friday = new Date(monday);
      friday.setDate(monday.getDate() + 4);

      return { monday, friday };
    },
    async fetchData() {
      await this.$store.dispatch("enterprise_core/fetchLimitedProjects");
      await this.$store.dispatch("employee/fetch");
      this.loading = false;
    },
    async fetchDisplayData() {
      this.loading = true;
      await this.$store.dispatch(
        "association/getUtilizationForProjects",
        this.selectedProjects
      );
      this.loading = false;
    },
    resizeHandler() {
      this.tableWidth = this.$refs.tableWrapper.clientWidth;
    },
    goToProject(row) {
      if (row.type === "SUPPORT") {
        this.$router.push(`/projects/support/${row.identifier}`);
      } else if (row.type === "OUTSOURCE") {
        this.$router.push(`/projects/outsource/${row.identifier}`);
      } else {
        this.$router.push(`/projects/project/${row.identifier}`);
      }
    },
    getAssociationsForProject(row) {
      return this.associations?.find(
        (association) => association.projectData.identifier === row?.identifier
      );
    },
    getWeeklyAllocationsForProject(row) {
      return this.getAssociationsForProject(row)?.weeklyUtilizations;
    },
    getAllocatedUsersForProject(row) {
      const users = [];
      this.getWeeklyAllocationsForProject(row)?.forEach((week) => {
        week.associations.forEach((association) => {
          users.push(association.userId);
        });
      });
      return [...new Set(users)].map((userId) => {
        return this.employees.find((employee) => employee.id === userId);
      });
    },
    getWeeklyTotalOfAllocatedUser(user, weeklyAllocations) {
      const weeks = {};
      weeklyAllocations.forEach((week) =>
        week.associations.forEach((association) => {
          if (
            association.userId === user.id &&
            this.displayedWeeks.find(
              (displayedWeek) => displayedWeek.week === week.week
            )
          ) {
            weeks[week.week] = association.hours
              ? `${association.hours / 8} ${this.$tf("rallocPage.mwd|MWD")}`
              : this.$tf("rallocPage.projectUtilization.noAllocation|N/A");
          }
        })
      );
      return weeks;
    },
    getTotalOfAllocatedUser(user, weeklyAllocations) {
      let total = 0;
      weeklyAllocations.forEach((week) =>
        week.associations.forEach((association) => {
          if (association.userId === user.id) {
            total = total + association.hours;
          }
        })
      );
      return total
        ? `${total / 8} ${this.$tf("rallocPage.mwd|MWD")}`
        : this.$tf("rallocPage.projectUtilization.noAllocation|N/A");
    },
  },
});
</script>

<style></style>

<style lang="scss">
@import "~@/assets/scss/colors.scss";

.utilization-projects {
  .table-wrapper:not(:last-child) {
    margin-bottom: 0 !important;
  }
}

.ralloc-container-column {
  width: 180px !important;
}

.borderless-cell {
  border: none !important;
}

.utilization-project {
  &-details {
    &-header {
      display: none !important;

      thead {
        display: none !important;
      }
    }

    &-user-info {
      max-width: 140px;
      text-align: left;

      figure {
        margin-right: 20px !important;
        margin-top: 6px !important;
      }
    }
  }
}

.ralloc-container {
  .content table td {
    border: 1px solid $grey-lightest !important;
  }

  &-projects-table {
    table {
      border-spacing: 0 8px !important;
      border-collapse: separate;
    }

    th {
      div {
        text-align: left !important;
        justify-content: left;
      }
    }

    td {
      border-top: 1px solid $grey-lightest !important;
      border-bottom: 1px solid $grey-lightest !important;
      color: $custom-dark-blue !important;
      cursor: pointer;
    }

    td:first-of-type {
      border-left: 1px solid $grey-lightest !important;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }

    td:last-of-type {
      border-right: 1px solid $grey-lightest !important;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }

    &-current-week {
      background: $custom-lighter-blue;
      padding: 8px;
      border-radius: 12px;
    }

    &-current-week-people {
      background: $custom-lightest-blue;
      padding: 8px;
      border-radius: 12px;
    }

    .detail {
      border: none !important;
      border-radius: 0 !important;

      td[colspan="7"] {
        background: $white !important;
        border: none !important;
        border-radius: 0 !important;
        padding-left: 0 !important;
        padding-right: 0 !important;
        padding-top: 0 !important;

        .detail-container {
          padding: 0 !important;
        }
      }
    }
  }

  &-title {
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    color: $custom-dark-blue;
  }

  &-empty-list {
    display: block;
    width: 100%;
    border-radius: 8px;
    background-color: $grey-lightest;
    height: 30px;
  }

  &-empty-placeholder {
    height: 100%;
    width: 100%;
    padding: 21px;
    border-radius: 10px;
    border: 1px solid $grey-lightest;
    color: $grey-lighter;
    margin-top: 10px;
    text-align: center;
  }

  &-header-now {
    background: $grey-lighter !important;
  }

  &-icons {
    &-weeks {
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      color: $custom-dark-blue;
    }

    &-undo {
      margin-left: 16px;
      color: $blue;
    }
  }
}

.utilization-top-row {
  font-size: 14px;

  &-identifier {
    font-weight: 500;
    color: $custom-light-blue;
    cursor: pointer;
  }
}
.utilization-bottom-row {
  font-size: 14px;
  line-height: 16px;

  &-name {
    cursor: pointer;
  }
}
.util-actions-column {
  color: $blue;
}
</style>
