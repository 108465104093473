<template>
  <b-upload
    drag-drop
    expanded
    :modelValue="file"
    @update:modelValue="updateValue"
    :accept="accept"
    rounded
    :loading="loading"
    :disabled="loading"
  >
    <div class="is-flex is-justify-content-center py-2">
      <div
        class="is-inline-flex is-align-items-center"
        :class="{
          'is-flex-direction-column': size === 'is-large',
          'has-gap-4': size === 'is-large',
        }"
      >
        <b-icon icon="upload" custom-size="fa-lg" class="mr-2" />
        <span>
          {{
            $tf(
              "components.upload.subtitle.drag-and-drop|Húzd ide a fájlt, vagy"
            )
          }}
        </span>
        <span class="ml-2 has-border">
          {{ $tf("components.upload.subtitle.browse|Tallózás") }}
        </span>
      </div>
    </div>
    <div class="is-flex is-justify-content-center" v-if="file">
      <b-tag closable @close="removeFile">
        {{ truncateWithExtension(file.name, 30) }}
      </b-tag>
    </div>
  </b-upload>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "ForcaUpload",
  props: {
    modelValue: {
      type: String,
      required: true,
    },
    accept: {
      type: String,
      required: false,
      default: "file",
    },
    multiple: {
      //todo
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: "is-medium",
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      file: null,
    };
  },
  watch: {
    modelValue(val) {
      if (!val) {
        this.file = null;
      }
    },
  },
  methods: {
    updateValue(file) {
      if (file.size > 1e7) {
        this.$buefy.toast.open({
          duration: 5000,
          message: this.$tf(
            "upload.error.fileSize|A maximum feltölthető méret 10MB!"
          ),
          position: "is-bottom",
          type: "is-danger",
        });
        this.file = null;
        return;
      }

      this.file = file;
      this.$emit("update:modelValue", file);
    },
    removeFile($event) {
      $event.preventDefault();
      this.file = null;

      this.$emit("update:modelValue", null);
    },
    truncateWithExtension(str, n) {
      if (str.length <= n) return str;

      const lastDotIndex = str.lastIndexOf(".");
      let extension = "";

      if (lastDotIndex !== -1 && str.length - lastDotIndex <= 5) {
        extension = str.slice(lastDotIndex);
        str = str.slice(0, lastDotIndex);
      }

      return str.slice(0, n) + "[...]" + extension;
    },
  },
});
</script>

<style scoped lang="scss">
@import "~@/assets/scss/colors.scss";

.has-border {
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #b7b7b7;
  color: #4a4a4a;
  padding: 0 6px;
}
</style>
